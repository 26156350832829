import * as PDFHelper from './PDFHelper';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ifNullOrEmpty, getLocaleDate, getAmountInWords, isNullOrEmpty, safeFloat, safeString, safeBool, isNullOrUndefined, safeInt, getAdditioanlFieldValue, getLocaleDateTime, getLocaleDate2, safeAmount, isEmptyObjectArray } from '../../Base/Utils';
import { TAX_REG_COMPOSITION_SCHEME, INVOICE_FORMAT_RETAIL_POS_LOGO, PDF_OUTPUT_BUFFER, PDF_OUTPUT_DOWNLOAD, PDF_OUTPUT_PRINT, PDF_OUTPUT_URL, TAX_REG_UNREGISTERED } from '../../Base/Common';
import { getAdditionalDetails } from '../../Shared/OrderHelper';
import { isEmptyArray } from 'formik';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function printInvoicePOS(printData, callback) {
    const docDefinition = {
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: { width: 216, height: 'auto' },
        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [5, 5, 5, 25],
        content: {
            stack: addBody(printData)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

const noBordersTableLayout = {
    hLineWidth: function (i, node) {
        return 0;
    },
    vLineWidth: function (i, node) {
        return 0;
    }
}
const topAndBottomBorderTableLayout = {
    hLineWidth: function (i, node) {
        if ((i <= 1 || i === node.table.body.length)) {
            return 0.5;
        }
        return 0;
    },
    vLineWidth: function (i, node) {
        return 0;
    }
}
const topBorderTableLayout = {
    hLineWidth: function (i, node) {
        return i === 0 ? 0.5 : 0;
    },
    vLineWidth: function (i, node) {
        return 0;
    }
}

const headerAndFooterBorderTableLayout = {
    hLineWidth: function (i, node) {
        if ((i <= 1 || i === node.table.body.length || i === node.table.body.length - 1)) {
            return 1;
        }
        return 0;
    },
    vLineWidth: function (i, node) {
        return 0;
    }
}

const headerAndFooterBorderTableLayoutDashed = {
    hLineWidth: function (i, node) {
        if ((i <= 1 || i === node.table.body.length || i === node.table.body.length - 1)) {
            return 0.5;
        }
        return 0;
    },
    vLineWidth: function (i, node) {
        return 0;
    },
    hLineStyle: function (i, node) {
        return { dash: { length: 2, space: 2 } };
    },
}

const addBody = (printData) => {
    var body = [];
    const isCompositionScheme = safeInt(printData.subscriber.taxRegistrationTypeId) === TAX_REG_COMPOSITION_SCHEME;

    if (printData.settings.invoiceFormat === INVOICE_FORMAT_RETAIL_POS_LOGO) {
        body.push(PDFHelper.getLogo(printData.subscriber, printData.settings, 63, 'center'));
    }

    body.push(getHeader(printData));

    body.push(getDocDetails(printData));
    body.push(getItemList(printData, isCompositionScheme));
    body.push([
        { text: getAmountInWords(printData.voucherData.grandTotal), fontSize: 9, bold: true, margin: 5 }
    ]);
    if (!isCompositionScheme) {
        body.push(getHSNSummary(printData));
    }
    var fontSizeVar = 8;
    if (printData.voucherData.grandTotal > 99999.00) {
        fontSizeVar = 7;
    }

    if (!isNullOrUndefined(printData.bankDetails)) {
        var fieldData = '';
        fieldData += "Company's PAN: " + PDFHelper.getPAN(printData.subscriber.taxIdNumber);
        if (!isNullOrUndefined(printData.bankDetails)) {
            fieldData += "\n Bank Name: " + safeString(printData.bankDetails.bankName);
            fieldData += "\n A/c No.: " + safeString(printData.bankDetails.accountNumber);
            fieldData += "\n Branch: " + safeString(printData.bankDetails.branchName);
            fieldData += "\n IFSC: " + safeString(printData.bankDetails.ifscCode);
        }
        body.push([
            { text: fieldData, fontSize: fontSizeVar, margin: [0, 10, 0, 0], bold: false, alignment: 'center' }
        ]);
        body.push([
            {
                stack: PDFHelper.getQRCode(printData.bankDetails, printData.settings, 108, 'center')
            }
        ]);
    }

    if (!isNullOrEmpty(printData.settings.invoiceTermsAndConditions)) {
        body.push([
            { text: 'Terms & Conditions:', fontSize: fontSizeVar, margin: [0, 10, 0, 0], bold: false, alignment: 'center' }
        ]);
        body.push([
            { text: safeString(printData.settings.invoiceTermsAndConditions), fontSize: fontSizeVar, bold: false, margin: [0, 0, 0, 10], alignment: 'center' }
        ]);
    }

    if (!isNullOrEmpty(printData.settings.invoiceGreetingMessage)) {
        body.push([
            { text: safeString(printData.settings.invoiceGreetingMessage), fontSize: 9, bold: false, margin: [5, 10, 5, 25], alignment: 'center' }
        ]);
    }

    return body;
}

const getHSNSummary = (printData) => {
    const hsnSummary = printData.hsnSummary;

    var itemDetailsTableBody = [];
    var headerRow = [];
    var fontSizeVar = 7;
    if (printData.voucherData.grandTotal > 99999.00) {
        fontSizeVar = 6;
    }

    headerRow.push({ text: 'HSN/SAC', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'GST', bold: true, alignment: 'center', fontSize: fontSizeVar });
    headerRow.push({ text: 'Amount', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'CGST', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'SGST', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'IGST', bold: true, alignment: 'left', fontSize: fontSizeVar });
    itemDetailsTableBody.push(headerRow);
    for (let i = 0; i < hsnSummary.length; i++) {
        const item = hsnSummary[i];
        var row = [];
        const gstRateDisplay = safeFloat(item.taxRate) > 0 ? safeFloat(item.taxRate) + '%' : '';
        row.push({ text: safeString(item.hsnCode), alignment: 'left', fontSize: fontSizeVar });
        row.push({ text: gstRateDisplay, alignment: 'right', fontSize: fontSizeVar });
        row.push({ text: safeFloat(item.taxableAmount).toFixed(2), alignment: 'right', fontSize: fontSizeVar });
        row.push({ text: item.centralTaxAmountDisplay, alignment: 'right', fontSize: fontSizeVar });
        row.push({ text: item.centralTaxAmountDisplay, alignment: 'right', fontSize: fontSizeVar });
        row.push({ text: item.interStateTaxAmountDisplay, alignment: 'right', fontSize: fontSizeVar });
        itemDetailsTableBody.push(row);
    }

    return [
        {
            table: {
                widths: ['22%', '10%', '20%', '16%', '16%', '16%'],
                body: itemDetailsTableBody,
                headerRows: 1
            },
            layout: headerAndFooterBorderTableLayoutDashed
        }
    ];

}
const getItemList = (printData, isCompositionScheme) => {
    const voucherItems = printData.voucherItems;
    const voucherData = printData.voucherData;
    const settings = printData.settings;
    const isUnregisteredBusiness = printData.subscriber.taxRegistrationTypeId == TAX_REG_UNREGISTERED;

    var itemDetailsTableBody = [];
    var headerRow = [];
    var fontSizeVar = 7;
    if (printData.voucherData.grandTotal > 99999.00) {
        fontSizeVar = 6;
    }
    if (safeBool(settings.useMaximumRetailPrice)) {
        fontSizeVar = fontSizeVar - 0.5;
    }
    headerRow.push({ text: '#', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'Item', bold: true, alignment: 'left', fontSize: fontSizeVar });
    headerRow.push({ text: 'Qty.', bold: true, alignment: 'left', fontSize: fontSizeVar });

    var itemsWithDiscount = voucherItems.find(i => i.discountPercent > 0);
    const hasDiscount = !isNullOrUndefined(itemsWithDiscount) ? true : false;

    if (safeBool(settings.useMaximumRetailPrice)) {
        headerRow.push({ text: 'MRP', bold: true, alignment: 'right', fontSize: fontSizeVar });
    }
    if (hasDiscount) {
        headerRow.push({ text: 'Rate\nDisc(%)', bold: true, alignment: 'right', fontSize: fontSizeVar });
    } else {
        headerRow.push({ text: 'Rate', bold: true, alignment: 'right', fontSize: fontSizeVar });
    }

    if (!isCompositionScheme && !isUnregisteredBusiness) {
        headerRow.push({ text: 'GST', bold: true, alignment: 'center', fontSize: fontSizeVar });
    }
    headerRow.push({ text: 'Amount', bold: true, alignment: 'center', fontSize: fontSizeVar });

    itemDetailsTableBody.push(headerRow);
    var taxAmountTotal = 0;
    var quantityTotal = 0;
    var unitPriceTotal = 0;
    var maximumRetailPriceTotal = 0;
    for (let i = 0; i < voucherItems.length; i++) {
        const item = voucherItems[i];
        var row = [];
        var itemDescription = safeString(item.description);
        if (!isNullOrEmpty(item.orderDetailInfotext)) {
            itemDescription += safeString(item.orderDetailInfotext);
        }
        if (!isNullOrEmpty(item.hsnCode) && (!isCompositionScheme && !isUnregisteredBusiness)) {
            itemDescription += '\nHSN:' + safeString(item.hsnCode);
        }

        var taxAmount = safeFloat(item.stateTaxAmount) + safeFloat(item.centralTaxAmount) + safeFloat(item.interStateTaxAmount);
        taxAmountTotal += taxAmount;
        if (item.productType === 'Goods') {
            quantityTotal += safeFloat(item.quantity);
        }
        unitPriceTotal += safeFloat(item.unitPrice);
        maximumRetailPriceTotal += safeFloat(item.maximumRetailPrice);
        var discountDispaly = safeFloat(item.discountPercent) > 0 ? ('\n-' + (item.discountPercent) + '%') : '';

        row.push({ text: i + 1, alignment: 'left', fontSize: fontSizeVar });
        row.push({ text: itemDescription, alignment: 'left', fontSize: fontSizeVar });
        row.push({ text: item.quantity + '\n' + item.unitOfMeasurement, alignment: 'center', fontSize: fontSizeVar });
        if (safeBool(settings.useMaximumRetailPrice)) {
            row.push({ text: safeFloat(item.maximumRetailPrice).toFixed(2), alignment: 'right', fontSize: fontSizeVar });
        }
        row.push({ text: safeFloat(item.unitPrice).toFixed(2) + discountDispaly, alignment: 'right', fontSize: fontSizeVar });
        if (!isCompositionScheme && !isUnregisteredBusiness) {
            row.push({ text: safeFloat(taxAmount).toFixed(2) + '\n@' + safeString(item.taxRate) + '%', alignment: 'right', fontSize: fontSizeVar });
        }
        row.push({ text: safeFloat(item.lineTotal).toFixed(2), alignment: 'right', fontSize: fontSizeVar });
        itemDetailsTableBody.push(row);
    }
    let colSpanSize = (isCompositionScheme || isUnregisteredBusiness) ? 5 : 6;
    if (safeBool(settings.useMaximumRetailPrice)) {
        colSpanSize = colSpanSize + 1;
    }
    if (safeFloat(voucherData?.orderDiscountPercent) !== 0) {
        var roundOffRow = [];
        roundOffRow.push({ colSpan: colSpanSize, fontSize: fontSizeVar, text: 'Disc(%):         ' + safeFloat(voucherData.orderDiscountPercent).toFixed(2), alignment: 'right' });
        itemDetailsTableBody.push(roundOffRow);
    }
    if (!isEmptyArray(voucherData?.orderAdjustments) && !isEmptyObjectArray(voucherData?.orderAdjustments)) {
        for (let i = 0; i < voucherData?.orderAdjustments.length; i++) {
            let orderAdjustmentsRow = [];
            let orderAdjustmentItem = voucherData?.orderAdjustments?.[i];
            if (orderAdjustmentItem.adjustmentType === 'TDS' || orderAdjustmentItem.adjustmentType === 'TCS') {
                orderAdjustmentsRow.push({ colSpan: colSpanSize, fontSize: fontSizeVar, text: `${safeString(orderAdjustmentItem?.adjustmentText)}%      ` + safeAmount(orderAdjustmentItem?.adjustmentAmount).toFixed(2), alignment: 'right' });
            } else {
                orderAdjustmentsRow.push({ colSpan: colSpanSize, fontSize: fontSizeVar, text: `${safeString(orderAdjustmentItem?.adjustmentText)}       ` + safeAmount(orderAdjustmentItem?.adjustmentAmount).toFixed(2), alignment: 'right' });
            }
            itemDetailsTableBody.push(orderAdjustmentsRow);
        }
    }
    if (safeFloat(voucherData.roundOffAmount) !== 0) {
        var roundOffRow = [];
        roundOffRow.push({ colSpan: colSpanSize, fontSize: fontSizeVar, text: 'Round off:         ' + safeFloat(voucherData.roundOffAmount).toFixed(2), alignment: 'right' });
        itemDetailsTableBody.push(roundOffRow);
    }

    var totalRow = [];
    totalRow.push({ text: '', alignment: 'left', fontSize: fontSizeVar });
    totalRow.push({ text: 'Total', alignment: 'right', fontSize: fontSizeVar, bold: true });
    totalRow.push({ text: quantityTotal, alignment: 'center', fontSize: fontSizeVar, bold: true });
    if (safeBool(settings.useMaximumRetailPrice)) {
        totalRow.push({ text: safeFloat(maximumRetailPriceTotal).toFixed(2), alignment: 'right', fontSize: fontSizeVar, bold: true });
    }
    totalRow.push({ text: unitPriceTotal.toFixed(2), alignment: 'right', fontSize: fontSizeVar, bold: true });
    if (!isCompositionScheme && !isUnregisteredBusiness) {
        totalRow.push({ text: taxAmountTotal.toFixed(2), alignment: 'right', fontSize: fontSizeVar, bold: true });
    }
    totalRow.push({ text: safeFloat(voucherData.grandTotal).toFixed(2), alignment: 'right', fontSize: fontSizeVar, bold: true });
    itemDetailsTableBody.push(totalRow);
    if (isCompositionScheme || isUnregisteredBusiness) {
        let widths = ['6%', '34%', '10%', '25%', '25%'];
        if (safeBool(settings.useMaximumRetailPrice)) {
            widths = ['6%', '24%', '10%', '20%', '20%', '20%'];
        }
        return [
            {
                table: {
                    widths: widths,
                    body: itemDetailsTableBody,
                    headerRows: 1
                },
                layout: headerAndFooterBorderTableLayout
            }
        ];
    } else {
        let widths = ['6%', '31%', '10%', '17%', '16%', '20%'];
        if (safeBool(settings.useMaximumRetailPrice)) {
            widths = ['5%', '19%', '10%', '16%', '16%', '16%', '18%'];
        }
        return [
            {
                table: {
                    widths: widths,
                    body: itemDetailsTableBody,
                    headerRows: 1
                },
                layout: headerAndFooterBorderTableLayout
            }
        ];
    }


}

const getDocDetails = (printData) => {
    var docDetailsTableBody = [];
    const voucherData = printData.voucherData;
    const party = printData.party;
    const partyAddress = party.addressList[0];

    var fontSizeVar = 9;
    if (printData.voucherData.grandTotal > 99999.00) {
        fontSizeVar = 8;
    }

    const customFields = printData.customFields;
    let fieldDefinition = PDFHelper.getEntityFields(customFields);
    let additionalDetails = null;
    additionalDetails = getAdditionalDetails(voucherData);
    docDetailsTableBody.push([PDFHelper.getLabelText('Invoice No. ', safeString(voucherData.orderNumber), true, fontSizeVar), PDFHelper.getLabelText('Date: ', getLocaleDate(voucherData.orderDate), true, fontSizeVar, 'right')]);
    let customFieldCount = 0;
    if (fieldDefinition?.hasCustomFields) {
        let customFieldVal = '';
        for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
            const customField = fieldDefinition?.customFields[i];
            if (safeBool(customField?.print)) {
                if (safeBool(customField?.isStandard)) {
                    customFieldVal = safeString(voucherData[customField?.name]);
                } else {
                    if (!isNullOrUndefined(additionalDetails)) {
                        customFieldVal = safeString(additionalDetails[safeString(customField?.name)]);
                    } else {
                        customFieldVal = '';
                    }
                }
                if (customField?.type === 'DateTime') {
                    customFieldVal = getLocaleDateTime(customFieldVal);
                } else if (customField?.type === 'Date') {
                    customFieldVal = getLocaleDate2(customFieldVal);
                } else if (customField?.type === 'Currency') {
                    customFieldVal = safeFloat(customFieldVal).tofixed(2);
                }
                docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: [PDFHelper.getLabelText(safeString(customField?.label) + ': ', customFieldVal, true, fontSizeVar)] }]);
                customFieldCount++;
            }
        }
    }
    docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: [PDFHelper.getLabelText('Customer Name: ', safeString(party.legalName), true, fontSizeVar)] }]);
    docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: [PDFHelper.getLabelText('Phone: ', safeString(partyAddress.contactPhone), true, fontSizeVar)] }])
    if (!isNullOrEmpty(party.taxIdNumber)) {
        docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: partyAddress.addressLine1 + ' ' + partyAddress.addressLine2 }])
        docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: safeString(partyAddress.city) + ' ' + PDFHelper.getStateName(safeString(partyAddress.stateId), printData.stateList) + ' ' + safeString(partyAddress.pinCode), bold: false }]);
        docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: [PDFHelper.getLabelText('Place of supply: ', safeString(ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId)) + '-' + PDFHelper.getStateName(ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId), printData.stateList), true, fontSizeVar)] }]);
        docDetailsTableBody.push([{ colSpan: 2, fontSize: fontSizeVar, text: [PDFHelper.getLabelText('GSTIN: ', safeString(party.taxIdNumber), true, fontSizeVar)] }]);
    }

    return [
        {
            table: {
                headerRows: 0,
                widths: ['*', '*'],
                body: docDetailsTableBody
            },
            layout: {
                hLineWidth: function (i, node) {
                    if ((i <= 1 || i === node.table.body.length || i === customFieldCount + 1)) {
                        return 0.5;
                    }
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                }
            }
        }
    ]
}

const getHeader = (printData) => {
    var headerTableBody = [];
    const subscriber = printData.subscriber;
    const subscriberAddress = printData.subscriber.addressList[0];

    headerTableBody.push([{ text: printData.heading, alignment: 'center', margin: [0, 10, 0, 10], fontSize: 10, bold: true }]);

    if (!isNullOrEmpty(subscriber.shortName)) {
        headerTableBody.push([{ text: safeString(subscriber.shortName), alignment: 'center', margin: [0, 0, 0, 0], fontSize: 12, bold: true }]);
    }

    if (!safeBool(printData.noHeader)) {
        headerTableBody.push([{ text: safeString(subscriber.legalName), alignment: 'center', margin: [0, 0, 0, 0], fontSize: 12, bold: true }]);
        headerTableBody.push([{ text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + PDFHelper.getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, fontSize: 9 }]);
        headerTableBody.push([{ text: 'PH: ' + safeString(subscriberAddress.contactPhone), alignment: 'center', margin: [0, 0, 0, 0], fontSize: 9, bold: false }]);
        headerTableBody.push([{ text: 'Email: ' + safeString(subscriberAddress.contactEmail), alignment: 'center', margin: [0, 0, 0, 0], fontSize: 9, bold: false }]);
        if (!isNullOrEmpty(subscriberAddress.website)) {
            headerTableBody.push([{ text: 'Website: ' + safeString(subscriberAddress.website), alignment: 'center', margin: [0, 0, 0, 0], fontSize: 9, bold: false }]);
        }

        var subscriberGSTIN = PDFHelper.getLabelText('GSTIN: ', safeString(subscriber.taxIdNumber), true, 9, 'center');
        headerTableBody.push([subscriberGSTIN]);
        if (!isNullOrEmpty(subscriber.additionalDetails)) {
            let additionalDetails = JSON.parse(subscriber.additionalDetails);
            let fssaiLicense = getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
            if (!isNullOrEmpty(fssaiLicense)) {
                headerTableBody.push([PDFHelper.getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'center')]);
            }
        }
    }

    return [
        {
            style: 'noBorders',
            table: {
                headerRows: 0,
                widths: ['*'],
                body: headerTableBody
            },
            layout: noBordersTableLayout
        }
    ];
}