import * as PDFHelper from './PDFHelper';
import { degrees, PDFDocument } from 'pdf-lib';
import download from 'downloadjs';

import * as Utility from '../../Base/Utils';
import * as Common from '../../Base/Common';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getWaterMark(settings) {
    const watermarkLabel = Utility.safeString(settings?.watermarkLabel);
    const waterMarkAngle = Utility.safeInt(settings?.waterMarkAngle);
    if (!Utility.isNullOrEmpty(watermarkLabel)) {
        return { text: !Utility.isNullOrEmpty(watermarkLabel) ? "   " + watermarkLabel + "   " : "", opacity: 0.5, color: '#8cb4cd', angle: waterMarkAngle };
    }
    return null;
}

export function printQuotation(printData, entityType, callback) {
    var totalPages = 0;
    const printFormat = Utility.safeString(printData.settings.quotationFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: getPageMargin(printFormat, printData.compact, 'Quotation'),
        // pageMargins: [20, 158, 20, 94], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(printFormat, "Quotation", printData.compact)
        },
        header: function (currentPage, pageCount, pageSize) {
            return getPageHeader(printFormat, printData, entityType);
        },
        // header: function (currentPage, pageCount, pageSize) {            
        //     return PDFHelper.addHeader(printData, entityType);
        // },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType, printData.compact);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }


    // pdfMake.createPdf(docDefinition).open();
}


export function printQuotationElegant(printData, entityType, callback) {
    var totalPages = 0;
    const printFormat = Utility.safeString(printData.settings.quotationFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 27, 20, 94], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorderElegant("", "Quotation", printData.compact)
        },
        header: function (currentPage, pageCount, pageSize) {
            return null; //PDFHelper.addHeaderElegant(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addFooterElegant(currentPage, pageCount, pageSize, printData, entityType, printData.compact);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat, true)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }


    // pdfMake.createPdf(docDefinition).open();
}


export function printInvoiceElegant(printData, entityType, callback) {
    try {
        var totalPages = 0;
        const printFormat = Utility.safeString(printData.settings.invoiceFormat);
        var docDefinition = {
            watermark: getWaterMark(printData.settings),
            styles: PDFHelper.getStyles(),
            info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
            pageSize: 'A4', // a string or { width: number, height: number }
            pageMargins: [20, 27, 20, 93], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageOrientation: 'portrait', // landscape        
            background: function (currentPage, pageSize) {
                return PDFHelper.addPageBorderElegant("", "Invoice", printData.compact)
            },
            header: function (currentPage, pageCount, pageSize) {
                return null;
            },
            footer: function (currentPage, pageCount, pageSize) {
                totalPages = pageCount;
                return PDFHelper.addFooterElegant(currentPage, pageCount, pageSize, printData, entityType);
            },
            content: {
                stack: PDFHelper.addPageBody(printData, entityType, printFormat, true)
            }
        }
    } catch (error) {
        callback({ error: 'Failed to generate PDF. Please verify the logo image you have selected is in correct format.' })
    }


    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blobURL = await copyPages(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, Utility.safeInt(printData.settings.invoiceCopies) + 1, false, printData.settings);

            callback(blobURL);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        // var win = window.open('', '_blank');
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blob = await copyPages(pdfBuffer, getFileName(printData), totalPages, 1, true, printData.settings);
            download(blob, printData.downloadFileName + '.pdf', 'application/pdf');
        });
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

function getPageMargin(printFormat, compact = false, entityName = 'Invoice') {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    switch (printFormat) {
        case Common.INVOICE_FORMAT_CLASSIC:
        case Common.INVOICE_FORMAT_CLASSIC_LOGO:
            return [20, 43, 20, 93];
        case Common.INVOICE_FORMAT_MODERN:
        case Common.INVOICE_FORMAT_MODERN_LOGO:
            return [20, 32, 20, 90];
        case Common.INVOICE_FORMAT_RETAIL:
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
            return [20, 30, 20, 93];
        case Common.INVOICE_FORMAT_ELEGANT:
        case Common.INVOICE_FORMAT_ELEGANT_LOGO:
            return [20, 45, 20, 94];
        case Common.INVOICE_FORMAT_CLASSIC_COMPACT:
            return [20, 36, 20, 93];
        default:
            return [20, 43, 20, compact ? 90 : 93];
    }
}

function getPageBorder(printFormat, reportName = "", entityType = "", compact = false) {
    switch (printFormat) {
        case Common.INVOICE_FORMAT_RETAIL:
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
            return PDFHelper.addPageBorder(printFormat, entityType, compact)
        default:
            return PDFHelper.addPageBorder(printFormat, entityType, compact)
    }
}

function getPageHeader(printFormat, printData, entityType) {
    switch (printFormat) {
        case Common.INVOICE_FORMAT_RETAIL:
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
        case Common.INVOICE_FORMAT_MODERN:
        case Common.INVOICE_FORMAT_MODERN_LOGO:
            return PDFHelper.addHeaderService(printData, entityType);
        case Common.INVOICE_FORMAT_ELEGANT:
        case Common.INVOICE_FORMAT_ELEGANT_LOGO:
            return PDFHelper.addHeaderElegant(printData, entityType);
        default:
            return PDFHelper.addHeader(printData, entityType);
    }
}
export function printInvoice(printData, entityType, callback) {
    try {
        var totalPages = 0;
        const printFormat = Utility.safeString(printData.settings.invoiceFormat);
        var docDefinition = {
            watermark: getWaterMark(printData.settings),
            styles: PDFHelper.getStyles(),
            info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
            pageSize: 'A4', // a string or { width: number, height: number }
            pageMargins: getPageMargin(printFormat, printData.compact),
            pageOrientation: 'portrait', // landscape,  portrait       
            background: function (currentPage, pageSize) {
                return getPageBorder(printFormat, "Invoice", entityType, printData.compact);
            },
            header: function (currentPage, pageCount, pageSize) {
                return getPageHeader(printFormat, printData, entityType);
            },
            footer: function (currentPage, pageCount, pageSize) {
                totalPages = pageCount;
                return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType, printData.compact);
            },
            content: {
                stack: PDFHelper.addPageBody(printData, entityType, printFormat)
            }
        }
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        if (printData.outputType === Common.PDF_OUTPUT_URL) {
            pdfDocGenerator.getBuffer(async (pdfBuffer) => {
                var blobURL = await copyPages(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, Utility.safeInt(printData.settings.invoiceCopies) + 1, false, printData.settings);

                callback(blobURL);
            });
        } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
            pdfDocGenerator.getBase64(async (pdfBuffer) => {
                callback(pdfBuffer);
            });
        } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
            // var win = window.open('', '_blank');
            pdfDocGenerator.print();
        } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
            pdfDocGenerator.getBuffer(async (pdfBuffer) => {
                var blob = await copyPages(pdfBuffer, getFileName(printData), totalPages, 1, true, printData.settings);
                download(blob, printData.downloadFileName + '.pdf', 'application/pdf');
            });
        } else {
            pdfDocGenerator.download(printData.downloadFileName + '.pdf');
        }
    } catch (error) {
        callback({ error: 'Failed to generate PDF. Please verify the logo image you have selected is in correct format.' })
    }

}

function getFileName(printData) {
    return printData.voucherData.orderNumber + "_" + printData.party.legalName + ".pdf";
}

/* async function copyPages(pdfBuffer, fileName, pageCount = 1, noOfCopies = 1, returnBlob = false) {
    const originalDocument = await PDFDocument.load(pdfBuffer);
    const duplicateDocument = await PDFDocument.load(pdfBuffer);

    for (let index = 0; index < originalDocument.getPages().length; index++) {
        const page = originalDocument.getPages()[index];
        const { width, height } = page.getSize();
        page.drawText('(ORIGINAL FOR RECIPIENT)', {
            x: width - 160,
            y: height - 20,
            size: 10,
        });
    }
    const pdfDoc = await PDFDocument.create();
    for (let i = 0; i < noOfCopies; i++) {
        for (let j = 0; j < pageCount; j++) {
            let documentToAdd = (i === 0) ? originalDocument : duplicateDocument;
            const [firstDonorPage] = await pdfDoc.copyPages(documentToAdd, [j]);
            pdfDoc.addPage(firstDonorPage);
        }
        if (i === 0) {
            for (let k = 0; k < duplicateDocument.getPages().length; k++) {
                const page = duplicateDocument.getPages()[k];
                const { width, height } = page.getSize();
                let invoiceCopyDesc = '(DUPLICATE)';
                page.drawText(invoiceCopyDesc, {
                    x: width - 90,
                    y: height - 20,
                    size: 10,
                });
            }
        }



    }
    pdfDoc.setTitle(fileName);
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    if (returnBlob) {
        return blob;
    }
    return URL.createObjectURL(blob);

}
 */
async function copyPages(pdfBuffer, fileName, pageCount = 1, noOfCopies = 1, returnBlob = false, settings) {
    const originalDocument = await PDFDocument.load(pdfBuffer);

    const pdfDoc = await PDFDocument.create();

    const documentCopyHeadings = [
        Utility.ifNullOrEmpty(settings?.invoiceCopyHeading1, Common.INVOICE_COPY_HEADINGS[0]),
        Utility.ifNullOrEmpty(settings?.invoiceCopyHeading2, Common.INVOICE_COPY_HEADINGS[1]),
        Utility.ifNullOrEmpty(settings?.invoiceCopyHeading3, Common.INVOICE_COPY_HEADINGS[2]),
        Utility.ifNullOrEmpty(settings?.invoiceCopyHeading4, Common.INVOICE_COPY_HEADINGS[3]),
        Utility.ifNullOrEmpty(settings?.invoiceCopyHeading5, Common.INVOICE_COPY_HEADINGS[4]),
    ];

    for (let i = 0; i < noOfCopies; i++) {
        for (let j = 0; j < pageCount; j++) {
            const [pageToAdd] = await pdfDoc.copyPages(originalDocument, [j]);
            pdfDoc.addPage(pageToAdd);

            const addedPage = pdfDoc.getPages()[pdfDoc.getPages().length - 1];
            const { width, height } = addedPage.getSize();
            let documentHeading = documentCopyHeadings[i] + ``;
            addedPage.drawText(documentHeading, {
                x: width - 185,
                y: height - 20,
                size: 10,
            });
        }
    }

    pdfDoc.setTitle(fileName);
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    if (returnBlob) {
        return blob;
    }
    return URL.createObjectURL(blob);
}

async function copyPagesDC(pdfBuffer, fileName, pageCount = 1, noOfCopies = 1, returnBlob = false, settings) {
    const originalDocument = await PDFDocument.load(pdfBuffer);
    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < noOfCopies; i++) {
        for (let j = 0; j < pageCount; j++) {
            const [pageToAdd] = await pdfDoc.copyPages(originalDocument, [j]);
            pdfDoc.addPage(pageToAdd);
        }
    }

    pdfDoc.setTitle(fileName);
    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    if (returnBlob) {
        return blob;
    }
    return URL.createObjectURL(blob);
}


export function printDeliveryChallan(printData, entityType, callback) {
    var totalPages = 0;
    const printFormat = Utility.safeString(printData.settings.deliveryChallanFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 43, 20, 121], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder()
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addHeader(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);


    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blobURL = await copyPagesDC(pdfBuffer, printData.downloadFileName + '.pdf', totalPages, Utility.safeInt(printData.settings.deliveryChallanCopies) + 1, false, printData.settings);

            callback(blobURL);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        // var win = window.open('', '_blank');
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.getBuffer(async (pdfBuffer) => {
            var blob = await copyPages(pdfBuffer, getFileName(printData), totalPages, 1, true, printData.settings);
            download(blob, printData.downloadFileName + '.pdf', 'application/pdf');
        });
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

export function printCreditNote(printData, entityType, callback) {
    const printFormat = Utility.safeString(printData.settings.creditNoteFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 43, 20, 93], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder()
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addHeader(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

export function printDebitNote(printData, entityType, callback) {
    const printFormat = Utility.safeString(printData.settings.debitNoteFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 43, 20, 93], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder()
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addHeader(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

export function printPurchaseOrder(printData, entityType, callback) {
    const printFormat = Utility.safeString(printData.settings.purchaseOrderFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 43, 20, 90], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder()
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addHeader(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

export function printPurchaseBill(printData, entityType, callback) {
    const printFormat = Utility.safeString(printData.settings.purchaseBillFormat);
    var docDefinition = {
        watermark: getWaterMark(printData.settings),
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName + '.pdf', printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 43, 20, 90], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder()
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addHeader(printData, entityType);
        },
        footer: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addFooter(currentPage, pageCount, pageSize, printData, entityType);
        },
        content: {
            stack: PDFHelper.addPageBody(printData, entityType, printFormat)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

function getTemplateValue(position) {
    const pointToMillimeterRatio = 2.83465;
    try {
        return Utility.safeInt(position) * pointToMillimeterRatio;
    } catch (error) {
        return 0;
    }
}

export async function printCheque(printData, callback) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([601.2, 261]);

    const template = printData.template;

    page.setFontSize(11);


    // mm to pt 1mm = 2.83465 pt
    var accountPayX = 10;
    var accountPayY = 216;
    accountPayX += getTemplateValue(template.accountPayX);
    accountPayY += getTemplateValue(template.accountPayY);
    page.moveTo(accountPayX, accountPayY);

    page.moveTo(accountPayX - 9, accountPayY + 9);
    page.drawText('_________', { rotate: degrees(45) });
    page.moveTo(accountPayX, accountPayY);
    page.drawText('A/c Payee', { rotate: degrees(45) });
    page.drawText('_________', { rotate: degrees(45) });

    var dateX = 454;
    var dateY = 218;
    dateX += getTemplateValue(template.dateX);
    dateY += getTemplateValue(template.dateY);

    page.moveTo(dateX, dateY)
    var chequeDate = printData.date.split("");

    var year = chequeDate[0] + '    ' + chequeDate[1] + '    ' + chequeDate[2] + '    ' + chequeDate[3];
    var month = chequeDate[5] + '    ' + chequeDate[6];
    var day = chequeDate[8] + '    ' + chequeDate[9];

    page.drawText(day + '   ' + month + '   ' + year);

    var favoringX = 57;
    var favoringY = 190;
    favoringX += getTemplateValue(template.favoringX);
    favoringY += getTemplateValue(template.favoringY);

    page.moveTo(favoringX, favoringY)
    page.drawText('**' + printData.payee + '**');

    var amountX = 472;
    var amountY = 148;
    amountX += getTemplateValue(template.amountX);
    amountY += getTemplateValue(template.amountY);

    page.moveTo(amountX, amountY)
    page.drawText('**' + Utility.safeFloat(printData.amount).toLocaleString(undefined, { minimumFractionDigits: 2 }) + '**');

    var amountWordsX = 71;
    var amountWordsY = 148;
    amountWordsX += getTemplateValue(template.amountingWordsX);
    amountWordsY += getTemplateValue(template.amountingWordsY);

    page.moveTo(amountWordsX, amountWordsY)
    page.drawText('**' + Utility.getAmountInWords(printData.amount, true) + '**');


    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, printData.payee + '_' + Utility.safeString(printData.amount) + '_cheque.pdf', "application/pdf");
}

export function printPaymentReceipt(printData, callback) {
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.heading, printData.heading, printData.heading),
        pageSize: 'A5',
        pageMargins: [60, 146, 10, 10],
        pageOrientation: 'landscape',
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_PAYMENT_RECEIPT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_PAYMENT_RECEIPT_PRINT);
        },
        content: {
            stack: PDFHelper.addPaymentReceiptBody(printData)
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        /*  pdfDocGenerator.getDataUrl(async (dataUrl) => {
             callback(dataUrl);
         }); */
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else {
        pdfDocGenerator.download(PDFHelper.getPartyName(printData.party, printData.referenceType) + ' payment receipt.pdf');
    }
}

export function printStatementPdf(printData, callback) {
    var totalPages = 0;
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName, printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 105, 20, 60], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_STATEMENT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_STATEMENT_PRINT);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addStatementFooter(currentPage, pageCount, printData);
        },
        content: {
            stack: PDFHelper.addStatementBody(printData)
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }

    // if (printData.outputType === Common.PDF_OUTPUT_URL) {
    //     pdfDocGenerator.getDataUrl(async (dataUrl) => {
    //         callback(dataUrl);
    //     });
    // } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
    //     pdfDocGenerator.getBase64(async (pdfBuffer) => {
    //         callback(pdfBuffer);
    //     });
    // } else {
    //     pdfDocGenerator.download(PDFHelper.getPartyName(printData.party, printData.referenceType) + ' ledger statement.pdf');
    // }
}


export function PrintOrderStatementPdf(entityName , printData, callback) {
    var totalPages = 0;
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName, printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 105, 20, 60], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_STATEMENT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_ORDERS_PRINT);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addStatementFooter(currentPage, pageCount, printData);
        },
        content: {
            stack: PDFHelper.addOrderStatementBody(printData,entityName)
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }

    // if (printData.outputType === Common.PDF_OUTPUT_URL) {
    //     pdfDocGenerator.getDataUrl(async (dataUrl) => {
    //         callback(dataUrl);
    //     });
    // } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
    //     pdfDocGenerator.getBase64(async (pdfBuffer) => {
    //         callback(pdfBuffer);
    //     });
    // } else {
    //     pdfDocGenerator.download(PDFHelper.getPartyName(printData.party, printData.referenceType) + ' ledger statement.pdf');
    // }
}
export function printPendingStatement(printData, callback) {
    var totalPages = 0;
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.downloadFileName, printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 90, 20, 60], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_PENDING_STATEMENT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_PENDING_STATEMENT_PRINT);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addStatementFooter(currentPage, pageCount, printData);
        },
        content: {
            stack: PDFHelper.addPendingStatementBody(printData)
        }
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getBlob((blob) => {
            callback(URL.createObjectURL(blob));
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_PRINT) {
        pdfDocGenerator.print();
    } else if (printData.outputType === Common.PDF_OUTPUT_DOWNLOAD) {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    } else {
        pdfDocGenerator.download(printData.downloadFileName + '.pdf');
    }
}

export function printCustomerGroupStatement(printData, callback) {
    var totalPages = 0;
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.heading, printData.heading, printData.heading),
        pageSize: 'A4', // a string or { width: number, height: number }
        pageMargins: [20, 90, 20, 60], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageOrientation: 'portrait', // landscape        
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_CUSTOMER_GROUP_STATEMENT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_CUSTOMER_GROUP_STATEMENT_PRINT);
        },
        footer: function (currentPage, pageCount, pageSize) {
            totalPages = pageCount;
            return PDFHelper.addStatementFooter(currentPage, pageCount, printData);
        },
        content: {
            stack: PDFHelper.addCustomerGroupStatementBody(printData)
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getDataUrl(async (dataUrl) => {
            callback(dataUrl);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else {
        pdfDocGenerator.download(printData.customerGroup.name + '_statement.pdf');
    }
}

export function printExpenseReceipt(printData, callback) {
    const docDefinition = {
        styles: PDFHelper.getStyles(),
        info: PDFHelper.getDocumentInfo(printData.heading, printData.heading, printData.heading),
        pageSize: 'A5',
        pageMargins: [60, 146, 10, 10],
        pageOrientation: 'landscape',
        background: function (currentPage, pageSize) {
            return PDFHelper.addPageBorder(Common.REPORT_EXPENSE_RECEIPT_PRINT);
        },
        header: function (currentPage, pageCount, pageSize) {
            return PDFHelper.addStatementHeader(printData, Common.REPORT_EXPENSE_RECEIPT_PRINT);
        },
        content: {
            stack: PDFHelper.addExpenseReceiptBody(printData)
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (printData.outputType === Common.PDF_OUTPUT_URL) {
        pdfDocGenerator.getDataUrl(async (dataUrl) => {
            callback(dataUrl);
        });
    } else if (printData.outputType === Common.PDF_OUTPUT_BUFFER) {
        pdfDocGenerator.getBase64(async (pdfBuffer) => {
            callback(pdfBuffer);
        });
    } else {
        pdfDocGenerator.download(`${printData?.expense?.payeeName} expense receipt.pdf`);
    }
}