import React, { useContext } from 'react';
import { Grid, ButtonGroup, Button, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from '../../styles';
import { APP_MODULE_Bills, APP_MODULE_Payables, CREDIT_NOTE_ENTITY, DEBIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY } from '../Base/Common';
import AuthContext from '../Auth/AuthContext';
import AppIcon from '../UI/Controls/AppIcons';
import { AdaptiveButton, LockIcon } from '../UI/Controls/Controls';

const EntityButtonGroup = ({ entityName }) => {
    const classes = useStyles();
    const history = useHistory();
    const [moreButtonAnchorEl, setMoreButtonAnchorEl] = React.useState(null);

    const authContext = useContext(AuthContext);
    const moduleAccess = authContext.moduleAccess(APP_MODULE_Bills);
    const paymentAccess = authContext.moduleAccess(APP_MODULE_Payables);
    const handleMoreClick = (event) => {
        setMoreButtonAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setMoreButtonAnchorEl(null);
    };

    const renderButtons = () => {
        switch (entityName) {
            case INVOICE_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button size="small" onClick={() => history.push(`/new${entityName}`)}>
                                New Invoice
                            </Button>
                            <Button color="secondary" size="small" aria-haspopup="menu" onClick={handleMoreClick}>
                                <AppIcon name="ArrowDown" size="small" />
                            </Button>
                            <Menu
                                anchorEl={moreButtonAnchorEl}
                                open={Boolean(moreButtonAnchorEl)}
                                onClose={handleMoreClose}
                                elevation={1}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { maxHeight: 48 * 4.5, width: '20ch' } }}
                            >
                                <MenuItem onClick={() => history.push("/invoicepicklist")}>
                                    <ListItemIcon>
                                        <AppIcon name="PickList" />
                                    </ListItemIcon>
                                    Pick List
                                </MenuItem>
                            </Menu>
                        </ButtonGroup>
                    </>
                );
            case PURCHASE_ORDER_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button size="small" onClick={() => history.push(`/new${entityName}`)}>
                                New Purchase Order
                            </Button>
                            <Button color="secondary" size="small" aria-haspopup="menu" onClick={handleMoreClick}>
                                <AppIcon name="ArrowDown" size="small" />
                            </Button>
                            <Menu
                                anchorEl={moreButtonAnchorEl}
                                open={Boolean(moreButtonAnchorEl)}
                                onClose={handleMoreClose}
                                elevation={1}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { maxHeight: 48 * 4.5, width: '30ch' } }}
                            >
                                <MenuItem onClick={() => history.push("/purchaseorderfullfillmentstatus")}>
                                    <ListItemIcon>
                                        <AppIcon name="ReportsIcon" />
                                    </ListItemIcon>
                                    Fullfillment Report
                                </MenuItem>
                            </Menu>
                        </ButtonGroup>
                    </>
                )
            case PURCHASE_BILL_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button disabled={!moduleAccess.allowAdd}
                                startIcon={!moduleAccess.allowAdd ? <LockIcon /> : <AppIcon name="Add" />} size="small" onClick={() => history.push(`/newpurchasebill`)}>
                                New purchase invoice
                            </Button>
                        </ButtonGroup>
                    </>
                );
            case QUOTATION_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button disabled={!moduleAccess.allowAdd}
                                startIcon={!moduleAccess.allowAdd ? <LockIcon /> : <AppIcon name="Add" />} size="small" onClick={() => history.push(`/new${entityName}`)}>
                                New Quotation
                            </Button>
                        </ButtonGroup>
                    </>
                );
            case DELIVERY_CHALLAN_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button disabled={!moduleAccess.allowAdd}
                                startIcon={!moduleAccess.allowAdd ? <LockIcon /> : <AppIcon name="Add" />} size="small" onClick={() => history.push("/new" + entityName.toLowerCase())}>
                                New delivery challan
                            </Button>
                        </ButtonGroup>
                    </>
                );
            case CREDIT_NOTE_ENTITY:
            case DEBIT_NOTE_ENTITY:
                return (
                    <>
                        <ButtonGroup variant="contained" color="secondary" size="small">
                            <Button disabled={!moduleAccess.allowAdd}
                                startIcon={!moduleAccess.allowAdd ? <LockIcon /> : <AppIcon name="Add" />} size="small" onClick={() => history.push("/new" + entityName.toLowerCase())}>
                                {entityName == CREDIT_NOTE_ENTITY ? "New credit note" : "New debit note"}
                            </Button>
                        </ButtonGroup>
                    </>
                )
            default:
                return null;
        }
    };

    return (
        renderButtons()
    );
};

export default EntityButtonGroup;
