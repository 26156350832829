import * as Common from '../Base/Common';
import * as PrintPDF from './Printing/PrintPDF';
import { getData, getMasterData, getDataList } from '../Base/DataProvider';
import WebAPI from "../Base/WebAPI";
import axios from 'axios';
import { printInvoicePOS } from './Printing/POSPaperPrint';
import AppIcon from '../UI/Controls/AppIcons';
import { Button, ButtonBase } from '@material-ui/core';
import { safeAmount, ifNullOrEmpty, getAmountIncludingTax, getAmountExcludingTax, safeBool, safeInt, safeFloat, safeString, isNullOrUndefined, getBatchDateDisplay, isNullOrEmpty, cellPaddingCompact, setInputFocus, lowerCaseFirstLetter, isEmptyObjectArray, getMinDate, getLocaleDate, getISODate } from '../Base/Utils';
import { processPrintOrder } from "./Printing/PDFFormats/ProcessPDF";

export async function setNextSequenceNumber(entityName, authContext, fieldValues, setFieldValues) {
    var nextNumber = await getData(entityName, 'Get' + entityName + 'NextSequence', authContext, null);
    let orderNumberLength = 0;
    if (!isNullOrUndefined(authContext.settings[lowerCaseFirstLetter(entityName) + 'VoucherLength'])) {
        orderNumberLength = safeInt(authContext.settings[lowerCaseFirstLetter(entityName) + 'VoucherLength']);
    }
    const orderPrefix = authContext.settings[lowerCaseFirstLetter(entityName) + 'Prefix'];

    var tempValues = { ...fieldValues };
    tempValues.orderNumber = orderPrefix + String(nextNumber).padStart(orderNumberLength, "0");
    tempValues.orderSequence = nextNumber;
    tempValues.orderPrefix = authContext.settings[lowerCaseFirstLetter(entityName) + 'Prefix'];
    setFieldValues(tempValues);
}

export async function handleItemSelectionChange(authContext, selectedParty, val, setFieldValue, setSelectedProduct, entityName = 'Invoice') {
    var addStandardDsicount = false;
    var orderTypeId = Common.ORDER_TYPE_INVOICE;
    if (isSalesVoucher(entityName)) {
        addStandardDsicount = true;
    } else {
        orderTypeId = Common.ORDER_TYPE_PURCHASE_BILL;
    }
    const useMRP = safeBool(authContext.settings.useMaximumRetailPrice);
    const taxInclusivePricing = safeBool(authContext.settings.taxInclusivePricing);

    if (!isNullOrUndefined(val) && authContext.settings['useLast' + entityName + 'Price'] && selectedParty != null && selectedParty.id > 0) {
        let source = axios.CancelToken.source();
        const methodName = "GetLastProductPrices";
        const response = await getData("Product", methodName, authContext, source.token, "/" + orderTypeId + "/" + selectedParty.id + "/" + val.id);

        if (!isNullOrUndefined(response.code) && safeInt(response.code) > 0) {
            setItemValues(val, setFieldValue, setSelectedProduct, 0, addStandardDsicount, useMRP, entityName, taxInclusivePricing);
        } else {
            setItemValues(val, setFieldValue, setSelectedProduct, response, addStandardDsicount, useMRP, entityName, taxInclusivePricing);
        }
    } else {
        setItemValues(val, setFieldValue, setSelectedProduct, 0, addStandardDsicount, useMRP, entityName, taxInclusivePricing);
    }

    if (val.useBatchTracking == true && authContext.settings.useBatchSerialNoTracking) {
        setFieldValue('showBatchSerialNumber', true);
    } else {
        setFieldValue('showBatchSerialNumber', false);
    }
    setFieldValue('productType', val.productType);
    setFieldValue('unitOfMeasurement', val.unitOfMeasurement);
    setFieldValue('useUnitConversion', safeBool(val.useUnitConversion));
    if (safeBool(authContext.settings.enableCess)) {
        setFieldValue('cessPercent', safeFloat(val.cessPercent));
    }
}

export function getColumnDefinitionSummary(currencyCode = 'INR') {
    return [
        { title: 'Description', field: 'shortDescription', align: 'right', mobileOnly: true, cellStyle: { color: '#3f51b5', paddingTop: 4, paddingBottom: 0 } },
        { title: 'Total', field: 'itemTotal', align: 'right', mobileOnly: true, cellStyle: { color: '#3f51b5', paddingTop: 4, paddingBottom: 0 }, type: "currency", currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: 2 } },
        { title: 'col1', field: 'col1', alwaysVisible: true, width: '25%', cellStyle: { paddingTop: 4, paddingBottom: 0 } },
        { title: 'col2', field: 'col2', alwaysVisible: false, cellStyle: { paddingTop: 4, paddingBottom: 0 } },
        { title: 'col3', field: 'col3', cellStyle: { paddingTop: 4, paddingBottom: 0 } },
        { title: 'col4', field: 'col4', align: 'right', cellStyle: { paddingTop: 4, paddingBottom: 0 } },
        {
            title: 'col5', field: 'col5', align:
                'right', cellStyle: { paddingTop: 4, paddingBottom: 0 }
        },
        { title: 'col6', field: 'col6', cellStyle: { paddingTop: 4, paddingBottom: 0 } },
        {
            title: 'Desc', field: 'description', align: 'right', cellStyle: { color: 'navy', paddingTop: 4, paddingBottom: 0 },
            render: (rowData) => <span
                style={
                    {
                        color: rowData.id === 993 ? 'green' : 'navy',
                        fontWeight: rowData.id === 993 ? '600' : 'normal'
                    }}>
                {rowData.description}</span>
        },
        {
            title: 'Amount', field: 'lineTotal', align: 'right', cellStyle: { paddingTop: 4, paddingBottom: 0 }, type: "currency",
            currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: 2 },
            render: (rowData) => <span
                style={
                    {
                        color: rowData.id === 993 ? 'green' : 'navy',
                        fontWeight: rowData.id === 993 ? '600' : 'normal'
                    }}>
                {rowData.lineTotal}
            </span>
        },
    ];
}

const getItemDescription = (rowData) => {
    return (
        <>
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{rowData.description}</span>
            <br />
            <span style={{ fontSize: '11px', fontStyle: 'italic' }}>{rowData.orderDetailInfoText}</span>
            {
                !isNullOrEmpty(rowData.warehouseName) &&
                <span style={{ display: 'block' }}>
                    <ButtonBase style={{ fontSize: '11px', fontStyle: 'italic' }}>
                        <AppIcon name="Warehouse" color="primary" style={{ fontSize: "14px" }} /> &nbsp;{rowData.warehouseName}
                    </ButtonBase>
                </span>

            }
            {
                !isNullOrEmpty(rowData.serialNumbers) &&
                <span style={{ fontSize: '11px' }}>
                    {
                        !isNullOrEmpty(rowData.orderDetailInfoText) && <br></br>
                    }
                    {"IMEI/Sl.#: " + rowData.serialNumbers.replaceAll(",", ", ")}
                </span>
            }
            {
                safeBool(rowData.useBatchTracking) && !isNullOrEmpty(rowData.batchItems) && !isEmptyObjectArray(rowData.batchItems) &&
                <>
                    {
                        rowData.batchItems.map((item, index) => {
                            return (
                                (safeString(item.batchNumber).length > 0) ?
                                    <>
                                        {
                                            (safeString(rowData.orderDetailInfoText).length > 0 || index > 0) && <br></br>
                                        }
                                        <span style={{ color: 'blue' }}>
                                            {safeString(item.quantity)} * {safeString(item.batchNumber)}; Mfg: {getBatchDateDisplay(item.mfgDate)}; Exp: {getBatchDateDisplay(item.expiryDate)}
                                        </span>

                                    </> : null
                            )
                        })
                    }
                </>
            }
            {
                rowData.showBatchWarning && <Button style={{ padding: 0, margin: 0, color: '#F44336' }} disableRipple variant='text' color="error" startIcon={<AppIcon style={{ fontSize: '14px', padding: 0 }} name="Warning" color='error' />}>Bacth# missing!</Button>
            }
            {
                rowData.showSerialWarning && <Button style={{ padding: 0, margin: 0, color: '#F44336' }} disableRipple variant='text' color="error" startIcon={<AppIcon style={{ fontSize: '14px', padding: 0 }} name="Warning" color='error' />}>Serial# missing!</Button>
            }
        </>
    )
}

const getUOM = (item) => {
    var uomDisplay = <>{item.unitOfMeasurement}</>;
    if (!isNullOrEmpty(item.baseUnitOfMeasurement)) {
        var baseQty = safeFloat(item.conversionFactor, 2);
        return <>
            <span>{item.unitOfMeasurement}</span>
            <br />
            <span style={{ fontSize: '10px', color: 'blue' }}>{"(" + baseQty + " " + item.baseUnitOfMeasurement + ")"}</span>
        </>
    }
    return uomDisplay;
}

export function getColumnDefinition(includeBatchDetails = false, includeMRP = false, includeIndex = false, currencyCode = 'INR', includeFreeQuantity = false, includeReferencePrice = false, isPharmacy = false, taxInclusivePricing = false, decimalPlaces = 2, enableCess = false) {
    let columnDefinition = [];

    if (includeIndex) {
        columnDefinition.push({ title: '#', field: 'index', cellStyle: cellPaddingCompact() });
    }
    columnDefinition.push({ title: 'Desc', field: 'shortDescription', align: 'left', mobileOnly: true, cellStyle: cellPaddingCompact() });
    columnDefinition.push({ title: 'Detail', field: 'itemDetail', align: 'right', mobileOnly: true, cellStyle: cellPaddingCompact() });
    columnDefinition.push({ title: 'Description', field: 'description', align: 'left', alwaysVisible: true, width: '25%', render: rowData => getItemDescription(rowData) });
    if (isPharmacy) {
        columnDefinition.push({ title: 'Pack', field: 'quantityDescription', alwaysVisible: false });
    }
    columnDefinition.push({ title: 'HSN/SAC', field: 'hsnCode', alwaysVisible: false });
    columnDefinition.push({ title: 'GST', field: 'taxRateDisplay' });
    if (includeFreeQuantity) {
        columnDefinition.push({ title: 'Free Qty', field: 'freeQuantity', align: 'right' });
    }
    columnDefinition.push({ title: 'Qty', field: 'quantity', align: 'right' });
    if (includeMRP) {
        columnDefinition.push({ title: 'MRP', field: 'maximumRetailPrice', align: 'right', type: 'currency', currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces } });
    }
    if (includeReferencePrice) {
        columnDefinition.push({ title: 'PTS', field: 'pts', align: 'right', type: 'currency', currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces } });
        columnDefinition.push({ title: 'PTR', field: 'ptr', align: 'right', type: 'currency', currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces } });
    }
    columnDefinition.push({
        title: 'Rate', field: taxInclusivePricing ? 'unitPriceIncludingTax' : 'unitPrice', align: 'right', type: 'currency',
        currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces }
    });
    columnDefinition.push({ title: 'Per', field: 'unitOfMeasurement', align: 'center', render: (rowData) => getUOM(rowData) });
    columnDefinition.push({ title: 'Disc.(%)', field: 'discountPercentDisplay', align: 'right' });
    if (enableCess) {
        columnDefinition.push({ title: 'CESS (%)', field: 'cessPercentDisplay', align: 'right' });
    }
    columnDefinition.push({ title: 'Amount', field: taxInclusivePricing ? 'lineTotal' : 'taxableAmount', align: 'right', type: 'currency', currencySetting: { currencyCode: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: 2 } });
    return columnDefinition;
}

const getBatchItems = (item, orderId = 0, refreshItems = false) => {
    // return item.batchItems;

    if (item.useBatchTracking === false && refreshItems === true) {
        return [{
            id: 0,
            orderDetailsId: safeInt(item.id),
            quantity: safeFloat(item.quantity),
            batchNumber: '',
            mfgDate: getMinDate(),
            expiryDate: getMinDate(),
            active: true
        }];
    } else {
        if (isEmptyObjectArray(item.batchItems)) {
            return [{
                id: 0,
                orderDetailsId: safeInt(item.id),
                quantity: safeFloat(item.quantity),
                batchNumber: '',
                mfgDate: getMinDate(),
                expiryDate: getMinDate(),
                active: true
            }];
        } else {
            // Set MfgDate to min date if it is null before sending it to API
            let formattedBatchItems = [];
            for (let i = 0; i < item.batchItems.length; i++) {
                let batchItem = item.batchItems[i];
                if (isNullOrEmpty(batchItem.mfgDate)) {
                    batchItem.mfgDate = getMinDate();
                }
                formattedBatchItems.push(batchItem);
            }
            return formattedBatchItems;
        }
    }
}

const getOrderDetails = (orderData, isIGST, orderId = 0, taxInclusive = false) => {
    var items = [];
    if (orderData.length > 0) {
        orderData.map(item => {
            let taxTotals = getTaxTotals(item, isIGST);
            if (taxInclusive && orderId != 0) {
                // Recalculate unitPrice before tax                
                item.unitPrice = getAmountExcludingTax(item.unitPriceIncludingTax, item.taxRate);
            }
            items.push({
                "id": safeInt(item.id),
                "orderId": safeInt(orderId),
                "productId": safeInt(item.productId),
                "productType": safeString(item.productType),
                "productDescription": safeString(item.description),
                "hsnCode": safeString(item.hsnCode),
                "quantity": safeAmount(item.quantity),
                "quantityDescription": safeString(item.quantityDescription),
                "freeQuantity": safeAmount(item.freeQuantity),
                "unitOfMeasurement": safeString(item.unitOfMeasurement),
                "baseUnitOfMeasurement": safeString(item.baseUnitOfMeasurement),
                "conversionFactor": safeAmount(item.conversionFactor, 2),
                "ptsptr": safeAmount(item.ptsptr),
                "pts": safeAmount(item.pts),
                "ptr": safeAmount(item.ptr),
                "maximumRetailPrice": safeAmount(item.maximumRetailPrice),
                "unitPrice": safeAmount(item.unitPrice),
                "unitPriceIncludingTax": safeAmount(item.unitPriceIncludingTax),
                "taxableAmount": safeAmount(item.taxableAmount),
                "lineTotal": safeAmount(item.lineTotal),
                "stateTaxAmount": safeAmount(taxTotals.sgst),
                "centralTaxAmount": safeAmount(taxTotals.cgst),
                "interStateTaxAmount": safeAmount(taxTotals.igst),
                "taxRate": safeAmount(item.taxRate),
                "discountPercent": safeAmount(item.discountPercent),
                "discountAmount": safeAmount(getDiscountAmount(item)),
                "batchItems": getBatchItems(item, orderId, !safeBool(item.useBatchTracking)),
                "serialNumbers": safeString(item.serialNumbers),
                "useSerialTracking": safeBool(item.useSerialTracking),
                "validateBatchSerialNumber": true,
                "orderDetailInfoText": safeString(item.orderDetailInfoText),
                "warehouseId": safeInt(item.warehouseId),
                "isCalculated": false,
                "cessPercent": safeAmount(item.cessPercent),
                "cessAmount": safeAmount(getCessAmount(item)),
                "active": true
            });
        });
    }
    return items;
}

export function getDeliveryChallanRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": 2,
        "orderTotal": safeAmount(values.orderTotal),
        "orderDiscountPercent": safeAmount(values.orderDiscountPercent),
        "orderDiscountAmount": safeAmount(values.orderDiscountAmount),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "eWayBillNo": values.eWayBillNo,
        "referenceId": selectedParty.id,
        "referenceType": "Customer",
        "referenceName": selectedParty.legalName,
        "notes": safeString(values.notes),
        "isOrderChanged": isOrderChanged,
        "invoiceId": safeInt(values.invoiceId),
        "deliveryAddressId": safeInt(values.deliveryAddressId),
        "addToReservedQty": safeBool(values.addToReservedQty),
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "active": true,
        "isManualOrderNumber": safeBool(values.isManualOrderNumber),
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive)
    };
    request['deliveryChallanDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function getSalesOrderRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "deliveryAddressId": safeInt(values.deliveryAddressId),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": safeInt(values.statusId),
        "syncErrors": safeString(values.syncErrors),
        "orderTotal": safeAmount(values.orderTotal),
        "orderDiscountPercent": safeAmount(values.orderDiscountPercent),
        "orderDiscountAmount": safeAmount(values.orderDiscountAmount),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Customer",
        "referenceName": selectedParty.legalName,
        "validityInDays": 0,
        "invoiceId": safeInt(values.invoiceId),
        "isOrderChanged": isOrderChanged,
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive),
        "deliveryAddressId": safeInt(values.deliveryAddressId),
    };
    request['salesOrderDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function getQuotationRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "deliveryAddressId": safeInt(values.deliveryAddressId),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": 2,
        "orderTotal": safeAmount(values.orderTotal),
        "orderDiscountPercent": safeAmount(values.orderDiscountPercent),
        "orderDiscountAmount": safeAmount(values.orderDiscountAmount),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Customer",
        "referenceName": selectedParty.legalName,
        "validityInDays": 0,
        "invoiceId": safeInt(values.invoiceId),
        "isOrderChanged": isOrderChanged,
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive),
        "deliveryAddressId": safeInt(values.deliveryAddressId)
    };
    request['quotationDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function getInvoiceRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true, customFields = null) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": safeInt(values.statusId),
        "syncErrors": safeString(values.syncErrors),
        "orderTotal": safeAmount(values.orderTotal),
        "orderDiscountPercent": safeAmount(values.orderDiscountPercent),
        "orderDiscountAmount": safeAmount(values.orderDiscountAmount),
        "cessAmount": safeAmount(values.cessAmount),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "employeeId": safeInt(values.employeeId),
        "referenceId": selectedParty.id,
        "referenceType": "Customer",
        "referenceName": selectedParty.legalName,
        "isCredit": safeBool(values.isCredit),
        "modeOfPayment": values.modeOfPayment,
        "poReference": values.poReference,
        "poDate": values.poDate === "" ? getMinDate() : values.poDate,
        "isPaid": false,
        "transportDetails": values.transportDetails,
        "eWayBillNo": values.eWayBillNo,
        "deliveryAddressId": safeInt(values.deliveryAddressId),
        "deliveryDate": values.deliveryDate === "" ? getMinDate() : values.deliveryDate,
        "paymentDueDate": values.paymentDueDate,
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "isOrderChanged": isOrderChanged,
        "active": true,
        "isManualOrderNumber": safeBool(values.isManualOrderNumber),
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive)
    };
    let additionalDetails = {
        "printBatchDetails": safeBool(values.printBatchDetails),
        "includeBatchDetailsInInvoice": safeString(values.includeBatchDetailsInInvoice),
        "bankId": safeInt(values.bankId),
    }

    if (!isNullOrUndefined(customFields)) {
        if (!isEmptyObjectArray(customFields)) {
            let invoiceCustomFields = customFields.find(x => x.templateTypeId === 2002);
            if (!isNullOrEmpty(invoiceCustomFields?.templateText)) {
                try {
                    let fieldDefinition = JSON.parse(invoiceCustomFields?.templateText);
                    if (!isNullOrUndefined(fieldDefinition)) {
                        if (!isEmptyObjectArray(fieldDefinition?.customFields)) {
                            for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                                const customField = fieldDefinition?.customFields[i];
                                if (!safeBool(customField?.isStandard))
                                    additionalDetails[customField?.name] = values[customField?.name];
                            }
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }

    request['additionalDetails'] = JSON.stringify(additionalDetails);
    request['invoiceDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id), safeBool(values.isTaxInclusive));
    return request;
}

export function getCreditNoteRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": 2,
        "orderTotal": safeFloat(values.orderTotal),
        "taxAmount": safeFloat(values.taxAmount),
        "grandTotal": safeFloat(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Customer",
        "referenceName": selectedParty.legalName,
        "originalId": safeInt(values.originalId),
        "reasonForIssue": safeString(values.reasonForIssue),
        "notes": safeString(values.notes),
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "isOrderChanged": isOrderChanged,
        "isCredit": false,
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive)
    };
    request['creditNoteDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

// PURCHASE
export function getPurchaseOrderRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "notes": values.notes,
        "statusId": 2,
        "orderTotal": safeFloat(values.orderTotal),
        "orderDiscountPercent": safeFloat(values.orderDiscountPercent),
        "orderDiscountAmount": safeFloat(values.orderDiscountAmount),
        "taxAmount": safeFloat(values.taxAmount),
        "grandTotal": safeFloat(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Supplier",
        "referenceName": selectedParty.legalName,
        "purchaseBillId": safeInt(values.purchaseBillId),
        "isOrderChanged": isOrderChanged,
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive),
        "deliveryAddressId": safeInt(values.deliveryAddressId),
    };
    request['purchaseOrderDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function getPurchaseBillRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": safeInt(values.statusId),
        "syncErrors": safeString(values.syncErrors),
        "orderTotal": safeAmount(values.orderTotal),
        "orderDiscountPercent": safeAmount(values.orderDiscountPercent),
        "orderDiscountAmount": safeAmount(values.orderDiscountAmount),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Supplier",
        "referenceName": selectedParty.legalName,
        "isCredit": true,
        "modeOfPayment": values.modeOfPayment,
        "poReference": values.poReference,
        "isPaid": false,
        "paymentDueDate": values.paymentDueDate,
        "isOrderChanged": isOrderChanged,
        "isManualOrderNumber": true,
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive)
    };
    request['purchaseBillDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function getDebitNoteRequest(values, selectedParty, orderData, isCreate = true, isOrderChanged = true) {
    var request = {
        "id": isCreate ? 0 : safeInt(values.id),
        "orderNumber": values.orderNumber,
        "orderDate": values.orderDate,
        "orderReference": values.orderReference,
        "termsAndConditions": values.termsAndConditions,
        "statusId": 2,
        "orderTotal": safeAmount(values.orderTotal),
        "taxAmount": safeAmount(values.taxAmount),
        "grandTotal": safeAmount(values.grandTotal),
        "roundOffAmount": 0,
        "isIGST": values.isIGST,
        "referenceId": selectedParty.id,
        "referenceType": "Supplier",
        "referenceName": selectedParty.legalName,
        "originalId": safeInt(values.originalId),
        "reasonForIssue": safeString(values.reasonForIssue),
        "notes": safeString(values.notes),
        "placeOfSupply": safeString(values.placeOfSupply).padStart(2, "0"),
        "isOrderChanged": isOrderChanged,
        "isCredit": false,
        "active": true,
        "concurrencyLock": safeString(values.concurrencyLock),
        "isTaxInclusive": safeBool(values.isTaxInclusive)
    };
    request['debitNoteDetails'] = getOrderDetails(orderData, values.isIGST, isCreate ? 0 : safeInt(values.id));
    return request;
}

export function reCalculateOnTaxTypeChanged(orderData, isIGST) {
    let updatedItems = [];
    let data = [...orderData]
    data.map(item => {
        let taxTotals = getTaxTotals(item, isIGST);
        item.stateTaxAmount = safeFloat(taxTotals.sgst);
        item.centralTaxAmount = safeFloat(taxTotals.cgst);
        item.interStateTaxAmount = safeFloat(taxTotals.igst);
        updatedItems.push(item);
    });
    return updatedItems;
}

/** Removes an item from the list and returns the updated item list */
export function deleteItem(itemId, orderData) {
    var items = [...orderData];
    var index = items.findIndex(i => i.id === itemId);
    if (index > -1) {
        items.splice(index, 1);
    }
    return items;
}

/** Adds an item to orderData and returns the new item list. Use this to add items from create order pages */
export function addNewItem(itemCount, item, orderData) {
    var items = [...orderData];
    let taxTotals = getTaxTotals(item, item.isIGST);
    var newRow = {
        id: itemCount,
        productId: item.productId,
        productType: item.productType,
        description: item.productDescription,
        orderDetailInfoText: safeString(item.orderDetailInfoText),
        hsnCode: item.hsnCode,
        serialNumbers: safeString(item.serialNumbers),
        taxRate: item.taxRate,
        taxRateDisplay: item.taxRate + "%",
        quantity: item.quantity,
        quantityDescription: safeString(item.quantityDescription),
        freeQuantity: safeAmount(item.freeQuantity),
        ptsptr: safeAmount(item.ptsptr),
        pts: safeAmount(item.pts),
        ptr: safeAmount(item.ptr),
        maximumRetailPrice: safeAmount(item.maximumRetailPrice),
        unitPrice: safeAmount(item.unitPrice),
        unitPriceIncludingTax: safeAmount(item.unitPriceIncludingTax),
        unitOfMeasurement: safeString(item.unitOfMeasurement),
        baseUnitOfMeasurement: safeString(item.baseUnitOfMeasurement),
        conversionFactor: safeAmount(item.conversionFactor, 2),
        discountPercent: safeAmount(item.discountPercent),
        discountPercentDisplay: safeAmount(item.discountPercent) > 0 ? item.discountPercent + "%" : '-',
        discountAmount: 0,
        batchItems: item.batchItems,
        taxableAmount: getLineTotal(item, false),
        lineTotal: getLineTotal(item),
        stateTaxAmount: safeAmount(taxTotals.sgst),
        centralTaxAmount: safeAmount(taxTotals.cgst),
        interStateTaxAmount: safeAmount(taxTotals.igst),
        shortDescription: item.productDescription,
        warehouseId: item.warehouseId,
        warehouseName: item.warehouseName,
        itemDetail: getItemDetail(item),
        cessPercent: safeAmount(item.cessPercent),
        cessPercentDisplay: safeAmount(item.cessPercent) > 0 ? item.cessPercent + "%" : '-',
        cessAmount: 0,
        useSerialTracking: safeBool(item.useSerialTracking)
    };
    items.push(newRow);
    return items;
}

/** Adds an item to orderData and returns the new item list. Use this to add items from update order pages */
export function addItem(itemToAdd, orderData, isIGST) {
    var items = [...orderData];
    let taxTotals = getTaxTotals(itemToAdd, isIGST);
    itemToAdd.index = items.length + 1;
    itemToAdd.id = "new_" + items.length + 1;
    itemToAdd.centralTaxAmount = taxTotals.cgst;
    itemToAdd.stateTaxAmount = taxTotals.sgst;
    itemToAdd.interStateTaxAmount = taxTotals.igst;
    itemToAdd.shortDescription = itemToAdd.description;
    itemToAdd.itemDetail = getItemDetail(itemToAdd);
    if (safeFloat(itemToAdd?.cessPercent) > 0) {
        itemToAdd.cessPercentDisplay = `${itemToAdd.cessPercent}%`;
    }

    // itemToAdd.batchItems = getBatchItems(itemToAdd, 0);
    if (!!itemToAdd) {
        items.push(itemToAdd);
    }

    return items;
}

/** Removes the itemId from list and adds an updated item to orderData and returns the new item list. Use this to edit items from update order pages */
export function editItem(itemId, updatedItem, orderData, isIGST) {
    var items = [...orderData];
    var index = items.findIndex(i => i.id === itemId);
    if (index > -1) {
        items.splice(index, 1);
    }
    let taxTotals = getTaxTotals(updatedItem, isIGST);
    updatedItem.centralTaxAmount = taxTotals.cgst;
    updatedItem.stateTaxAmount = taxTotals.sgst;
    updatedItem.interStateTaxAmount = taxTotals.igst;
    updatedItem.shortDescription = updatedItem.description;
    updatedItem.itemDetail = getItemDetail(updatedItem);
    if (safeFloat(updatedItem?.cessPercent) > 0) {
        updatedItem.cessPercentDisplay = `${updatedItem.cessPercent}%`;
    }

    // updatedItem.batchItems = getBatchItems(updatedItem, 0, true);
    if (!!updatedItem) {
        items.push(updatedItem);
    }
    return items;
}

export function reCalculateOrderTotals(items, values, rounding = true) {
    var order = { ...values };
    order.orderTotal = 0;
    order.taxAmount = 0;
    order.grandTotal = 0;
    order.cessAmount = 0;
    var miscellaneousTotal = 0;
    items.map(item => {
        if (item.productType !== 'Miscellaneous') {
            order.orderTotal += safeAmount(item.taxableAmount);
        } else {
            miscellaneousTotal += safeAmount(item.taxableAmount);
        }
        order.taxAmount += getTaxAmount(item);
        order.cessAmount += getCessAmount(item);
    });
    order.grandTotal = (safeAmount(order.orderTotal + order.taxAmount + order.cessAmount) - safeAmount(order.orderDiscountAmount)) + miscellaneousTotal;
    order.grandTotalDisplay = order.grandTotal;
    if (rounding == true) {
        order.roundOffAmount = Math.round(order.grandTotal) - order.grandTotal;
        order.grandTotalDisplay = Math.round(order.grandTotal);
    }
    return order;
}

export function setPartyValue(value, setFieldValue, setSelectedParty) {
    if (!isNullOrUndefined(value)) {
        setSelectedParty(value);
        setFieldValue('referenceId', safeInt(value.id));
        setFieldValue('referenceName', ifNullOrEmpty(safeString(value.legalName), safeString(value.name)));
        setFieldValue('taxIdNumber', safeString(value.taxIdNumber));
        setFieldValue('outstandingAmount', safeFloat(value.outstandingAmount));
    } else {
        setSelectedParty(null);
        setFieldValue('referenceId', 0);
        setFieldValue('referenceName', '');
        setFieldValue('taxIdNumber', '');
        setFieldValue('outstandingAmount', 0);
    }
}

export function clearItemValues(setFieldValue, setSelectedProduct, clearBatchDetails = false) {
    if (clearBatchDetails === true) {
        setFieldValue('batchItems', []);
        setFieldValue('mfgDate', '');
        setFieldValue('expiryDate', '');
        setFieldValue('batchNumber', '');
        setFieldValue('showBatchSerialNumber', false);
        setFieldValue('serialNumbers', '');
    }
    setSelectedProduct(null);
    setFieldValue('productId', 0);
    setFieldValue('productDescription', '');
    setFieldValue('orderDetailInfoText', '');
    setFieldValue('quantity', '');
    setFieldValue('quantityDescription', '');
    setFieldValue('freeQuantity', '');
    setFieldValue('ptsptr', '');
    setFieldValue('pts', '');
    setFieldValue('ptr', '');

    setFieldValue('unitPrice', 0);
    setFieldValue('unitPriceIncludingTax', 0);
    setFieldValue('maximumRetailPrice', 0);
    setFieldValue('taxRate', 0);
    setFieldValue('discountAmount', 0.00);
    setFieldValue('discountPercent', 0);
    setFieldValue('cessAmount', 0.00);
    setFieldValue('cessPercent', 0);
    setFieldValue('lineTotal', 0);
    setFieldValue('unitOfMeasurement', '');
    setFieldValue('conversionFactor', 1);
    setTimeout(() => {
        setInputFocus("productDescription");
    });
}

export function setItemValues(value, setFieldValue, setSelectedProduct, response = 0, addStandardDiscount = false, useMRP = false, entityName = 'Invoice', taxInclusivePricing = false) {
    if (!isNullOrUndefined(value)) {
        setSelectedProduct(value);
        setFieldValue('productId', safeInt(value.id));
        setFieldValue('productType', safeString(value.productType));
        setFieldValue('productDescription', value.description);
        setFieldValue('useUnitConversion', safeBool(value.useUnitConversion));
        let unitPrice = 0;
        let unitPriceIncludingTax = 0;
        let maximumRetailPrice = 0;
        let pts = 0;
        let ptr = 0;
        if (isNaN(response)) {
            if (safeFloat(response.unitPrice) === 0) {
                if (entityName === 'PurchaseBill' || entityName === 'PurchaseOrder' || entityName === 'DebitNote') {
                    unitPrice = parseFloat(safeFloat(value.buyPrice).toFixed(2));
                } else {
                    unitPrice = parseFloat(safeFloat(value.sellPrice).toFixed(2));
                    unitPriceIncludingTax = safeAmount(value.sellPriceIncludingTax);
                }
            } else {
                unitPrice = parseFloat(safeFloat(response.unitPrice).toFixed(2));
                unitPriceIncludingTax = safeAmount(response.unitPriceIncludingTax);
            }
            if (safeFloat(response.maximumRetailPrice) === 0) {
                maximumRetailPrice = parseFloat(safeFloat(value.maximumRetailPrice).toFixed(2));
            } else {
                maximumRetailPrice = parseFloat(safeFloat(response.maximumRetailPrice).toFixed(2));
            }
            if (safeFloat(response.pts) === 0) {
                pts = parseFloat(safeFloat(value.pts).toFixed(2));
            } else {
                pts = parseFloat(safeFloat(response.pts).toFixed(2));
            }
            if (safeFloat(response.ptr) === 0) {
                ptr = parseFloat(safeFloat(value.ptr).toFixed(2));
            } else {
                ptr = parseFloat(safeFloat(response.ptr).toFixed(2));
            }
        } else {
            if (response === 0) {
                if (entityName === 'PurchaseBill' || entityName === 'PurchaseOrder' || entityName === 'DebitNote') {
                    unitPrice = parseFloat(safeFloat(value.buyPrice).toFixed(2));
                } else {
                    unitPrice = parseFloat(safeFloat(value.sellPrice).toFixed(2));
                    unitPriceIncludingTax = parseFloat(safeFloat(value.sellPriceIncludingTax).toFixed(2));
                }
                maximumRetailPrice = parseFloat(safeFloat(value.maximumRetailPrice).toFixed(2));
                pts = parseFloat(safeFloat(value.pts).toFixed(2));
                ptr = parseFloat(safeFloat(value.ptr).toFixed(2));
            } else {
                unitPrice = parseFloat(safeFloat(response).toFixed(2));
            }
        }

        // if (taxInclusivePricing) {
        //     setFieldValue('unitPriceIncludingTax', getAmountIncludingTax(unitPrice, value.taxRate));
        // }
        setFieldValue('unitPrice', unitPrice);
        setFieldValue('unitPriceIncludingTax', unitPriceIncludingTax);
        setFieldValue('maximumRetailPrice', maximumRetailPrice);
        setFieldValue('pts', pts);
        setFieldValue('ptr', ptr);
        setFieldValue('lineTotal', getAmountIncludingTax(unitPrice, value.taxRate));

        setFieldValue('quantity', 1);
        setFieldValue('hsnCode', value.hsnCode);
        setFieldValue('unitOfMeasurement', value.unitOfMeasurement);
        setFieldValue('taxRate', value.taxRate);
        setFieldValue('discountPercent', safeFloat(value.standardDiscount));

        if (value.productType !== 'Miscellaneous') {
            setInputFocus("quantity");
        } else {
            setInputFocus("unitPrice");
        }

        if (safeBool(value.useBatchTracking) === true) {
            setFieldValue('showBatchSerialNumber', true);
        } else {
            setFieldValue('showBatchSerialNumber', false);
        }

        setFieldValue("useSerialTracking", safeBool(value.useSerialTracking));
    } else {
        setSelectedProduct(null);
        setFieldValue('productId', 0);
        setFieldValue('productType', '');
        setFieldValue('productDescription', '');
    }
}

function showBatchWarning(item) {
    var returnVal = false;
    if (safeBool(item.useBatchTracking)) {
        if (isEmptyObjectArray(item.batchItems)) {
            returnVal = true;
        }
        if (item.batchItems.length === 1) {
            if (safeString(item.batchItems[0].batchNumber).length === 0) {
                returnVal = true;
            }
        }
    } else {
        returnVal = false;
    }
    return returnVal;
}

export function getOrderItems(orderDetails, isSummaryView) {
    if (!!orderDetails && orderDetails.length > 0) {
        let i = 0;
        orderDetails.map(item => {
            i++;
            item.index = i;
            item.id = safeInt(item.id);
            item.description = safeString(item.productDescription);
            item.orderDetailInfoText = safeString(item.orderDetailInfoText);
            item.taxRateDisplay = item.taxRate + "%";
            item.taxAmount = item.stateTaxAmount + item.centralTaxAmount + item.interStateTaxAmount;
            item.discountPercentDisplay = getDiscountPercentDisplay(item.discountPercent);
            if (!isNullOrEmpty(item.baseUnitOfMeasurement) && safeInt(item.conversionFactor) > 1) {
                item.unitOfMeasurementDisplay = safeString(item.unitOfMeasurement) + ' (' + safeInt(item.conversionFactor) + ' ' + safeString(item.baseUnitOfMeasurement) + ')';
            } else {
                item.unitOfMeasurementDisplay = safeString(item.unitOfMeasurement);
            }
            item.mfgDate = getLocaleDate(item.mfgDate);
            item.expiryDate = getLocaleDate(item.expiryDate);
            if (item.productType === 'Services') {
                item.quantityDisplay = '-';
            }
            if (isSummaryView) {
                item.showBatchWarning = showBatchWarning(item);
            } else {
                item.showBatchWarning = false;
            }
            // if (isSummaryView && safeBool(item.useBatchTracking) && (isEmptyObjectArray(item.batchItems)) {
            //     item.showBatchWarning = true;
            // } else {
            //     item.showBatchWarning = false;
            // }

            if (safeBool(item.useSerialTracking) && !isNullOrEmpty(item.serialNumbers)) {
                item.serialNumbers = item.serialNumbers.replaceAll(",", ", ");
            }

            if (isSummaryView && safeBool(item.useSerialTracking) && isNullOrEmpty(item.serialNumbers)) {
                item.showSerialWarning = true;
            } else {
                item.showSerialWarning = false;
            }
            item.cessPercent = safeAmount(item.cessPercent);
            item.cessPercentDisplay = safeAmount(item.cessPercent) > 0 ? item.cessPercent + "%" : '-';

            // For mobile view
            item.shortDescription = safeString(item.productDescription);
            item.itemDetail = "(" + safeString(item.quantity) + ' * ' + safeString(item.unitPrice) + ") " + safeFloat(item.taxableAmount).toFixed(2);

            if (item.productType === 'Miscellaneous' && safeFloat(item.unitPrice) === 0 && safeFloat(item.unitPriceIncludingTax) === 0) {
                item.quantity = '';
                item.unitOfMeasurement = '';
                item.discountPercentDisplay = '';
                item.taxRateDisplay = '';
                item.cessPercentDisplay = '';
            }
        });
    }
    return orderDetails;

}

export function getHSNSummaryDisplay(hsnSummary) {
    if (!isNullOrUndefined(hsnSummary) && hsnSummary.length > 0) {
        var taxableAmountTotal = 0;
        var cgstTotal = 0;
        var igstTotal = 0;
        var taxGrandTotal = 0;
        hsnSummary.map(item => {
            item.cgstRateDisplay = item.centralTaxAmount > 0 ? (item.taxRate / 2) + '%' : '';
            item.igstRateDisplay = item.interStateTaxAmount > 0 ? item.taxRate + '%' : '';
            item.taxTotal = safeFloat(item.stateTaxAmount + item.centralTaxAmount + item.interStateTaxAmount).toFixed(2);
            item.interStateTaxAmountDisplay = item.interStateTaxAmount > 0 ? safeFloat(item.interStateTaxAmount).toFixed(2) : '';
            item.centralTaxAmountDisplay = item.centralTaxAmount > 0 ? safeFloat(item.centralTaxAmount).toFixed(2) : '';

            taxableAmountTotal += item.taxableAmount;
            cgstTotal += item.centralTaxAmount;
            igstTotal += item.interStateTaxAmount;
            taxGrandTotal += safeFloat(item.stateTaxAmount + item.centralTaxAmount + item.interStateTaxAmount);
        });
        hsnSummary.push(
            {
                hsnCode: 'TOTAL',
                taxableAmount: taxableAmountTotal,
                cgstRateDisplay: '',
                taxTotal: safeFloat(taxGrandTotal).toFixed(2),
                interStateTaxAmountDisplay: igstTotal > 0 ? safeFloat(igstTotal).toFixed(2) : '',
                centralTaxAmountDisplay: cgstTotal > 0 ? safeFloat(cgstTotal).toFixed(2) : ''
            }
        );
    }
    return hsnSummary;
}

export function getOrderSummary(order) {
    try {
        let orderSummary = [];
        if (safeBool(order.isTaxInclusive)) {
            orderSummary.push({
                id: 990,
                description: 'Total:',
                lineTotal: safeFloat(order.grandTotal).toFixed(2),
                shortDescription: 'Total:',
                itemTotal: safeFloat(order.grandTotal).toFixed(2),
            });
        }
        orderSummary.push({
            id: 990,
            description: 'Taxable total:',
            lineTotal: safeFloat(order.orderTotal).toFixed(2),
            shortDescription: 'Taxable total:',
            itemTotal: safeFloat(order.orderTotal).toFixed(2),
        });
        if (order.isIGST === true) {
            orderSummary.push({
                id: 991,
                description: 'IGST:',
                lineTotal: parseFloat(order.taxAmount).toFixed(2),
                shortDescription: 'IGST:',
                itemTotal: parseFloat(order.taxAmount).toFixed(2),
            });
        } else {
            orderSummary.push({
                id: 991,
                description: 'CGST:',
                lineTotal: parseFloat(order.taxAmount / 2).toFixed(2),
                shortDescription: 'CGST:',
                itemTotal: parseFloat(order.taxAmount / 2).toFixed(2),
            });
            orderSummary.push({
                id: 992,
                description: 'SGST:',
                lineTotal: parseFloat(order.taxAmount / 2).toFixed(2),
                shortDescription: 'SGST:',
                itemTotal: parseFloat(order.taxAmount / 2).toFixed(2),
            });
        }

        if (safeFloat(order.cessAmount) > 0) {
            orderSummary.push({
                id: 996,
                description: 'Total Cess:',
                lineTotal: safeFloat(order.cessAmount).toFixed(2),
                shortDescription: 'Cess:',
                itemTotal: safeFloat(order.cessAmount).toFixed(2),
            });
        }

        if (safeFloat(order.orderDiscountAmount) > 0) {
            orderSummary.push({
                id: 993,
                description: 'Discount:',
                lineTotal: safeFloat(order.orderDiscountAmount * -1).toFixed(2),
                shortDescription: 'Discount:',
                itemTotal: safeFloat(order.orderDiscountAmount * -1).toFixed(2),
            });
        }

        if (safeFloat(order.roundOffAmount) !== 0) {
            orderSummary.push({
                id: 994,
                description: 'Round-off:',
                lineTotal: parseFloat(order.roundOffAmount).toFixed(2),
                shortDescription: 'Round-off:',
                itemTotal: parseFloat(order.roundOffAmount).toFixed(2),
            });
        }

        orderSummary.push({
            id: 995,
            description: 'Grand total:',
            lineTotal: parseFloat(order.grandTotalDisplay).toFixed(2),
            shortDescription: 'Grand total:',
            itemTotal: parseFloat(order.grandTotalDisplay).toFixed(2),
        });

        return orderSummary;
    } catch (error) {
        console('Error occured in order summary. Please retry or contact support.', 'error');
        return [];
    }

}

export function getTaxAmount(item) {
    if (safeAmount(item.taxRate) > 0) {
        let total = safeAmount(item.quantity) * safeAmount(item.unitPrice);
        let discountAmount = 0;
        if (safeAmount(item.discountPercent) > 0) {
            discountAmount = total * (item.discountPercent / 100);
            total -= discountAmount;
        }
        return parseFloat(safeAmount(total * (item.taxRate / 100)).toFixed(2));
    }
    return 0;
}

export function getTaxTotals(item, isIGST) {
    let taxTotal = getTaxAmount(item);
    if (isIGST === true) {
        return { cgst: 0, sgst: 0, igst: taxTotal };
    } else {
        return { cgst: safeAmount((taxTotal / 2).toFixed(2)), sgst: safeAmount((taxTotal / 2).toFixed(2)), igst: 0 };
    }
}

export function getDiscountAmount(item) {
    let total = safeAmount(item.quantity) * safeAmount(item.unitPrice);
    let discountAmount = 0;
    if (safeAmount(item.discountPercent) > 0) {
        discountAmount = safeAmount(total) * (safeAmount(item.discountPercent) / 100);
    }
    return parseFloat(safeAmount(discountAmount).toFixed(2));
}

export function getCessAmount(item) {
    let cessAmount = 0;
    let total = safeAmount(item.quantity) * safeAmount(item.unitPrice);
    let discountAmount = getDiscountAmount(item);
    total = total - discountAmount;

    if (safeAmount(item.cessPercent) > 0) {
        cessAmount = safeAmount(total) * (safeAmount(item.cessPercent) / 100);
    }
    return parseFloat(safeAmount(cessAmount).toFixed(2));
}

// export function getProuctFullDescription(item) {
//     return safeString(item.productDescription) + " " + safeString(item.orderDetailInfoText);
// }

export function getDiscountPercentDisplay(discountPercent) {
    return safeAmount(discountPercent) > 0 ? discountPercent + "%" : "-"
}

export function getItemDetail(item) {
    let itemDetail = "(" + safeString(item.quantity) + ' * ' + safeString(item.unitPrice.toFixed(2));
    itemDetail += item.discountPercent > 0 ? " - " + item.discountPercent + "%) " : ") ";
    itemDetail += safeAmount(item.taxableAmount).toFixed(2);
    return itemDetail;
}

export function getLineTotal(item, includeTax = true, taxInclusivePricing = false) {
    let total = 0;
    let discountAmount = 0;
    let cessAmount = 0;
    if (taxInclusivePricing) {
        total = safeAmount(item.quantity) * safeAmount(item.unitPriceIncludingTax);
    } else {
        total = safeAmount(item.quantity) * safeAmount(item.unitPrice);
    }
    if (!!item.discountPercent && item.discountPercent > 0) {
        discountAmount = total * (item.discountPercent / 100);
        total -= discountAmount;
    }
    if (includeTax && safeFloat(item.cessPercent) > 0) {
        cessAmount = total * (item.cessPercent / 100);
    }
    if (includeTax && safeFloat(item.taxRate) > 0) {
        total += total * (item.taxRate / 100);
    }
    // Add CESS at the end
    total += cessAmount;
    return safeAmount(total);
}

export function printVoucher(printData, entityType, callback) {
    switch (entityType) {
        case Common.QUOTATION_ENTITY:
            PrintPDF.printQuotation(printData, entityType, callback);
            break;
        case Common.INVOICE_ENTITY:
            PrintPDF.printInvoice(printData, entityType, callback);
            break;
        case Common.DELIVERY_CHALLAN_ENTITY:
            PrintPDF.printDeliveryChallan(printData, entityType, callback);
            break;
        case Common.CREDIT_NOTE_ENTITY:
            PrintPDF.printCreditNote(printData, entityType, callback);
            break;
        case Common.DEBIT_NOTE_ENTITY:
            PrintPDF.printDebitNote(printData, entityType, callback);
            break;
        case Common.PURCHASE_ORDER_ENTITY:
            PrintPDF.printPurchaseOrder(printData, entityType, callback);
            break;
        default:
            break;
    }
}

export async function getPartyAddress(referenceId, referenceType, authContext) {
    try {
        let partyAddress = null;
        const party = await getPartyAddress(referenceId, referenceType, authContext);
        if (!isNullOrUndefined(party) && !isNullOrUndefined(party.addressList)) {
            partyAddress = party.addressList.find(a => a.addressType === 'Billing');
        }
        return partyAddress;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getParty(referenceId, referenceType, authContext) {
    try {
        var party = null;
        if (isNullOrUndefined(referenceId) || referenceId === 0) {
            console.log('Invalid reference id provided: ' + referenceId);
        } else {
            var response = await getData(referenceType, 'Get' + referenceType + 'ById', authContext, null, referenceId);
            if (!isNullOrUndefined(response)) {
                if (!isNullOrUndefined(response.code) && response.code > 0) {
                    console.log(JSON.stringify(response));
                } else {
                    party = response;
                }
            }
        }
        return party;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getOrderLite(orderId, orderType, authContext) {
    try {
        var order = null;
        if (isNullOrUndefined(orderId) || orderId === 0) {
            console.log('Invalid order id provided: ' + orderId);
        } else {
            var response = await getData(orderType, 'Get' + orderType + 'LiteById', authContext, null, orderId);
            if (!isNullOrUndefined(response)) {
                if (!isNullOrUndefined(response.code) && response.code > 0) {
                    console.log(JSON.stringify(response));
                    return null;
                } else {
                    order = response;
                }
            }
            return order;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCustomerLite(customerId, authContext) {
    try {
        var outstandingAmount = null;
        if (isNullOrUndefined(customerId) || customerId === 0) {
            console.log('Invalid customer id provided: ' + customerId);
        } else {
            var response = await getData(Common.CUSTOMER_ENTITY, 'GetCustomerLite', authContext, null, customerId);
            if (!isNullOrUndefined(response)) {
                if (!isNullOrUndefined(response.code) && response.code > 0) {
                    console.log(JSON.stringify(response));
                    return null;
                } else {
                    outstandingAmount = response?.outstandingAmount;
                }
            }
            return outstandingAmount;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getOrder(orderId, orderType, authContext) {
    try {
        var order = null;
        if (isNullOrUndefined(orderId) || orderId === 0) {
            console.log('Invalid order id provided: ' + orderId);
        } else {
            var response = await getData(orderType, 'Get' + orderType + 'ById', authContext, null, orderId);


            if (!isNullOrUndefined(response)) {
                if (!isNullOrUndefined(response.code) && response.code > 0) {
                    console.log(JSON.stringify(response));
                    return null;
                } else {
                    // Reformat date properties
                    response.orderDate = response.orderDate.slice(0, 10);
                    if (orderType === Common.INVOICE_ENTITY) {
                        response.deliveryDate = response.deliveryDate.slice(0, 10);
                        response.paymentDueDate = response.paymentDueDate.slice(0, 10);
                    }
                    // Check if rounding enabled
                    if (authContext.settings['roundOff' + orderType + 'Total'] === true) {
                        response.grandTotalDisplay = Math.round(safeFloat(response.grandTotal));
                    } else {
                        response.grandTotalDisplay = safeFloat(response.grandTotal);
                    }

                    order = response;
                }
            }
            return order;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getAddress(addressId, authContext) {
    try {
        var address = null;
        if (isNullOrUndefined(addressId) || addressId === 0) {
            console.log('Invalid address id provided: ' + addressId);
        } else {
            var response = await getData("Address", 'GetAddressById', authContext, null, addressId);
            if (!isNullOrUndefined(response)) {
                if (!isNullOrUndefined(response.code) && response.code > 0) {
                    console.log(JSON.stringify(response));
                } else {
                    address = response;
                }
            }
        }
        return address;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getBankList(authContext) {
    try {
        var response = await getData('BankDetails', 'GetBankDetailsList', authContext, null, authContext.currentUser.subscriberId);
        var bankList = [];
        if (!isNullOrUndefined(response)) {
            if (!isNullOrUndefined(response.code) && response.code > 0) {
                console.log(JSON.stringify(response));
            } else {
                bankList = response;
            }
        }
        return bankList;
    } catch (error) {
        console.log(error);
        return [];
    }
}


export async function printOrder(orderId, entityType, referenceId, referenceType, authContext, callback) {
    await processPrintPdf(orderId, entityType, referenceId, referenceType, authContext, Common.PDF_OUTPUT_URL, callback);
}

export async function generatePdfAttachment(orderId, entityType, referenceId, referenceType, authContext, callback) {
    await processPrintPdf(orderId, entityType, referenceId, referenceType, authContext, Common.PDF_OUTPUT_BUFFER, callback);
}


export function getDeliveryAddress(selectedOrder, selectedParty) {
    var deliveryAddress = null;
    if (safeInt(selectedOrder.deliveryAddressId) > 0) {
        if (!isNullOrUndefined(selectedParty.addressList) && selectedParty.addressList.length > 0) {
            deliveryAddress = selectedParty.addressList.find(a => a.id === safeInt(selectedOrder.deliveryAddressId));
        }
    }
    return deliveryAddress;
}

export function getAdditionalDetails(entityObject) {
    try {
        if (!isNullOrUndefined(entityObject) && !isNullOrUndefined(entityObject.additionalDetails) && !isNullOrEmpty(entityObject.additionalDetails)) {
            return JSON.parse(entityObject.additionalDetails);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export async function processPrintPdf(orderId, entityType, referenceId, referenceType, authContext, outputType, callback) {
    var printData = null;
    var bankList = null;
    let bankDetail = null;
    var hsnSummaryData = null;
    var selectedOrder = await getOrder(orderId, entityType === 'Proforma' ? 'Quotation' : entityType, authContext);
    var selectedParty = await getParty(referenceId, referenceType, authContext);
    const additionalDetails = getAdditionalDetails(selectedOrder);

    let eInvoiceData = null;

    if (!isNullOrUndefined(selectedOrder) && !isNullOrUndefined(selectedParty)) {

        switch (entityType) {
            case "Proforma":
            case Common.QUOTATION_ENTITY:
                bankList = await getData('BankDetails', 'GetBankDetailsList', authContext, null, safeInt(authContext.currentSubscriber.id));

                var headingText = ((entityType === 'Proforma') ? 'Proforma Invoice' : safeString(authContext.settings.quotationDocumentHeading));
                if (entityType === 'Proforma') {
                    entityType = Common.QUOTATION_ENTITY;
                }

                if (!isNullOrUndefined(additionalDetails) && safeInt(additionalDetails.bankId) > 0) {
                    bankDetail = bankList.find(b => b.id === safeInt(additionalDetails.bankId));
                } else {
                    bankDetail = bankList.find(b => b.isPrimary === true);
                }
                printData = {
                    heading: headingText,
                    party: selectedParty,
                    deliveryAddress: getDeliveryAddress(selectedOrder, selectedParty),
                    voucherData: selectedOrder,
                    paymentInfo: null,
                    voucherItems: getOrderItems(selectedOrder.quotationDetails),
                    hsnSummary: null,//getHSNSummaryDisplay(hsnSummaryData),
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    customFields: authContext.customFields,
                    stateList: getMasterData('State', authContext),
                    bankDetails: bankDetail,
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.legalName,
                    outputType: outputType
                }
                switch (safeString(authContext.settings.quotationFormat)) {
                    case Common.INVOICE_FORMAT_COMFORT:
                    case Common.INVOICE_FORMAT_COMFORT_LOGO:
                    case Common.INVOICE_FORMAT_ELEGANT:
                    case Common.INVOICE_FORMAT_ELEGANT_LOGO:
                        PrintPDF.printQuotationElegant(printData, entityType, callback);
                        break;
                    case Common.INVOICE_FORMAT_CLASSIC_COMPACT:
                        printData.compact = true;
                        PrintPDF.printQuotation(printData, entityType, callback);
                        break;
                    case Common.INVOICE_FORMAT_PROFESSIONAL:
                        processPrintOrder(printData, entityType, callback);
                        break;
                    /* case Common.INVOICE_FORMAT_RETAIL_POS:                    
                      case Common.INVOICE_FORMAT_RETAIL_POS_LOGO:
                         printInvoicePOS(printData, callback);
                         break;
                     case Common.INVOICE_FORMAT_RETAIL_POS_NOHEADER:
                         printData.noHeader = true;
                         printInvoicePOS(printData, callback);
                         break; */
                    default:
                        PrintPDF.printQuotation(printData, entityType, callback);
                        break;
                }

                break;
            case Common.INVOICE_ENTITY:
                var orderItems = getOrderItems(selectedOrder.invoiceDetails);
                hsnSummaryData = await getDataList(entityType, 'GetInvoiceHSNSummary', orderId, authContext, null, 'put');
                bankList = await getData('BankDetails', 'GetBankDetailsList', authContext, null, safeInt(authContext.currentSubscriber.id));

                if (!isNullOrUndefined(additionalDetails) && safeInt(additionalDetails.bankId) > 0) {
                    bankDetail = bankList.find(b => b.id === safeInt(additionalDetails.bankId));
                } else {
                    bankDetail = bankList.find(b => b.isPrimary === true);
                }
                let settings = { ...authContext.settings };
                if (!isNullOrUndefined(additionalDetails) && !isNullOrEmpty(additionalDetails.includeBatchDetailsInInvoice)) {
                    settings.includeBatchDetailsInInvoice = safeString(additionalDetails.includeBatchDetailsInInvoice);
                }
                if (!isNullOrEmpty(selectedOrder.irnNo)) {
                    eInvoiceData = await getDataList("Invoice", "GetEInvoiceByInvoiceId", safeInt(orderId), authContext, null, 'post');
                }
                selectedOrder["eInvoiceData"] = eInvoiceData;
                let paymentInfo = null;
                if (safeBool(authContext.settings.printPaymentInfo)) {
                    paymentInfo = await getOrderLite(orderId, entityType, authContext);
                }
                let totalPaymentDueAmount = null;
                if (safeBool(authContext.settings.printTotalPendingAmount)) {
                    totalPaymentDueAmount = await getCustomerLite(referenceId, authContext)
                }
                printData = {
                    heading: (safeInt(authContext.currentSubscriber.taxRegistrationTypeId) === Common.TAX_REG_COMPOSITION_SCHEME ? 'Bill of Supply' : safeString(authContext.settings.invoiceDocumentHeading)),
                    party: selectedParty,
                    deliveryAddress: getDeliveryAddress(selectedOrder, selectedParty),
                    voucherData: selectedOrder,
                    voucherItems: orderItems,
                    paymentInfo: paymentInfo,
                    totalPaymentDueAmount: totalPaymentDueAmount,
                    hsnSummary: getHSNSummaryDisplay(hsnSummaryData),
                    subscriber: authContext.currentSubscriber,
                    settings: settings,
                    customFields: authContext.customFields,
                    stateList: getMasterData('State', authContext),
                    bankDetails: bankDetail,
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.legalName,
                    outputType: outputType
                }
                switch (safeString(authContext.settings.invoiceFormat)) {
                    case Common.INVOICE_FORMAT_COMFORT:
                    case Common.INVOICE_FORMAT_COMFORT_LOGO:
                    case Common.INVOICE_FORMAT_ELEGANT:
                    case Common.INVOICE_FORMAT_ELEGANT_LOGO:
                        PrintPDF.printInvoiceElegant(printData, entityType, callback);
                        break;
                    case Common.INVOICE_FORMAT_CLASSIC_COMPACT:
                        printData.compact = true;
                        PrintPDF.printInvoice(printData, entityType, callback);
                        break;
                    case Common.INVOICE_FORMAT_RETAIL_POS:
                    case Common.INVOICE_FORMAT_RETAIL_POS_LOGO:
                        printInvoicePOS(printData, callback);
                        break;
                    case Common.INVOICE_FORMAT_RETAIL_POS_NOHEADER:
                        printData.noHeader = true;
                        printInvoicePOS(printData, callback);
                        break;
                    case Common.INVOICE_FORMAT_CUSTOM_1:
                        PrintPDF.printInvoice(printData, entityType, callback);
                        break;
                    case Common.INVOICE_FORMAT_PROFESSIONAL:
                        processPrintOrder(printData, entityType, callback);
                        break;
                    default:
                        PrintPDF.printInvoice(printData, entityType, callback);
                        break;
                }
                break;
            case Common.DELIVERY_CHALLAN_ENTITY:
                printData = {
                    heading: 'Delivery challan',
                    party: selectedParty,
                    deliveryAddress: getDeliveryAddress(selectedOrder, selectedParty),
                    voucherData: selectedOrder,
                    voucherItems: getOrderItems(selectedOrder.deliveryChallanDetails),
                    hsnSummary: null,
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    stateList: getMasterData('State', authContext),
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.legalName,
                    customFields: authContext.customFields,
                    outputType: outputType
                }
                PrintPDF.printDeliveryChallan(printData, entityType, callback);
                break;
            case Common.CREDIT_NOTE_ENTITY:
                if (!isNullOrEmpty(selectedOrder.irnNo)) {
                    eInvoiceData = await getDataList("Invoice", "GetEInvoiceByInvoiceId", safeInt(orderId), authContext, null, 'post');
                }
                selectedOrder["eInvoiceData"] = eInvoiceData;

                printData = {
                    heading: 'Credit Note',
                    party: selectedParty,
                    voucherData: selectedOrder,
                    voucherItems: getOrderItems(selectedOrder.creditNoteDetails),
                    hsnSummary: null,
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    stateList: getMasterData('State', authContext),
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.legalName,
                    outputType: outputType
                }
                switch (safeString(authContext.settings.creditNoteFormat)) {
                    case Common.INVOICE_FORMAT_PROFESSIONAL:
                        processPrintOrder(printData, entityType, callback);
                        break;
                    default:
                        PrintPDF.printCreditNote(printData, entityType, callback);
                        break;
                }
                break;
            case Common.DEBIT_NOTE_ENTITY:
                printData = {
                    heading: 'Debit Note',
                    party: selectedParty,
                    voucherData: selectedOrder,
                    voucherItems: getOrderItems(selectedOrder.debitNoteDetails),
                    hsnSummary: null,
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    stateList: getMasterData('State', authContext),
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.name,
                    outputType: outputType
                }
                PrintPDF.printDebitNote(printData, entityType, callback);
                break;
            case Common.PURCHASE_ORDER_ENTITY:
                printData = {
                    heading: 'Purchase Order',
                    party: selectedParty,
                    voucherData: selectedOrder,
                    voucherItems: getOrderItems(selectedOrder.purchaseOrderDetails),
                    deliveryAddress: getDeliveryAddress(selectedOrder, selectedParty),
                    hsnSummary: null,
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    stateList: getMasterData('State', authContext),
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.name,
                    customFields: authContext.customFields,
                    outputType: outputType
                }
                PrintPDF.printPurchaseOrder(printData, entityType, callback);
                break;
            case Common.PURCHASE_BILL_ENTITY:
                printData = {
                    heading: 'Purchase Bill',
                    party: selectedParty,
                    voucherData: selectedOrder,
                    voucherItems: getOrderItems(selectedOrder.purchaseBillDetails),
                    deliveryAddress: getDeliveryAddress(selectedOrder, selectedParty),
                    hsnSummary: null,
                    subscriber: authContext.currentSubscriber,
                    settings: authContext.settings,
                    stateList: getMasterData('State', authContext),
                    downloadFileName: selectedOrder.orderNumber + " " + selectedParty.name,
                    customFields: authContext.customFields,
                    outputType: outputType
                }
                PrintPDF.printPurchaseBill(printData, entityType, callback);
                break;
            default:
                break;
        }
    } else {
        callback({ error: 'Failed to print ' + entityType });
    }
}
export async function sendSMS(mobileNumber, orderType, referenceType, selectedOrder, selectedParty, showAlert, authContext) {
    var smsRequest = {
        "docInfoTypeId": 1,
        "applicationId": 1,
        "referenceId": safeInt(selectedParty.id),
        "referenceType": referenceType,
        "docUID": "",
        "orderId": safeInt(selectedOrder.id),
        "orderTypeId": getOrderTypeId(orderType),
        "providerName": "TEXTLOCAL",
        "mode": 0,
        "mobile": safeString(mobileNumber),
        "active": true
    };
    var smsResponse = await WebAPI.post('/' + orderType + '/SMS' + orderType, smsRequest, {
        headers: { "AccessToken": authContext.token },
        cancelToken: null
    });
    if (!isNullOrUndefined(showAlert)) {
        if (!!smsResponse.data.code && smsResponse.data.code > 0) {
            showAlert(smsResponse.data.message, 'error');
        } else {
            showAlert('SMS sent successfully.', 'success');
        }
    }
}

export async function sendWhatsApp(mobileNumber, orderType, referenceType, selectedOrder, selectedParty, showAlert, authContext, fromContacts = false) {

    var smsRequest = {
        "docInfoTypeId": 1,
        "applicationId": 1,
        "referenceId": safeInt(selectedParty?.id),
        "referenceType": referenceType,
        "docUID": "",
        "orderId": safeInt(selectedOrder?.id),
        "orderTypeId": getOrderTypeId(orderType),
        "providerName": "TEXTLOCAL",
        "mode": 0,
        "mobile": ifNullOrEmpty(safeString(mobileNumber), '7483463220'),
        "active": true
    };
    var waResponse = await WebAPI.post('/' + orderType + '/WhatsApp' + orderType, smsRequest, {
        headers: { "AccessToken": authContext.token },
        cancelToken: null
    });
    if (!!waResponse.data.code && waResponse.data.code > 0) {
        showAlert(waResponse.data.message, 'error');
    } else {
        if (fromContacts || isNullOrEmpty(mobileNumber)) {
            window.open(`https://api.whatsapp.com/send/?text=${waResponse.data}`, '_blank');
        } else {
            window.open(`https://api.whatsapp.com/send/?phone=${safeString(mobileNumber)}&text=${waResponse.data}`, '_blank');
        }
        // window.open(`https://api.whatsapp.com/send/?text=Namaskar!%0a%0aClick the link below to view your invoice from YOUR COMPANY:%0ahttps://www.gst.ezybill.in/docs/GAG6B%0a%0awww.ezybill.in%0aEzy bhi! Sahi bhi!`, '_blank')
    }
}

export async function sendEmail(orderType, referenceType, selectedOrder, selectedParty, showAlert, buffer, authContext, values = null) {
    var emailRequest = {
        "subject": selectedOrder.orderNumber + '_' + (referenceType === Common.CUSTOMER_ENTITY ? selectedParty.legalName : selectedParty.name),
        "body": "Dear Sir/Madam, <br><br> Please find the attached " + orderType + "_" + selectedOrder.orderNumber + ".pdf.<br><br> Regards, <br> " + authContext.currentSubscriber.legalName + '<br><br> <a href="https://www.ezybill.in">Powered by ezybill</a>',
        "format": 1,
        "mailTemplate": 0,
        "referenceId": safeInt(selectedParty.id),
        "referenceType": referenceType,
        "orderId": safeInt(selectedOrder.id),
        "orderTypeId": getOrderTypeId(orderType),
        "paymentId": 0,
        "attachments": buffer,
        "emailAddress": ifNullOrEmpty(safeString(values?.emailAddress), '')
    };
    var emailResponse = await WebAPI.post('/' + orderType + '/Email' + orderType, emailRequest, {
        headers: { "AccessToken": authContext.token },
        cancelToken: null
    });
    if (!!emailResponse.data.code && emailResponse.data.code > 0) {
        showAlert(emailResponse.data.message, 'error');
    } else {
        showAlert('Email sent successfully.', 'success');
    }
}

export function getOrderType(orderTypeId) {
    switch (orderTypeId) {
        case Common.ORDER_TYPE_INVOICE:
            return Common.INVOICE_ENTITY;
        case Common.ORDER_TYPE_CR_NOTE:
            return Common.CREDIT_NOTE_ENTITY;
        case Common.ORDER_TYPE_DR_NOTE:
            return Common.DEBIT_NOTE_ENTITY;
        case Common.ORDER_TYPE_DELIVERY_CHALLAN:
            return Common.DELIVERY_CHALLAN_ENTITY;
        case Common.ORDER_TYPE_PURCHASE_BILL:
            return Common.PURCHASE_BILL_ENTITY;
        case Common.ORDER_TYPE_PURCHASE_ORDER:
            return Common.PURCHASE_ORDER_ENTITY;
        case Common.ORDER_TYPE_QUOTATION:
            return Common.QUOTATION_ENTITY;
        default:
            return "";
    }
}
export function getOrderTypeId(orderType) {
    switch (orderType) {
        case Common.INVOICE_ENTITY:
            return Common.ORDER_TYPE_INVOICE;
        case Common.CREDIT_NOTE_ENTITY:
            return Common.ORDER_TYPE_CR_NOTE;
        case Common.DEBIT_NOTE_ENTITY:
            return Common.ORDER_TYPE_DR_NOTE;
        case Common.DELIVERY_CHALLAN_ENTITY:
            return Common.ORDER_TYPE_DELIVERY_CHALLAN;
        case Common.PURCHASE_BILL_ENTITY:
            return Common.ORDER_TYPE_PURCHASE_BILL;
        case Common.PURCHASE_ORDER_ENTITY:
            return Common.ORDER_TYPE_PURCHASE_ORDER;
        case Common.QUOTATION_ENTITY:
            return Common.ORDER_TYPE_QUOTATION;
        default:
            return 0;
    }
}

export const getUomDesc = (product) => {
    var uomDescription = "";
    if (!isNullOrUndefined(product)) {
        uomDescription = safeString(product.unitOfMeasurement);
    }
    return uomDescription;
}

export const getProductStockDescription = (product) => {
    var stockDescription = "";
    if (safeBool(product.useInventory) && safeString(product.productType) === "Goods") {
        if (safeFloat(product.stockInHandQty) > 0) {
            stockDescription = "In stock: " + safeString(product.stockInHandQty) + " " + safeString(product.unitOfMeasurement)
        } else {
            stockDescription = "Out of stock: " + safeString(product.stockInHandQty)
        }
    } else if (safeString(product.categoryName).trim().length > 0) {
        stockDescription += "Category: " + safeString(product.categoryName)
    }

    return stockDescription;
}

export const printAdhocCheque = async (printData, callback) => {
    try {
        PrintPDF.printCheque(printData, callback);
    } catch (error) {
        callback({ error: 'Failed to print cheque' });
    }
}

export const printPaymentReceipt = async (printData, callback) => {
    try {

        PrintPDF.printPaymentReceipt(printData, callback);

    } catch (error) {
        callback({ error: 'Failed to print payment receipt' });
    }
}

export const getActiveSubscription = (subscriberDetails) => {
    try {
        const subscriptions = subscriberDetails.subscriptions;
        var activeSubscription = null;
        if (!isNullOrUndefined(subscriptions) && subscriptions.length > 0) {
            activeSubscription = subscriptions.find(s => (s.validSubscription === true));
            if (isNullOrUndefined(activeSubscription)) {
                // Return the current/last active subscription
                activeSubscription = subscriptions.find(s => (s.active === true));
            }
        }
        return activeSubscription;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const validityInDays = (subscriberDetails) => {
    try {
        const subscriptions = subscriberDetails.subscriptions;
        if (!isNullOrUndefined(subscriptions) && subscriptions.length > 0) {
            var activeSubscription = subscriptions.find(s => (s.validSubscription === true));
            if (!isNullOrUndefined(activeSubscription)) {
                return activeSubscription.expiringInDays;
            } else {
                return 0;
            }
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const verifySubscription = (subscriberDetails) => {
    try {
        const subscriptions = subscriberDetails.subscriptions;
        if (!isNullOrUndefined(subscriptions) && subscriptions.length > 0) {
            var activeSubscription = subscriptions.find(s => (s.validSubscription === true));
            if (isNullOrUndefined(activeSubscription)) {
                return false;
            } else {
                return true;
            }
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const isFreeTrial = (subscriberDetails) => {
    try {
        let activeSubscription = getActiveSubscription(subscriberDetails);
        return (safeInt(activeSubscription.packageId) === 1) ? true : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const handleDeleteDiscount = async (entityName, orderTypeId, orderId, setFieldValue, setWaiting, setOrderData, setOrderSummaryData, showAlert, authContext) => {
    try {
        setWaiting(true);
        const request = { orderId: safeInt(orderId), orderTypeId: orderTypeId, discountAmount: 0, discountPercent: 0, discountType: 2 };
        const response = await getDataList(entityName, 'ProcessDiscount', request, authContext, null, 'put');
        if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
            showAlert(response.message, 'error');
        } else {
            if (!isNullOrUndefined(response)) {
                if (safeFloat(response.orderDiscountAmount) > 0) {
                    setFieldValue('orderDiscountPercent', safeFloat(response.orderDiscountPercent));
                    setFieldValue('orderDiscountAmount', safeFloat(response.orderDiscountAmount));
                } else {
                    setFieldValue('orderDiscountPercent', 0);
                    setFieldValue('orderDiscountAmount', 0);
                }
                let orderItems = getOrderItems(response[lowerCaseFirstLetter(entityName) + "Details"]);
                setOrderData(orderItems);

                if (authContext.settings["roundOff" + entityName + "total"] === true) {
                    response.grandTotalDisplay = Math.round(safeFloat(response.grandTotal));
                } else {
                    response.grandTotalDisplay = safeFloat(response.grandTotal);
                }
                let orderSummary = getOrderSummary(response);
                setOrderSummaryData(orderSummary);
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        setWaiting(false);
    }
}


export const copyOrder = async (entityName, orderId, setWaiting, authContext, showAlert, history) => {
    try {
        setWaiting(true);
        if (!verifySubscription(authContext.currentSubscriber)) {
            let upgradePath = isFreeTrial(authContext.currentSubscriber) ? "/packages" : "/renewSubscription";
            showAlert('Your subscription/trial period has expired!', 'error', 'top', 90000, upgradePath, 'Upgrade now!');
        } else {
            let orderNumberLength = 0;
            if (!isNullOrUndefined(authContext.settings[lowerCaseFirstLetter(entityName) + 'VoucherLength'])) {
                orderNumberLength = safeInt(authContext.settings[lowerCaseFirstLetter(entityName) + 'VoucherLength']);
            }
            var order = await getOrder(orderId, entityName, authContext);
            var nextNumber = await getData(entityName, 'Get' + entityName + 'NextSequence', authContext, null);
            order.orderNumber = authContext.settings[lowerCaseFirstLetter(entityName) + 'Prefix'] + String(nextNumber).padStart(orderNumberLength, "0");
            order.orderDate = getISODate();
            order.paymentDuedate = getISODate();
            order.deliveryDate = getISODate();
            order.eWayBillNo = "";
            order.invoiceId = 0;
            order.purchaseBillId = 0;
            order.statusId = 2;
            order.syncErrors = "";

            var response = await WebAPI.put('/' + entityName + '/Create' + entityName, order, { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                showAlert(entityName + ' created successfully!', 'success');
                history.push("/" + entityName + "/" + response.data.id);
            }
        }

    } catch (error) {
        console.log(error);
        showAlert('Failed to copy ' + entityName, 'error');
    } finally {
        setWaiting(false);
    }
}



export const orderStatusList = [
    {
        code: 0,
        description: 'New'
    },
    {
        code: 1,
        description: 'InProgress'
    },
    {
        code: 2,
        description: 'Completed'
    },
    {
        code: 3,
        description: 'Cancelled'
    },
    {
        code: 4,
        description: 'Approved'
    },
    {
        code: 5,
        description: 'Rejected'
    },
    {
        code: 6,
        description: 'Deleted'
    },
    {
        code: 7,
        description: 'Closed'
    },
    {
        code: 8,
        description: 'Sync Error'
    },
]

export const getOrderStatusDesc = (orderStatusId) => {
    var statusDescripton = "";
    switch (safeInt(orderStatusId)) {
        case 0:
            statusDescripton = "New";
            break;
        case 1:
            statusDescripton = "InProgress";
            break;
        case 2:
            statusDescripton = "Completed";
            break;
        case 3:
            statusDescripton = "Cancelled";
            break;
        case 4:
            statusDescripton = "Approved";
            break;
        case 5:
            statusDescripton = "Rejected";
            break;
        case 6:
            statusDescripton = "Deleted";
            break;
        case 7:
            statusDescripton = "Closed";
            break;
        case 8:
            statusDescripton = "Sync Error";
            break;
        case 9:
            statusDescripton = "Shopify Synced";
            break;
        default:
            statusDescripton = "Unkown";
            break;
    }
    return statusDescripton;
}

export const isSalesVoucher = (entityName) => {
    return (entityName === Common.INVOICE_ENTITY
        || entityName === Common.DELIVERY_CHALLAN_ENTITY
        || entityName === Common.SALES_ORDER_ENTITY
        || entityName === Common.QUOTATION_ENTITY
        || entityName === Common.CREDIT_NOTE_ENTITY)
}

export const isIntegratedGST = (party, subscriber, placeOfSupply = 0) => {
    let isIGST = false;
    try {
        if (!isNullOrUndefined(subscriber)) {
            let partyTaxIdNumber = '';
            let partyStateId = 0;
            if (!isNullOrUndefined(party)) {
                partyTaxIdNumber = safeString(party.taxIdNumber)
                if (partyTaxIdNumber.length > 2) {
                    partyStateId = safeInt(partyTaxIdNumber.substring(0, 2));
                }
            }
            let subscriberTaxIdNumber = safeString(subscriber.taxIdNumber);
            let subscriberStateId = 0;
            if (subscriberTaxIdNumber.length > 2) {
                subscriberStateId = safeInt(subscriberTaxIdNumber.substring(0, 2));
            }
            if (partyStateId > 0 && partyStateId !== subscriberStateId) {
                isIGST = true;
            }
            if (safeInt(placeOfSupply) > 0) {
                if (safeInt(placeOfSupply) !== subscriberStateId) {
                    isIGST = true;
                }
            }
        }
        return isIGST;
    } catch (error) {
        console.log(error);
        return false;
    }
}