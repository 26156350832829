import { makeStyles } from "@material-ui/core/styles";

//#region Nav Menu Styles
const drawerWidth = 210;
const navBarHeight = '45px';
const useNavStyles = makeStyles((theme) => (
  {
    statusBar: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      borderRadius: '5px',
      // marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      backgroundColor: 'rgba(23,105,170,0.1)',
      color: theme.palette.secondary.dark,
      textAlign: 'right',
      zIndex: 999
    },
    selectedListItem: {
      backgroundColor: theme.palette.secondary.dark,
      color: 'white', // Text color when selected
    },
    link: {
      cursor: 'pointer',
      fontSize: '1em',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      '&:hover': {
        textDecoration: 'underline',
        transform: 'scale(1.05)',
        // transition: '0.1s'
      },
    },
    iconSideBar: {
      backgroundColor: /* '#DBE9FA' */ '#34475e !important',
      color: /* theme.palette.primary.light */ '#fff !important',
      padding: 0,
      marginRight: 0,
      width: '24px !important',
      height: '24px !important',
      transition: 'background-color 0.3s, color 0.3s', // Transition for background-color and color
      '&:hover': {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      },
    },
    avatarSideBar: {
      backgroundColor: /* '#DBE9FA' */ '#232F3E !important',
      color: theme.palette.primary.light,
      width: '24px !important',
      height: '24px !important',
      padding: 0,
      marginRight: 0,
      transition: 'background-color 0.3s, color 0.3s', // Transition for background-color and color
      /*  '&:hover': {
         backgroundColor: 'white',
         color: theme.palette.primary.main,
       }, */
    },

    bottomNavbar: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      zIndex: 27,
      width: "100%",
      backgroundColor: theme.palette.primary.main + ' !important',
    },
    addActionMobile: {
      position: 'fixed',
      right: 0,
      bottom: 5,
      zIndex: 27,
      backgroundColor: theme.palette.secondary.main + " !important",
      color: '#fff !important',
      "& .MuiFab-root": {
        backgroundColor: theme.palette.success.main,
        color: '#fff'
      },
      [theme.breakpoints.up('xs')]: {
        margin: '9px 18px 63px 9px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '9px 18px 41px 9px',
      },
    },
    addPWAMobile: {
      position: 'fixed',
      right: 0,
      bottom: 70,
      zIndex: 27,
      backgroundColor: '#6746c3 !important',
      color: '#fff !important',
      "& .MuiFab-root": {
        backgroundColor: theme.palette.success.main,
        color: '#fff'
      },
      [theme.breakpoints.up('xs')]: {
        margin: '9px 18px 63px 9px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '9px 18px 41px 9px',
      },
    },


    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
    },
    mobileOnly: {
      [theme.breakpoints.up('sm')]: {
        display: 'none !important',
      },
    },
    root: {
      display: "flex",
      "& .MuiIconButton-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0),
      },
      "& .MuiListItemIcon-root": {
        minWidth: '30px',
      },
      '& > .fa': {
        margin: theme.spacing(2),
      },

    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      minHeight: navBarHeight,
      paddingTop: theme.spacing(1),
      backgroundColor: /* theme.palette.primary.dark */ '#232F3E !important'
    },
    navToolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '0px',
    },
    toolbarButton: {
      display: 'inline-flex',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
      maxWidth: '120px'
    },
    pt1: {
      marginTop: '3px',
    },
    m1: {
      margin: theme.spacing(1),
    },
    m2: {
      margin: theme.spacing(2),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },

    ml2: {
      marginLeft: theme.spacing(2),
    },
    addNewMargin: {
      marginLeft: theme.spacing(7),
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    listItemText: {
      fontFamily: 'roboto',
      color: theme.palette.primary.light,
      // marginLeft: theme.spacing(2),
      paddingLeft: 0,
      /*  transition: 'color 0.3s ease, transform 0.2s ease', */ // Transition for color and transform effects
      cursor: 'pointer', // Change cursor on hover

    },
    menuListContainer: {
      transition: 'transform 0.1s',
      '&:hover': {
        color: '#fff', // Change text color on hover
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add a subtle shadow effect
        backgroundColor: '#131921', // Optional: Dark background color on hover        
      },
    },


    marginForAppBar: {
      marginTop: navBarHeight,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#232F3E !important', // Dark background color
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut, // Optional: adjust easing function
      }),
    },
    menuItems: {
      backgroundColor: /* theme.palette.primary.dark */ '#34475e',
      paddingLeft: theme.spacing(1),
      transition: 'background-color 0.3s ease-in-out', // Smooth transition for background-color change
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark, // Darker shade on hover
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      marginBottom: '126px',

    },
    title: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    grow: {
      [theme.breakpoints.up('md')]: {
        flexGrow: 1,
      },
      display: 'none'
    },

    dangerColor: {
      color: theme.palette.error.main,
    },
    infoColor: {
      color: '#0DCAF0'
    },
    successColor: {
      color: theme.palette.success.main,
    },
    pullRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0px !important',
      padding: '0px !important',
    },
    pullLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: '0px !important',
      padding: '0px !important',
    },
    pullEvenly: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0px !important',
      padding: '0px !important',
    },
    successButton: {
      backgroundColor: theme.palette.success.main,
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        color: '#FFF'
      },
    },
    noSpacing: {
      padding: '0px !important',
      margin: '0px !important',
      "& .MuiIconButton-root": {
        padding: '0px !important'
      }
    },
    infoDrawerTitle: {
      marginLeft: theme.spacing(2),
      color: '#ffffff'
    },
    infoDrawerTopToolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      minWidth: '360px',
      backgroundColor: theme.palette.primary.dark
    },
    successButtonOutlined: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        color: '#FFF'
      },
    },
    warningButton: {
      backgroundColor: theme.palette.warning.main,
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
        color: '#FFF'
      },
    },
    center: {
      margin: 'auto',
      width: '50%'
    },
    mutedText: {
      color: 'azure',
      fontStyle: 'italic',
      fontSize: '0.72em',
    },
    buyNowButton: {
      alignItems: "center",
      appearance: "none",
      backgroundImage: "radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%)",
      border: 0,
      borderRadius: 6,
      boxShadow:
        "rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset",
      boxSizing: "border-box",
      color: "#fff",
      cursor: "pointer",
      display: "inline-flex",
      fontFamily: "JetBrains Mono, monospace",
      height: 42,
      justifyContent: "center",
      lineHeight: 1,
      listStyle: "none",
      overflow: "hidden",
      paddingLeft: 16,
      paddingRight: 16,
      position: "relative",
      textAlign: "left",
      textDecoration: "none",
      transition: "box-shadow .15s, transform .15s",
      userSelect: "none",
      WebkitUserSelect: "none",
      touchAction: "manipulation",
      whiteSpace: "nowrap",
      willChange: "box-shadow, transform",
      fontSize: 16,
      "&:focus": {
        boxShadow: "#3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
      },
      "&:hover": {
        boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
        transform: "translateY(-2px)",
      },
      "&:active": {
        boxShadow: "#3c4fe0 0 3px 7px inset",
        transform: "translateY(2px)",
      },
    },
  }
));

export default useNavStyles;
//#endregion  Nav Menu Styles