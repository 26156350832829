import { Typography } from "@material-ui/core";
import { Fragment } from 'react';
import * as converter from 'num-words';
import * as moment from 'moment';
import { CsvBuilder } from 'filefy';
import AppIcon from "../UI/Controls/AppIcons";
import { createFilterOptions } from '@material-ui/lab';
import { OPTIONS_LIMIT } from "./Common";

export const findDuplicatesInArray = (arr) => {
    return arr.filter((currentValue, currentIndex) =>
        arr.indexOf(currentValue) !== currentIndex);
}

export const wrapTextInBrackets = (val) => {
    if (isNullOrEmpty(val)) {
        return ""
    }
    return " (" + safeString(val).trim() + ")";
}

export const filterOptionsWithMatchFromStart = createFilterOptions({
    matchFrom: 'start',
    limit: OPTIONS_LIMIT
});

export const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
});

export const lowerCaseFirstLetter = (val) => {
    try {
        if (!isNullOrUndefined(val)) {
            return val.charAt(0).toLowerCase() + val.slice(1);
        }
    } catch (error) {
        console.log(error);
        return "";
    }
}

export const getSumOfArrayItem = (arr, prop) => {
    try {
        return arr.map(item => safeFloat(item[prop])).reduce((prev, curr) => prev + curr, 0);
    } catch (error) {
        console.log(error);
        return 0;
    }

}

export const formatToCurency = (val, currencyCode = "INR") => {

    var currencySymbol = '';
    switch (currencyCode) {
        case "INR":
            currencySymbol = String.fromCharCode(0x20b9);
            break;
        case "CAD":
        case "AUD":
        case "USD":
            currencySymbol = String.fromCharCode(36);
            break;
        case "GBP":
            currencySymbol = String.fromCharCode(163);
            break;
        default:
            currencySymbol = String.fromCharCode(0x20b9);
            break;
    }
    if (!isNullOrUndefined(val)) {
        return currencySymbol + " " + safeFloat(val).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return currencySymbol + " 0.00";
}

export const isNullOrEmpty = (val) => {
    if (typeof val == 'undefined' || val == null || val === "") {
        return true;
    }
    return false;
};
export const isNullOrUndefined = (val) => {
    if (typeof val == 'undefined' || val == null) {
        return true;
    }
    return false;
};

export const scrollToFocusedElement = (elementName) => {
    try {
        var element = document.getElementsByName(elementName);
        if (typeof element != 'undefined' && !!element && element.length > 0) {
            document.getElementsByName(elementName)[0].scrollIntoView();
        }
    } catch (error) {

    }
}

export const setInputFocus = (elementName, scroll = false) => {
    try {
        var elements = document.getElementsByName(elementName);
        if (typeof elements != 'undefined' && !!elements && elements.length > 0) {
            let element = document.getElementsByName(elementName)[0];
            element.focus();
            if (element.nodeName === 'INPUT') {
                element.select();
            }
            if (scroll) {
                element.scrollIntoView();
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const CurrentTimeStamp = () => {
    return new Date().getTime();
};

export const downloadObjectAsJSON = (exportObj, exportName) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
};


export function cellPaddingCompact() {
    return {
        paddingTop: 4,
        paddingBottom: 0,
    }
}

export function minDate() {
    return getMinDate();
}

export function safeDateRender(value) {
    if (value === null || value === undefined || value === '0001-01-01T00:00:00' || value === '0001-01-01' || value === '1900-01-01' || value === '1/1/1') {
        return "";
    } else {
        return getDatePart(value);
    }
}

export function safeDateSend(value) {
    if (isNullOrEmpty(value) || value === '0001-01-01T00:00:00' || value === '0001-01-01' || value === '1900-01-01' || value === '1/1/1') {
        return getMinDate();
    } else {
        return getDatePart(value);
    }
}

export function safeDateDisplay(value) {
    if (value === null || value === undefined || value === '0001-01-01T00:00:00' || value === '0001-01-01' || value === '1900-01-01' || value === '1/1/1') {
        return "";
    } else {
        return value;
    }
}

export function safeDate(value) {
    if (isNullOrEmpty(value)) {
        return getMinDate();
    }
    let dateIn = new Date(value);
    return dateIn.toDateString().slice(0, 10);
}

export function shortenString(value, maxlen = 40) {
    if (value === null || value === undefined) {
        return "";
    }
    if (value.toString().trim().length > maxlen) {
        return value.toString().trim().substr(0, maxlen - 2) + '...';
    } else {
        return value.toString();
    }

}

export function shortenStringJSX(value, maxlen = 40, copyable = false, showAlert, message = "Text ") {
    if (value === null || value === undefined) {
        return <></>;
    }
    if (value.toString().trim().length > maxlen) {
        if (copyable === true) {
            return <span title="Click to copy." ><span title={value.toString().trim()}>{value.toString().trim().substr(0, maxlen - 2) + '...'}</span><AppIcon style={{ fontSize: '14px', color: 'green', cursor: 'pointer' }} name="Copy" size="small"
                onClick={() => {
                    navigator.clipboard.writeText(value);
                    if (!isNullOrUndefined(showAlert)) {
                        showAlert(message + ' copied!');
                    } else {
                        alert(message + ' copied!');
                    }
                }} />
            </span>;
        } else {
            return <span title={value.toString().trim()}>{value.toString().trim().substr(0, maxlen - 2) + '...'}</span>;
        }
    } else {
        return <>{value.toString()}</>;
    }
}

export function safeString(value) {
    if (value === null || value === undefined) {
        return "";
    }
    return value.toString();
}

export function safeAmount(value, decimalPlaces = -1) {
    if (isNaN(parseFloat(value))) {
        return 0;
    }
    if (decimalPlaces >= 0) {
        return parseFloat(parseFloat(value).toFixed(decimalPlaces));
    } else {
        return parseFloat(value);
    }
}

export function safeFloat(value, defaultValue = 0, decimalPlaces = 2) {
    if (isNaN(parseFloat(value))) {
        return defaultValue;
    }
    return parseFloat(parseFloat(value).toFixed(decimalPlaces));
}

export function safeInt(value, defaultValue = 0) {
    if (isNaN(parseInt(value))) {
        return defaultValue;
    }
    return parseInt(value);
}

export function safeBool(value, defaultValue = false) {
    try {
        if (isNullOrUndefined(value)) {
            return defaultValue;
        }
        return value;
    } catch (error) {
        return defaultValue;
    }
}

export function getConfirmOptions(title, description, okText = 'Yes', cancelText = 'No', content = null,) {
    return {
        title: title,
        description: description,
        confirmationText: okText,
        cancellationText: cancelText,
        confirmationButtonProps: { autoFocus: true, variant: 'contained' },
        cancellationButtonProps: { autoFocus: false, variant: 'outlined' },
        content: content
    }
}

export function getAlertOptions(title, description, okText = 'Ok', cancelText = 'Cancel', content = null) {
    return {
        title: title,
        description: description,
        confirmationText: okText,
        cancellationText: cancelText,
        confirmationButtonProps: { autoFocus: true, variant: 'contained' },
        cancellationButtonProps: { autoFocus: false, variant: 'outlined' },
        content: content
    }
}

export function getEmptyActionDefinition(count = 1, includeDiscount = false, handleDeleteDiscount) {
    var definition = [{
        icon: () => <Fragment />,
        tooltip: '',
        onClick: () => { }
    }];
    if (count > 1) {
        for (let index = 0; index < count - 1; index++) {
            if (index === 0 && includeDiscount === true) {
                definition.push(rowData => ({
                    icon: () => rowData.id === 993 ? <AppIcon color="error" name="Delete" onClick={handleDeleteDiscount} /> : <Fragment></Fragment>,
                    tooltip: 'Delete discount'
                }));
            } else {
                definition.push({
                    icon: () => <Fragment />,
                    tooltip: '',
                    onClick: () => { }
                });
            }
        }
    }
    return definition;
}

// export function isInterStateTax(party, subscriber) {
//     if (!isNullOrUndefined(party) && safeString(party.taxIdNumber).length > 2) {
//         if (safeString(party.taxIdNumber).substring(0, 2) !== subscriber.taxIdNumber.substring(0, 2)) {
//             return true;
//         }
//     }    
//     if (!isNullOrUndefined(party) && !isNullOrUndefined(party.addressList)) {        
//         const partyBillingAddress = party.addressList.find(a => a.addressType === 'Billing');
//         const subscriberBillingAddress = subscriber.addressList.find(a => a.addressType === 'Billing');
//         if (safeInt(partyBillingAddress.stateId) !== safeInt(subscriberBillingAddress.stateId)) {
//             return true;
//         }
//     }    
//     return false;
// }

// export function isInterStateTax(taxIdNumber, subscriberTaxIdNumber) {
//     if (!!taxIdNumber && taxIdNumber.length > 2) {
//         if (taxIdNumber.substring(0, 2) !== subscriberTaxIdNumber.substring(0, 2)) {
//             return true;
//         }
//     }
//     return false;
// }

export function getAmountIncludingTax(amount, taxRate) {
    let factor = (amount < 0) ? -1 : 1;
    let returnAmount = 0;
    if (safeAmount(taxRate) === 0) {
        returnAmount = safeAmount(Math.abs(amount));
    } else {
        var amountIncludingTax = safeAmount(amount) * (1 + (safeAmount(taxRate) / 100));
        returnAmount = safeAmount(Math.abs(amountIncludingTax));
    }
    return returnAmount * factor;
}

export function getAmountExcludingTax(amount, taxRate) {
    let factor = (amount < 0) ? -1 : 1;
    var amountExcludingTax = safeAmount(amount) / (1 + (safeAmount(taxRate) / 100));
    let returnAmount = safeAmount(Math.abs(amountExcludingTax));
    return returnAmount * factor;
}

export function getCurrencySymbol(currencyCodeISO) {
    switch (currencyCodeISO) {
        case "INR":
            return <Typography color="textSecondary">&#8377;</Typography>;
        case "AUD":
        case "CAD":
        case "USD":
            return <Typography color="textSecondary">&#36;</Typography>;
        case "GBP":
            return <Typography color="textSecondary">&#163;</Typography>;
        default:
            return <Typography color="textSecondary">&#8377;</Typography>;
    }
}

export function formatCurrencyAmount(currencyCodeISO, amount, textColor = 'black', isBold = false, labelText = "", decimalPlaces = 2, variant = 'subtitle2') {
    var boldText = isBold ? 'bold' : 'normal';
    switch (currencyCodeISO) {
        case "INR":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#8377;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
        case "USD":
        case "CAD":
        case "AUD":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#x24;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
        case "GBP":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#163;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
        default:
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#8377;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
    }
}

export function formatCurrencyAmountEndLable(currencyCodeISO, amount, textColor = 'black', isBold = false, labelText = "", decimalPlaces = 2, variant = 'subtitle2') {
    var boldText = isBold ? 'bold' : 'normal';
    switch (currencyCodeISO) {
        case "INR":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>&#8377;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}&nbsp; {labelText}&nbsp;</Typography>;
        case "USD":
        case "CAD":
        case "AUD":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#x24;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
        case "GBP":
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#163;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
        default:
            return <Typography variant={variant} style={{ color: textColor, fontWeight: boldText }}>{labelText}&#8377;&nbsp;{safeAmount(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces })}</Typography>;
    }
}

export function getDatePart(value, defaultValue = '0001-01-01') {
    if (!!value && value.length >= 10) {
        return value.slice(0, 10);
    }
    return defaultValue;
}

export function getMinDate() {
    return "0001-01-01";
}

export function getISODate() {
    return new Date().toISOString().slice(0, 10);
}

export function getFinancialYear() {
    var date = new Date()
    var fy = {
        startDate: new Date(date.getFullYear(), 4, 1),
        endDate: new Date(date.getFullYear() + 1, 3, 31)
    }
    if (date.getMonth() <= 3) {
        fy.startDate = new Date(date.getFullYear() - 1, 4, 1).toISOString().slice(0, 10);
        fy.endDate = new Date(date.getFullYear(), 3, 31).toISOString().slice(0, 10);
    }
    return fy;
}
export function getFinancialStartDate() {
    var date = new Date()
    var startDate = moment().format('YYYY-04-01');
    if (date.getMonth() <= 2) { // Month is zero index in JS so 2 = March
        startDate = moment().format((date.getFullYear() - 1) + '-04-01');
    }
    return startDate;
}

export function getFinancialEndDate() {
    var date = new Date()
    var endDate = moment().format((date.getFullYear() + 1) + '-03-31');
    if (date.getMonth() <= 2) { // Month is zero index in JS so 2 = March
        endDate = moment().format('YYYY-03-31');
    }
    return endDate;
}

export function getStartOfMonth() {
    return moment().format('YYYY-MM-01');
}

export function getFilterDate(addDays = -15) {
    return moment().add(addDays, 'days').format('YYYY-MM-01');
}

export function convertToISODate(value) {
    if (!!value && value !== "") {
        var darr = value.split("/");    // ["29", "1", "2016"]
        //if(darr.length === 1) {
        //    darr = value.split("-");    // ["29", "1", "2016"]
        //}
        var dobj = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0]));
        // Date {Fri Jan 29 2016 00:00:00 GMT+0530(utopia standard time)
        return dobj.toISOString();   //2016-01-28T18:30:00.000Z    
    }
    return getMinDate();
}

export function getISODateTime(value) {
    if (!!value) {
        return new Date(value).toISOString();
    }
    return getMinDate();

}

export function getLocaleDateTime(date, locale = 'en-IN') {
    try {
        if (!isNullOrUndefined(date)) {
            if (date !== "" && date.length >= 10) {
                let dateIn = new Date(date);
                if (dateIn.getFullYear() === 1900 || (dateIn.getFullYear() === 1)) {
                    return "";
                } else {
                    return moment(dateIn).format('DD-MMM-YYYY hh:mm A');
                }
            }
        }
    } catch (error) {
        console.log(error);
        return "";
    }
    return "";
}

export function getLocaleDate2(date, locale = 'en-IN') {
    try {
        if (!isNullOrUndefined(date)) {
            if (date !== "" && date.length >= 10) {
                let dateIn = new Date(date.slice(0, 10));
                if (dateIn.getFullYear() === 1900 || (dateIn.getFullYear() === 1)) {
                    return "";
                } else {
                    return moment(dateIn).format('DD-MM-YYYY');
                }
            }
        }
    } catch (error) {
        console.log(error);
        return "";
    }
    return "";
}

export function getBatchDateDisplay(date, locale = 'en-IN') {
    try {
        if (!isNullOrUndefined(date)) {
            if (date !== "" && date.length >= 10) {
                let dateIn = new Date(date.slice(0, 10));
                if (dateIn.getFullYear() === 1899 || dateIn.getFullYear() === 1900 || (dateIn.getFullYear() === 1)) {
                    return "";
                } else {
                    return dateIn.toLocaleDateString(locale, { month: '2-digit', year: 'numeric' });
                }
            }
        }
    } catch (error) {
        console.log(error);
        return "";
    }
    return "";
}

export function getLocaleDate(date, locale = 'en-IN') {
    try {
        if (!isNullOrUndefined(date)) {
            if (date !== "" && date.length >= 10) {
                let dateIn = new Date(date.slice(0, 10));
                if (dateIn.getFullYear() === 1899 || dateIn.getFullYear() === 1900 || (dateIn.getFullYear() === 1)) {
                    return "";
                } else {
                    return dateIn.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
                }
            }
        }
    } catch (error) {
        console.log(error);
        return "";
    }
    return "";
}

export function getAdditioanlFieldValue(additionalDetails, fieldName) {
    var fieldVal = '';
    if (!isEmptyObjectArray(additionalDetails)) {
        const fieldData = additionalDetails.find(a => safeString(a.fieldName).toLowerCase() === safeString(fieldName).toLowerCase());
        if (!isNullOrUndefined(fieldData)) {
            fieldVal = safeString(fieldData.fieldValue);
        }
    }
    return fieldVal;
}
export function getAmountInWords(value, dontPrefixINR = false) {
    if (!!value && safeFloat(value) > 0) {
        var decimal = value - Math.floor(value)
        var amountInWords = ((dontPrefixINR === true) ? '' : 'INR ');
        amountInWords += capitalizeFirstLetter(converter(safeInt(Math.floor(value))));
        if (safeFloat(decimal) > 0.00) {
            amountInWords = amountInWords + ' and ' + converter(Math.round(decimal * 100)) + ' paise';
        }
        return amountInWords + ' only';
    }
    return "Invalid amount";
}

export function capitalizeFirstLetter(value) {
    if (!isNullOrUndefined(value) && value.length > 0) {
        return value[0].toUpperCase() + value.slice(1);
    }
    return "";
}


export function getCurrentDateTime(locale = 'en-IN') {
    const d = new Date();
    return d.toLocaleDateString(locale) + ' ' + d.toLocaleTimeString();
}

export function getArrayLength(arr) {
    if (!isNullOrUndefined(arr) && arr.length > 0) {
        return arr.length;
    }
    return 0;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export function isEmptyObjectArray(objArr) {
    try {
        if (isNullOrUndefined(objArr) || objArr.length === 0) {
            return true;
        } else {
            return Object.keys(objArr[0]).length === 0;
        }
    } catch (error) {
        console.log(error);
    }
}

export function isEmptyObject(obj) {
    if (isNullOrUndefined(obj)) {
        return true;
    }
    return Object.keys(obj).length === 0;
}

export function scrollToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function openExternalLink(url) {
    window.open(url, '_blank');
}

export function preventFormSubmit(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        if (keyEvent.target.type !== 'textarea') {
            keyEvent.preventDefault();
        }
    }
}

export function getTodaysDate() {
    return moment(new Date()).format('YYYY-MM-DD')
}


export function exportToCsv(columns, data) {
    const columnTitles = columns
        .map(columnDef => columnDef.title);

    const csvData = data.map(rowData =>
        columns.map(columnDef => rowData[columnDef.field]),
    );
    const fileName = 'data_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.csv';
    const builder = new CsvBuilder(fileName)
        .setColumns(columnTitles)
        .addRows(csvData)
        .exportFile();
    return builder;
}

export function ifNullOrEmpty(expression, value) {
    if (isNullOrUndefined(expression) || safeString(expression) === "") {
        return value;
    } else {
        return expression;
    }
}

export function ifNull(expression, value) {
    if (isNullOrUndefined(expression)) {
        return value;
    } else {
        return expression;
    }
}

export function getFileSuffix() {
    return moment(new Date()).format('YYYYMMDDHHmmss');
}

export function getTimestamp() {
    const d = new Date();
    return d.valueOf();
}

export function getStateIdByName(stateList, stateName) {
    let stateId = 0;
    if (!isNullOrUndefined(stateList)) {
        const state = stateList.find(s => s.description.toLowerCase() == stateName.toLowerCase());
        if (!isNullOrUndefined(state)) {
            stateId = safeInt(state.code);
        }
    }
    return stateId;
}

export function getStateName(stateId, stateList) {
    if (!isNullOrUndefined(stateId) && stateId != "") {
        if (safeInt(stateId) > 0) {
            var stateName = stateList.find(s => safeInt(s.code) == safeInt(stateId)).description;
            if (!!stateName) {
                return stateName;
            }
        } else {
            return "";
        }
    }
    return stateId;
}
export function getUId() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
}

export function isDateBetween(dateToCheck, startDate, endDate) {
    // Parse the dates and set the time to 00:00:00 to ignore the time part
    const check = new Date(dateToCheck).setHours(0, 0, 0, 0);
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(0, 0, 0, 0);

    // Compare the timestamps
    return check >= start && check <= end;
}

export function getFiscalYearId(startDate, endDate, fiscalYears) {
    const fiscalYear = fiscalYears.find(year => {
        return startDate >= year.startDate && endDate <= year.endDate;
    });

    return fiscalYear ? fiscalYear.id : null;
}

export const getFiscalYearDatesById = (fiscalYearId, fiscalYears) => {
    const fiscalYear = fiscalYears.find(year => year.id === safeInt(fiscalYearId));
    return fiscalYear ? {
        startDate: fiscalYear.startDate, endDate: fiscalYear.endDate,
        description: fiscalYear.description
    } : null;
};