import { ifNullOrEmpty, safeBool, safeInt, safeString } from "../../../../Base/Utils";

export function getPDFStyles(printFormatCustomSettings = null) {
    let styles = {};
    styles = {
        invisible: {
            color: 'white',
            fontSize: 1
        },
        borderColor: {
            color: safeString(printFormatCustomSettings.borderColor, "black")
        },
        fontRegular: {
            fontSize: safeInt(printFormatCustomSettings?.fontRegular, 12)
        },
        borderThickness: {
            style: safeInt(printFormatCustomSettings.borderThickness, 1)
        },
        fontBold: {
            fontSize: 12
        },
        fontMainHeading1: {
            fontSize: safeInt(printFormatCustomSettings?.fontMainHeading1, 18),
            color: ifNullOrEmpty(printFormatCustomSettings?.fontMainHeading1Color, 'black')
        },
        fontMainHeading2: {
            fontSize: safeInt(printFormatCustomSettings?.fontMainHeading2, 16),
            color: ifNullOrEmpty(printFormatCustomSettings?.fontMainHeading2Color, 'black')
        },
        fontSubHeading1: {
            fontSize: safeInt(printFormatCustomSettings?.fontSubHeading1, 14),
            color: ifNullOrEmpty(printFormatCustomSettings?.fontSubHeading1Color, 'black')
        },
        fontSubHeading2: {
            fontSize: safeInt(printFormatCustomSettings?.fontSubHeading2, 12),
            color: ifNullOrEmpty(printFormatCustomSettings?.fontSubHeading2Color, 'black'),
            bold: safeBool(printFormatCustomSettings?.fontSubHeading2Weight, false)
        },
        nestedTable: {
            margin: [0, 0, 0, 0],
            fontSize: 12,
        },
        fontHyperLink: {
            fontSize: safeInt(printFormatCustomSettings?.fontHyperLink, 12),
            color: ifNullOrEmpty(printFormatCustomSettings?.fontHyperLinkColor, 'black'),
            decoration: 'underline'
        },
        backgroundFillColor: {
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0],
            fillColor: ifNullOrEmpty(printFormatCustomSettings?.fillColor, 'lightsteelblue')
        },
        noBorder: {
            margin: [0, 0, 0, 8]
        },
        tableItemsCompact: {
            margin: [0, 0, 0, 0],
            fontSize: 7,
            lineHeight: 0.8
        },
        tableItemsSmall: {
            margin: [0, 0, 0, 0],
            fontSize: 8,
            lineHeight: 0.8
        },
        tableItems: {
            margin: [0, 0, 0, 0],
            padding: [0, 0, 0, 0]
            // fontSize: 10,
            // lineHeight: 0.8
        },
        tableItemsNormal: {
            margin: [0, 0, 0, 0],
            fontSize: 10,
            lineHeight: 1
        },
        tableItemsLarge: {
            margin: [0, 0, 0, 0],
            fontSize: 14,
            lineHeight: 1
        },
        tableItemsComfort: {
            margin: [0, 0, 0, 0],
            fontSize: 11,
            lineHeight: 1
        },
        footerTable: {
            margin: [20, 0, 20, 0],
        },
    }

    return styles;
}

export function getPDFPageMargin(headerMargin, footerMargin) {
    return [20, headerMargin, 20, footerMargin];
}
