import { ERROR_INTERNAL_SERVER } from "../Base/Common";
import { getFinancialEndDate, getFinancialStartDate, getFiscalYearDatesById, ifNullOrEmpty, safeInt, safeString } from "../Base/Utils";
import WebAPI from "../Base/WebAPI";


export const getSubscriberRequest = (subscriberId, addressId, additionalDetails, values) => {
    return {
        "id": safeInt(subscriberId),
        "shortName": values.shortName,
        "taxRegistrationTypeId": safeInt(values.taxRegistrationTypeId),
        "businessTypeId": safeInt(values.businessTypeId),
        "legalName": values.legalName,
        "taxIdNumber": safeString(values.taxIdNumber).toUpperCase(),
        "fiscalStartDate": values.fiscalStartDate,
        "fiscalEndDate": values.fiscalEndDate,
        "logo": "",
        "currencyCode": safeString(values.currencyCode),
        "additionalDetails": JSON.stringify(additionalDetails),
        "addressList": [
            {
                "id": safeInt(addressId),
                "description": "",
                "addressLine1": values.addressLine1,
                "addressLine2": values.addressLine2,
                "addressType": "Billing",
                "isPrimary": true,
                "stateId": safeInt(values.stateId),
                "city": values.city,
                "pinCode": values.pinCode,
                "contactPerson": "",
                "contactEmail": values.contactEmail,
                "contactPhone": values.contactPhone,
                "website": values.website,
                "active": true
            }
        ]
    }
}

export const getAdditionalDetails = (values) => {
    var additionalDetails = [];
    additionalDetails.push({
        fieldName: 'drugLicense20B',
        fieldValue: values.drugLicense20B
    });
    additionalDetails.push({
        fieldName: 'drugLicense21B',
        fieldValue: values.drugLicense21B
    });
    additionalDetails.push({
        fieldName: 'fssaiLicense',
        fieldValue: values.fssaiLicense
    });
    additionalDetails.push({
        fieldName: 'msmeCertificateNumber',
        fieldValue: values.msmeCertificateNumber
    });
    additionalDetails.push({
        fieldName: 'importExportCode',
        fieldValue: values.importExportCode
    });
    additionalDetails.push({
        fieldName: 'tanNumber',
        fieldValue: values.tanNumber
    });
    additionalDetails.push({
        fieldName: 'CertificateOne',
        fieldValue: values.CertificateOne
    });
    additionalDetails.push({
        fieldName: 'CertificateTwo',
        fieldValue: values.CertificateTwo
    })

    return additionalDetails;
}



export const saveSubscriber = async (subscriberId, addressId, values, authContext, setWaiting, showAlert, fiscalYears) => {
    try {
        setWaiting(true);
        var apiUrl = '/Subscriber/UpdateSubscriber';
        if (subscriberId === 0) {
            apiUrl = '/Subscriber/CreateSubscriber';
        }
        var additionalDetails = getAdditionalDetails(values);
        const request = getSubscriberRequest(subscriberId, addressId, additionalDetails, values);
        var fiscalYearDates = {
            startDate: getFinancialStartDate(),
            endDate: getFinancialEndDate()
        }
        if(safeInt(values?.FinancialYear) > 0) {
            fiscalYearDates = getFiscalYearDatesById(values?.FinancialYear, fiscalYears);
        }                            
        request.fiscalEndDate = fiscalYearDates.endDate;
        request.fiscalStartDate = fiscalYearDates.startDate;        

        const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_INTERNAL_SERVER, 'error');
        return null;
    }
    finally {
        setWaiting(false);
    }
}