import React from 'react';
import { invoiceFormats, quotationFormats, purchaseOrderFormats, creditNotesFormats, debitNotesFormats, deliveryChallanFormats, purchaseBillFormats } from '../../../Base/Common';
import Carousel from 'react-material-ui-carousel';
import { Box, Button, Grid, IconButton, MobileStepper, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import useStyles from '../../../../styles';
import AppIcon from '../../../UI/Controls/AppIcons';
import { useState } from 'react';
import shallow from 'zustand/shallow';
import useStore from '../../../Base/Store';
import { safeInt } from '../../../Base/Utils';
import { isEmptyArray } from 'formik';
import { useEffect } from 'react';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const PdfPreview = ({ selectedFormat, entityType }) => {
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [formatLength, setFormateLength] = useState(0);
    const [selectedQuotationPdfFormat, setSelectedQuotationPdfFormat] = useState([]);
    useEffect(() => {
        switch (entityType) {
            case "Invoice":
                setSelectedQuotationPdfFormat(invoiceFormats);
                setFormateLength(invoiceFormats.length);
                break;
            case "Quotation":
                setSelectedQuotationPdfFormat(quotationFormats);
                setFormateLength(quotationFormats.length)
                break;
            case "PurchaseOrder":
                setSelectedQuotationPdfFormat(purchaseOrderFormats);
                setFormateLength(purchaseOrderFormats.length)
                break;
            case "PurchaseBill":
                setSelectedQuotationPdfFormat(purchaseBillFormats);
                setFormateLength(purchaseBillFormats.length)
                break;
            case "CreditNote":
                setSelectedQuotationPdfFormat(creditNotesFormats);
                setFormateLength(creditNotesFormats.length)
                break;
            case "DebitNote":
                setSelectedQuotationPdfFormat(debitNotesFormats);
                setFormateLength(debitNotesFormats.length);
                break;
            case "DeliveryChallan":
                setSelectedQuotationPdfFormat(deliveryChallanFormats);
                setFormateLength(deliveryChallanFormats.length);
                break;
            default:
                break;
        }
    }, [])
    const classes = useStyles();
    const maxSteps = formatLength;
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleSelectPdfFormat = (val) => {
        setDialogResponse(val);
        closeDialog();
    }

    return (
        <div>
            <Box sx={{ maxWidth: 550, flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            style={{ background: '#ffff' }}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    <KeyboardArrowLeft />
                                </Button>
                            }
                        />
                        <AutoPlaySwipeableViews
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            autoplay={false}
                        >
                            {!isEmptyArray(selectedQuotationPdfFormat) && selectedQuotationPdfFormat.map((step, index) => (
                                <div key={step.label} style={{ overflow: 'hidden' }}>
                                    <div className={classes.p1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f8ff' }}>
                                        <div>
                                            <Typography color='primary' variant='h6'>{step.name} | </Typography>
                                        </div>
                                        {
                                            (safeInt(selectedFormat) == safeInt(step.value))
                                                ?
                                                <IconButton caption="Select" aria-label="Select Format" color="primary" onClick={() => handleSelectPdfFormat(step)}><AppIcon color="primary" name="SelectAddress" /></IconButton>

                                                : <>
                                                    <IconButton caption="Select" aria-label="Select Format" onClick={() => handleSelectPdfFormat(step)}><AppIcon name="RadioUnchecked" /></IconButton> <span style={{ fontSize: '14px', marginLeft: '-8px' }}>Select format</span>
                                                </>
                                        }
                                    </div>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            component="img"
                                            className={`zoom-effect ${activeStep === index ? 'active' : ''}`}
                                            sx={{
                                                display: 'block',
                                                maxHeight: '100%',
                                                overflow: 'hidden',
                                                width: '100%',
                                                margin: '0px auto',
                                                border: '1px solid lightgray'
                                            }}
                                            src={step.image}
                                            alt={step.name}
                                        />
                                    ) : <></>}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default PdfPreview;
