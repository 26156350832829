import * as Utility from '../../Base/Utils';
import * as Common from '../../Base/Common';
import { getAdditionalDetails } from '../../Shared/OrderHelper';
import { isEmptyArray } from 'formik';
export function getStyles() {
    return {
        noMarginsAlignRight: {
            alignment: 'right',
        },
        headerBig: {
            fontSize: 18,
            bold: true
        },
        headerCompact: {
            fontSize: 14,
            bold: true
        },
        header: {
            fontSize: 18,
            bold: true
        },
        normalSmall: {
            fontSize: 9,
            italics: false
        },
        normal: {
            fontSize: 10,
            italics: false
        },
        subheader: {
            fontSize: 15,
            bold: true
        },
        quoteSmall: {
            fontSize: 8,
            italics: true
        },
        quote: {
            fontSize: 10,
            italics: true
        },
        small: {
            fontSize: 8
        },
        noMargins: {
            margin: [0, 0, 0, 0],
        },
        headerTable: {
            margin: [20, 0, 20, 0],
        },
        headerAddressTable: {
            margin: [0, 0, 0, 0],
        },
        headerTableWithTopMargin: {
            margin: [20, 5, 20, 0],
        },
        headerTableServiceInvoice: {
            margin: [20, 30, 20, 0],
        },
        footerTable: {
            margin: [20, 0, 20, 0],
            fontSize: 10,
        },
        footerTableCompact: {
            margin: [20, 0, 20, 0],
            fontSize: 8,
        },
        footerTableTerms: {
            margin: [0, 0, 0, 0],
            fontSize: 8
        },
        nestedTable: {
            margin: [0, 0, 0, 0],
            fontSize: 10,
        },
        nestedTableCompact: {
            margin: [0, 0, 0, 0],
            fontSize: 9,
        },
        nestedTableFontSize8: {
            margin: [0, 0, 0, 0],
            fontSize: 8,
        },
        tableItemsBankAndTerms: {
            margin: [0, 0, 0, 0],
            fontSize: 8,
        },
        tableItemsCompact: {
            margin: [0, 0, 0, 0],
            fontSize: 7,
            lineHeight: 0.8
        },
        tableItemsSmall: {
            margin: [0, 0, 0, 0],
            fontSize: 8,
            lineHeight: 0.8
        },
        tableItems: {
            margin: [0, 0, 0, 0],
            fontSize: 10,
            lineHeight: 0.8
        },
        tableItemsNormal: {
            margin: [0, 0, 0, 0],
            fontSize: 10,
            lineHeight: 1
        },
        tableItemsLarge: {
            margin: [0, 0, 0, 0],
            fontSize: 14,
            lineHeight: 1
        },
        tableItemsComfort: {
            margin: [0, 0, 0, 0],
            fontSize: 11,
            lineHeight: 1
        },
        tableList: {
            margin: [5, 5, 5, 5],
            fontSize: 11,
        },
        bankTable: {
            margin: [0, 0, 0, 0],
            fontSize: 8,
        },
        elegantHeader: {
            margin: [25, 27, 25, 0],
        },
        elegantMainHeading: {
            margin: [25, 0, 0, 0],
        }

    }
}

function getPartyAddress(settings, voucherData, party, partyAddress, stateList, entityType) {
    const voucherFormat = settings[Utility.lowerCaseFirstLetter(entityType) + 'Format'];

    const partyName = getPartyName(party, entityType);
    const drugLicense = getDrugLicenseValue(party);
    const fssaiLicense = getFssaiLicense(party);
    let aadhaarNumber = "";
    if (!Utility.isNullOrEmpty(party.additionalDetails)) {
        aadhaarNumber = Utility.getAdditioanlFieldValue(JSON.parse(party.additionalDetails), 'aadhaarNumber')
    }

    var address = [];
    address.push([{ text: [getPartyType(entityType) + ': ', { text: partyName, bold: true, fontSize: getFontSize(partyName) }] }]);
    if (drugLicense !== '') {
        address.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
    }
    if (!Utility.isNullOrEmpty(fssaiLicense)) {
        address.push([{ text: ['FSSAI License.: ', { text: fssaiLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
    }
    if (!Utility.isNullOrEmpty(aadhaarNumber)) {
        address.push([{ text: ['Aadhar Number.: ', { text: aadhaarNumber, bold: true }], fontSize: 9, alignment: 'left' }]);
    }

    switch (voucherFormat) {
        case Common.INVOICE_FORMAT_MODERN:
        case Common.INVOICE_FORMAT_MODERN_LOGO:
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
        case Common.INVOICE_FORMAT_RETAIL:
        case Common.INVOICE_FORMAT_COMFORT:
        case Common.INVOICE_FORMAT_COMFORT_LOGO:
        case Common.INVOICE_FORMAT_ELEGANT:
        case Common.INVOICE_FORMAT_ELEGANT_LOGO:
            address.push([{ text: Utility.safeString(partyAddress.addressLine1) + ' ' + Utility.safeString(partyAddress.addressLine2), bold: false, fontSize: 9 }]);
            address.push([{ text: Utility.safeString(partyAddress.city) + ' ' + getStateName(Utility.safeString(partyAddress.stateId), stateList) + ' ' + Utility.safeString(partyAddress.pinCode), bold: false, fontSize: 9 }]);
            break;
        default:
            address.push([{ text: Utility.safeString(partyAddress.addressLine1) + " " + Utility.safeString(partyAddress.addressLine2) + " " + Utility.safeString(partyAddress.city) + ' ' + getStateName(Utility.safeString(partyAddress.stateId), stateList) + ' ' + Utility.safeString(partyAddress.pinCode), bold: false, fontSize: 9 }]);
            break;
    }

    if (!Utility.isNullOrEmpty(partyAddress.contactPerson)) {
        address.push([{ text: ['Contact Name: ', { text: Utility.safeString(partyAddress.contactPerson) + ' PH: ' + Utility.safeString(partyAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
    } else {
        address.push([{ text: ['PH: ', { text: Utility.safeString(partyAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
    }
    address.push([{ text: [' Email: ' + Utility.safeString(partyAddress.contactEmail).substr(0, 63)], fontSize: 9 }]);
    address.push([{ text: 'State code/Place of supply: ' + Utility.safeString(Utility.ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId)) + '-' + getStateName(Utility.ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId), stateList), bold: true, fontSize: 9 }]);
    if (!Utility.isNullOrEmpty(party.taxIdNumber)) {
        address.push([{ text: "GSTIN: " + Utility.safeString(party.taxIdNumber), bold: true, fontSize: 9 }]);
    }

    return address;
}

function getShippingAddress(deliveryAddress, stateList) {
    var address = [];
    if (Utility.isNullOrUndefined(deliveryAddress)) {
        address.push([{ text: ['', { text: '', bold: true }] }]);
    } else {
        address.push([{ text: ['Ship To: ', { text: Utility.safeString(deliveryAddress.description), bold: true }], fontSize: 9 }]);
        address.push([{ text: Utility.safeString(deliveryAddress.addressLine1) + ' ' + Utility.safeString(deliveryAddress.addressLine2) + ' ' + Utility.safeString(deliveryAddress.city) + ' ' + getStateName(Utility.safeString(deliveryAddress.stateId), stateList) + ' ' + Utility.safeString(deliveryAddress.pinCode), bold: false, fontSize: 9 }]);
        if (!Utility.isNullOrEmpty(deliveryAddress.contactPerson)) {
            address.push([{ text: ['Contact Name: ', { text: Utility.safeString(deliveryAddress.contactPerson), bold: true, fontSize: 9 }], fontSize: 9 }]);
        }
        address.push([{ text: ['PH: ', { text: Utility.safeString(deliveryAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
        address.push([{ text: [' Email: ' + Utility.safeString(deliveryAddress.contactEmail).substr(0, 63)], fontSize: 9 }]);
        address.push([{ text: 'State code/Place of supply: ' + Utility.safeString(deliveryAddress.stateId) + '-' + getStateName(Utility.safeString(deliveryAddress.stateId), stateList), bold: true, fontSize: 9 }]);
    }
    return address;
}

export function getLogo(subscriber, settings, scale = 81, alignment = '') {
    const subscriberLogo = Utility.safeString(subscriber.logo);
    if (Utility.isNullOrUndefined(subscriberLogo) || Utility.safeString(subscriberLogo) === "") {
        return (
            [
                { text: '' }
            ]
        )
    } else {
        if (settings.quotationFormat === "21" || settings.invoiceFormat === "21") {
            return (
                [
                    {
                        image: subscriberLogo,
                        // height: 81,
                        width: 207,
                        margin: [0, 0, 5, 0],
                        alignment: 'center'
                    }
                ]
            );
        } else if (settings.quotationFormat === "16" || settings.invoiceFormat === "16") {
            return (
                [
                    {
                        image: subscriberLogo,
                        fit: [scale, scale],
                        margin: [0, 13, 0, 0],
                        alignment: alignment
                    }
                ]
            );
        } else {
            return (
                [
                    {
                        image: subscriberLogo,
                        fit: [scale, scale],
                        margin: [0, 0, 0, 0],
                        alignment: alignment
                    }
                ]
            );
        }

    }
}

export function getQRCode(bankDetails, settings, scale = 81, align = '') {
    if (!Utility.isNullOrUndefined(bankDetails)) {
        var qrCode = Utility.ifNullOrEmpty(bankDetails.qrCode, "");
        if (qrCode === "") {
            return (
                [
                    { text: '' }
                ]
            )
        } else {
            return (
                [
                    {
                        image: Utility.safeString(bankDetails.qrCode),
                        fit: [scale, scale],
                        margin: [0, 2, 2, 0],
                        alignment: align
                    }
                ]
            );
        }
    } else {
        return (
            [
                { text: '' }
            ]
        )
    }

}

function getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList) {
    const subscriberDetails = [];
    const drugLicense = getDrugLicenseValue(subscriber);

    switch (voucherFormat) {
        case Common.INVOICE_FORMAT_CUSTOM_1:
            subscriberDetails.push([{ text: (entityType === Common.PURCHASE_ORDER_ENTITY ? "Invoice to: " : "") + subscriber.legalName, bold: true, fontSize: 14, color: 'red' }]);
            subscriberDetails.push([{ text: subscriberAddress?.addressLine1, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress?.addressLine2, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress?.city + ', ' + getStateName(subscriberAddress?.stateId, stateList) + ' ' + subscriberAddress?.pinCode, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Email: ' + subscriberAddress.contactEmail, alignment: 'left', bold: false, style: 'normalSmall' }]);
            if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
                subscriberDetails.push([{ text: 'GSTIN: ' + subscriber.taxIdNumber, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;
        case Common.INVOICE_FORMAT_MODERN_LOGO:
        case Common.INVOICE_FORMAT_MODERN:
            subscriberDetails.push([{ text: (entityType === Common.PURCHASE_ORDER_ENTITY ? "Invoice to: " : "") + subscriber.legalName, bold: true, fontSize: 14 }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine2, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.city + ', ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Email: ' + subscriberAddress.contactEmail, alignment: 'left', bold: false, style: 'normalSmall' }]);
            if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
                subscriberDetails.push([{ text: 'GSTIN: ' + subscriber.taxIdNumber, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
        case Common.INVOICE_FORMAT_RETAIL:
            subscriberDetails.push([{ text: (entityType === Common.PURCHASE_ORDER_ENTITY ? "Invoice to: " : "") + subscriber.legalName, bold: true, fontSize: 12 }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine2, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.city + ', ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Email: ' + subscriberAddress.contactEmail, alignment: 'left', bold: false, style: 'normalSmall' }]);
            if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
                subscriberDetails.push([{ text: 'GSTIN: ' + subscriber.taxIdNumber, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, alignment: 'left', bold: false, style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;
        case Common.INVOICE_FORMAT_CLASSIC:
        case Common.INVOICE_FORMAT_CLASSIC_LOGO:
        case Common.INVOICE_FORMAT_DEFAULT_LOGO:
            subscriberDetails.push([{ text: (entityType === Common.PURCHASE_ORDER_ENTITY ? "Invoice to: " : "") + subscriber.legalName, bold: true, fontSize: getFontSize(subscriber.legalName) }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1 + " " + subscriberAddress.addressLine2 + " " + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, bold: false }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, bold: false }]);
            subscriberDetails.push([{ text: 'Email: ' + subscriberAddress.contactEmail, bold: false }]);
            if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
                subscriberDetails.push([{ text: 'GSTIN: ' + subscriber.taxIdNumber, bold: true }]);
            }
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, bold: false }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;
        case Common.INVOICE_FORMAT_COMFORT:
        case Common.INVOICE_FORMAT_COMFORT_LOGO:
            subscriberDetails.push([{ text: subscriber.legalName, alignment: 'left', style: 'headerBig' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine2, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, alignment: 'left', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, bold: false, alignment: 'left', style: 'normalSmall' }]);
            subscriberDetails.push([{ text: ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'left', style: 'normalSmall' }]);
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, bold: false, alignment: 'left', style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;

        case Common.INVOICE_FORMAT_ELEGANT:
        case Common.INVOICE_FORMAT_ELEGANT_LOGO:
            subscriberDetails.push([{ text: subscriber.legalName, alignment: 'center', style: 'headerBig' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1, alignment: 'center', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine2, alignment: 'center', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normalSmall' }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, bold: false, alignment: 'center', style: 'normalSmall' }]);
            subscriberDetails.push([{ text: ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normalSmall' }]);
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, bold: false, alignment: 'center', style: 'normalSmall' }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'center')]);
                }
                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'center')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'center')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'center')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'center')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'center')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'center' }]);
                }
            }
            break;
        default:
            subscriberDetails.push([{ text: (entityType === Common.PURCHASE_ORDER_ENTITY ? "Invoice to: " : "") + subscriber.legalName, bold: true, fontSize: getFontSize(subscriber.legalName) }]);
            subscriberDetails.push([{ text: subscriberAddress.addressLine1 + " " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, stateList) + ' ' + subscriberAddress.pinCode, bold: false }]);
            subscriberDetails.push([{ text: 'Tel.: ' + subscriberAddress.contactPhone, bold: false }]);
            subscriberDetails.push([{ text: 'Email: ' + subscriberAddress.contactEmail, bold: false }]);
            if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
                subscriberDetails.push([{ text: 'GSTIN: ' + subscriber.taxIdNumber, bold: true }]);
            }
            if (!Utility.isNullOrEmpty(subscriberAddress.website)) {
                subscriberDetails.push([{ text: 'Website: ' + subscriberAddress.website, bold: false }]);
            }
            if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
                let additionalDetails = JSON.parse(subscriber.additionalDetails);
                let fssaiLicense = Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                if (!Utility.isNullOrEmpty(fssaiLicense)) {
                    subscriberDetails.push([getLabelText('FSSAI License: ', fssaiLicense, true, 9, 'left')]);
                }

                let importExportCode = Utility.getAdditioanlFieldValue(additionalDetails, 'importExportCode');
                if (!Utility.isNullOrEmpty(importExportCode)) {
                    subscriberDetails.push([getLabelText('IEC: ', importExportCode, true, 9, 'left')]);
                }
                let msmeCertificateNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
                if (!Utility.isNullOrEmpty(msmeCertificateNumber)) {
                    subscriberDetails.push([getLabelText('MSME Cert. #: ', msmeCertificateNumber, true, 9, 'left')]);
                }
                let CertificateOne = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
                if (!Utility.isNullOrEmpty(CertificateOne)) {
                    subscriberDetails.push([getLabelText(' Certificate 1 #: ', CertificateOne, true, 9, 'left')]);
                }
                let CertificateTwo = Utility.getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
                if (!Utility.isNullOrEmpty(CertificateTwo)) {
                    subscriberDetails.push([getLabelText(' Certificate 2 #: ', CertificateTwo, true, 9, 'left')]);
                }
                let tanNumber = Utility.getAdditioanlFieldValue(additionalDetails, 'tanNumber');
                if (!Utility.isNullOrEmpty(tanNumber)) {
                    subscriberDetails.push([getLabelText('TAN Number: ', tanNumber, true, 9, 'left')]);
                }
                if (drugLicense !== '') {
                    subscriberDetails.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
                }
            }
            break;
    }
    return subscriberDetails;
}


function getMainHeading(entityType, subscriber, subscriberAddress, printData, stateList, settings, compact = false) {
    const voucherData = printData.voucherData;
    const voucherFormat = settings[Utility.lowerCaseFirstLetter(entityType) + 'Format'];
    const isCompositionScheme = subscriber.taxRegistrationTypeId == Common.TAX_REG_COMPOSITION_SCHEME;
    const isUnregisteredBusiness = subscriber.taxRegistrationTypeId == Common.TAX_REG_UNREGISTERED;
    const customFields = printData.customFields;
    let fieldDefinition = getEntityFields(customFields, entityType);
    let additionalDetails = null;
    additionalDetails = getAdditionalDetails(voucherData);

    let docHeading = 'TAX INVOICE';
    if (entityType !== 'Invoice') {
        docHeading = printData.heading;
    }
    if (isCompositionScheme) {
        docHeading = 'Bill of Supply';
    } else if (isUnregisteredBusiness) {
        docHeading = 'Sales Invoice';
    }

    switch (voucherFormat) {
        case Common.INVOICE_FORMAT_MODERN:
        case Common.INVOICE_FORMAT_MODERN_LOGO:
            var voucherInfo = [{ text: docHeading, bold: true, margin: [5, 5, 5, 0], alignment: 'right', fontSize: 18 }];
            voucherInfo.push({ text: [`${entityType} #: `, { text: voucherData.orderNumber, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            voucherInfo.push({ text: [`${entityType} date: `, { text: Utility.getLocaleDate(voucherData.orderDate), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });

            if (fieldDefinition?.hasCustomFields) {
                let customFieldVal = '';
                let rowArray = [];
                for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                    const customField = fieldDefinition?.customFields[i];
                    if (Utility.safeBool(customField?.print)) {
                        if (Utility.safeBool(customField?.isStandard)) {
                            customFieldVal = Utility.safeString(voucherData[customField?.name]);
                        } else {
                            if (!Utility.isNullOrUndefined(additionalDetails)) {
                                customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                            } else {
                                customFieldVal = '';
                            }
                        }
                        if (customField?.type === 'DateTime') {
                            customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                        } else if (customField?.type === 'Date') {
                            customFieldVal = Utility.getLocaleDate2(customFieldVal);
                        } else if (customField?.type === 'Currency') {
                            customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                        }
                        if (Utility.safeBool(customField.fullWidth)) {
                            voucherInfo.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });

                            // voucherInfo.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
                        } else {
                            rowArray.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                            if (rowArray.length === 2) {
                                voucherInfo.push(rowArray);
                                rowArray = [];
                            } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                                rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                voucherInfo.push(rowArray);
                                rowArray = [];
                            }
                        }
                    }
                    // At the end also check if any non-fullwidth fields were left out
                    if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                        rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    }
                }

            } else {
                if (!Utility.isNullOrEmpty(voucherData.poReference)) {
                    voucherInfo.push({ text: ['P.O. #:  ', { text: voucherData.poReference + ', dt:' + Utility.getLocaleDate(voucherData.poDate), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData.eWayBillNo)) {
                    voucherInfo.push({ text: ['E-Way bill: ', { text: Utility.safeString(voucherData.eWayBillNo), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData.transportDetails)) {
                    voucherInfo.push({ text: ['Transport: ', { text: Utility.safeString(voucherData.transportDetails), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData?.termsAndConditions)) {
                    voucherInfo.push({ text: ['Notes/Terms: ', { text: Utility.safeString(voucherData.termsAndConditions), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
            }

            return (
                [
                    {
                        style: 'nestedTable',
                        table: {
                            body: [
                                [
                                    {
                                        stack: voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO ? getLogo(subscriber, settings) : []
                                    },
                                    [
                                        {
                                            table: {
                                                body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                            },
                                            layout: {
                                                hLineWidth: function (i, node) {
                                                    return 0;
                                                },
                                                vLineWidth: function (i, node) {
                                                    return 0;
                                                },
                                                hLineColor: function (i, node) {
                                                    return 'black';
                                                },
                                                vLineColor: function (i, node) {
                                                    return 'black';
                                                },
                                                paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                paddingBottom: function (i, node) { return 0 },
                                                paddingRight: function (i, node) { return 0 },
                                                paddingLeft: function (i, node) { return 0 }
                                            }
                                        }
                                    ]
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        stack: voucherInfo
                                    }
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
        case Common.INVOICE_FORMAT_RETAIL:
            let voucherInfoRetail = [{ text: docHeading, bold: true, margin: [5, 5, 5, 0], alignment: 'right', fontSize: 18 }];
            voucherInfoRetail.push({ text: [`${entityType} #: `, { text: voucherData.orderNumber, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            voucherInfoRetail.push({ text: [`${entityType} date: `, { text: Utility.getLocaleDate(voucherData.orderDate), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            if (fieldDefinition?.hasCustomFields) {
                let customFieldVal = '';
                let rowArray = [];
                for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                    const customField = fieldDefinition?.customFields[i];
                    if (Utility.safeBool(customField?.print)) {
                        if (Utility.safeBool(customField?.isStandard)) {
                            customFieldVal = Utility.safeString(voucherData[customField?.name]);
                        } else {
                            if (!Utility.isNullOrUndefined(additionalDetails)) {
                                customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                            } else {
                                customFieldVal = '';
                            }
                        }
                        if (customField?.type === 'DateTime') {
                            customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                        } else if (customField?.type === 'Date') {
                            customFieldVal = Utility.getLocaleDate2(customFieldVal);
                        } else if (customField?.type === 'Currency') {
                            customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                        }
                        if (Utility.safeBool(customField.fullWidth)) {
                            voucherInfoRetail.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });

                            // voucherInfo.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
                        } else {
                            rowArray.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                            if (rowArray.length === 2) {
                                voucherInfoRetail.push(rowArray);
                                rowArray = [];
                            } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                                rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                voucherInfoRetail.push(rowArray);
                                rowArray = [];
                            }
                        }
                    }
                    // At the end also check if any non-fullwidth fields were left out
                    if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                        rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                        voucherInfoRetail.push(rowArray);
                        rowArray = [];
                    }
                }
            } else {
                if (!Utility.isNullOrEmpty(voucherData.eWayBillNo)) {
                    voucherInfoRetail.push({ text: ['E-Way bill: ', { text: Utility.safeString(voucherData.eWayBillNo), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                voucherInfoRetail.push({ text: ['Notes/Terms: ', { text: Utility.safeString(voucherData.termsAndConditions), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            }

            return (
                [
                    {
                        style: 'nestedTable',
                        table: {
                            body: [
                                [
                                    {
                                        stack: voucherFormat === Common.INVOICE_FORMAT_RETAIL_LOGO ? getLogo(subscriber, settings) : []
                                    },
                                    [
                                        {
                                            table: {
                                                body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                            },
                                            layout: {
                                                hLineWidth: function (i, node) {
                                                    return 0;
                                                },
                                                vLineWidth: function (i, node) {
                                                    return 0;
                                                },
                                                hLineColor: function (i, node) {
                                                    return 'black';
                                                },
                                                vLineColor: function (i, node) {
                                                    return 'black';
                                                },
                                                paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                paddingBottom: function (i, node) { return 0 },
                                                paddingRight: function (i, node) { return 0 }
                                            }
                                        }
                                    ]
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        stack: voucherInfoRetail
                                    }
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);
        case Common.INVOICE_FORMAT_CLASSIC:
            return (
                [
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*', '*'],
                            body: getEntityDetails(voucherData, entityType, voucherFormat, customFields, printData)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === node.table.body.length) ? 0 : 0.1;
                            },
                            vLineWidth: function (i, node) {
                                return 0.1;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);

        case Common.INVOICE_FORMAT_CLASSIC_LOGO:
        case Common.INVOICE_FORMAT_DEFAULT_LOGO:
            return (
                [
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            body:
                                [
                                    [
                                        {
                                            stack: getLogo(subscriber, settings)
                                        },
                                        [
                                            {
                                                table: {
                                                    body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                                },
                                                layout: {
                                                    hLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    vLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    hLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    vLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                    paddingBottom: function (i, node) { return 0 },
                                                    paddingRight: function (i, node) { return 0 }
                                                }
                                            }
                                        ]
                                    ]
                                ]

                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*', '*'],
                            body: getEntityDetails(voucherData, entityType, voucherFormat, customFields, printData)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === node.table.body.length) ? 0.1 : 0.1;
                            },
                            vLineWidth: function (i, node) {
                                return 0.1;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);

        case Common.INVOICE_FORMAT_COMFORT:
            return (
                [
                    {
                        style: 'noMargins',
                        table: {
                            widths: ['*'],
                            body:
                                [
                                    [
                                        [
                                            {
                                                table: {
                                                    widths: ['*'],
                                                    body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                                },
                                                layout: {
                                                    hLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    vLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    hLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    vLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                    paddingBottom: function (i, node) { return 0 },
                                                    paddingRight: function (i, node) { return 0 }
                                                }
                                            }
                                        ]
                                    ]
                                ]

                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 1 : 0 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                ]);

        case Common.INVOICE_FORMAT_COMFORT_LOGO:
            return (
                [
                    {
                        style: 'noMargins',
                        table: {
                            widths: ['*', 'auto'],
                            body:
                                [
                                    [
                                        [
                                            {
                                                table: {
                                                    widths: ['*'],
                                                    body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                                },
                                                layout: {
                                                    hLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    vLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    hLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    vLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                    paddingBottom: function (i, node) { return 0 },
                                                    paddingRight: function (i, node) { return 0 }
                                                }
                                            }
                                        ],
                                        {
                                            stack: getLogo(subscriber, settings, 100, 'right')
                                        }
                                    ]
                                ]

                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 1 : 0 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                ]);
        case Common.INVOICE_FORMAT_ELEGANT:
            return (
                [
                    {
                        style: 'noMargins',
                        table: {
                            widths: ['*'],
                            body:
                                [
                                    [
                                        [
                                            {
                                                table: {
                                                    widths: ['*'],
                                                    body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                                },
                                                layout: {
                                                    hLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    vLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    hLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    vLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                    paddingBottom: function (i, node) { return 0 },
                                                    paddingRight: function (i, node) { return 0 }
                                                }
                                            }
                                        ]
                                    ]
                                ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 1 : 0 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                ]);

        case Common.INVOICE_FORMAT_CUSTOM_1:
            // var voucherInfo = [{ text: docHeading, bold: true, margin: [5, 5, 5, 0], alignment: 'right', fontSize: 18 }];
            var voucherInfo = [];
            voucherInfo.push({ text: [`${entityType} #: `, { text: voucherData.orderNumber, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            voucherInfo.push({ text: [`${entityType} Date: `, { text: Utility.getLocaleDate(voucherData.orderDate), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
            if (fieldDefinition?.hasCustomFields) {
                let customFieldVal = '';
                let rowArray = [];
                for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                    const customField = fieldDefinition?.customFields[i];
                    if (Utility.safeBool(customField?.print)) {
                        if (Utility.safeBool(customField?.isStandard)) {
                            customFieldVal = Utility.safeString(voucherData[customField?.name]);
                        } else {
                            if (!Utility.isNullOrUndefined(additionalDetails)) {
                                customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                            } else {
                                customFieldVal = '';
                            }
                        }
                        if (customField?.type === 'DateTime') {
                            customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                        } else if (customField?.type === 'Date') {
                            customFieldVal = Utility.getLocaleDate2(customFieldVal);
                        } else if (customField?.type === 'Currency') {
                            customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                        }
                        if (Utility.safeBool(customField.fullWidth)) {
                            voucherInfo.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });

                            // voucherInfo.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
                        } else {
                            rowArray.push({ text: [Utility.safeString(customField?.label) + ': ', { text: customFieldVal, bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                            if (rowArray.length === 2) {
                                voucherInfo.push(rowArray);
                                rowArray = [];
                            } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                                rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                voucherInfo.push(rowArray);
                                rowArray = [];
                            }
                        }
                    }
                    // At the end also check if any non-fullwidth fields were left out
                    if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                        rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    }
                }
            } else {
                if (!Utility.isNullOrEmpty(voucherData.poReference)) {
                    voucherInfo.push({ text: ['P.O. #:  ', { text: voucherData.poReference + ', dt:' + Utility.getLocaleDate(voucherData.poDate), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData.eWayBillNo)) {
                    voucherInfo.push({ text: ['E-Way bill: ', { text: Utility.safeString(voucherData.eWayBillNo), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData.transportDetails)) {
                    voucherInfo.push({ text: ['Transport: ', { text: Utility.safeString(voucherData.transportDetails), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
                if (!Utility.isNullOrEmpty(voucherData?.termsAndConditions)) {
                    voucherInfo.push({ text: ['Terms: ', { text: Utility.safeString(voucherData.termsAndConditions), bold: true }], fontSize: 10, margin: [5, 0, 5, 0], alignment: 'right' });
                }
            }
            return (
                [
                    {
                        style: 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        stack: getLogo(subscriber, settings)
                                    },
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 },
                            paddingLeft: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        stack: voucherInfo
                                    }
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);
        case Common.INVOICE_FORMAT_ELEGANT_LOGO:
            return (
                [
                    {
                        style: 'noMargins',
                        table: {
                            widths: ['-6%', '*'],
                            body:
                                [
                                    [
                                        {
                                            stack: getLogo(subscriber, settings, 72)
                                        },
                                        [
                                            {
                                                table: {
                                                    widths: ['*'],
                                                    body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                                                },
                                                layout: {
                                                    hLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    vLineWidth: function (i, node) {
                                                        return 0;
                                                    },
                                                    hLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    vLineColor: function (i, node) {
                                                        return 'black';
                                                    },
                                                    paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                                                    paddingBottom: function (i, node) { return 0 },
                                                    paddingRight: function (i, node) { return 0 }
                                                }
                                            }
                                        ]
                                    ]
                                ]

                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 1 : 0 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                ]);
        default:
            return (
                [
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*'],
                            body: getSubscriberDetails(voucherFormat, entityType, subscriber, subscriberAddress, stateList)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
                            paddingBottom: function (i, node) { return 0 },
                            paddingRight: function (i, node) { return 0 }
                        }
                    },
                    {
                        style: compact ? 'nestedTableCompact' : 'nestedTable',
                        table: {
                            widths: ['*', '*'],
                            body: getEntityDetails(voucherData, entityType, voucherFormat, customFields, printData)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingRight: function (i, node) { return 0 }
                        }
                    }
                ]);
    }
}

const getFontSize = (value, compact = false) => {
    let fontSize = 10;
    try {
        if (!Utility.isNullOrUndefined(value)) {
            if (Utility.safeString(value).length > 40) {
                return 9;
            }
        }
        return 10;
    } catch (error) {
        console.log(error);
    }
    return fontSize;


}

export function addHeader(printData, entityType = 'Invoice') {
    var settings = printData.settings;
    return {
        stack: [
            { text: getGreetingMessage(settings, entityType), alignment: 'center', margin: [0, 10, 0, 0], style: printData.compact ? 'quoteSmall' : 'quote' },
            { text: printData.heading, alignment: 'center', margin: [0, 0, 0, 0], style: printData.compact ? 'headerCompact' : 'header' },
        ]
    }
}

export function addPageBody(printData, entityType, printFormat, isElegant = false) {
    var pageBody = [];
    var settings = printData.settings;
    var stateList = printData.stateList;
    var subscriber = printData.subscriber;
    var subscriberAddress = printData.subscriber.addressList[0];
    const body = addBody(printData, entityType, printFormat);
    var mainHeading = null;
    var mainHeadingTableWidths = null;
    if (isElegant) {
        mainHeadingTableWidths = ['*'];
        const header = addHeaderElegant(printData, entityType);
        mainHeading = [header];
    } else {
        mainHeadingTableWidths = printFormat === Common.INVOICE_FORMAT_CUSTOM_1 ? ['auto', '*', 'auto'] : ['*', '*'];
        if (printFormat === Common.INVOICE_FORMAT_RETAIL_LOGO || printFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
            mainHeadingTableWidths = ['60%', '40%'];
        }
        mainHeading = getMainHeading(entityType, subscriber, subscriberAddress, printData, stateList, settings);
    }

    let example = {
        table: {
            style: 'noMargins',
            widths: ['*'],
            headerRows: 1,
            body: [
                [
                    {
                        style: 'noMargins',
                        table: {
                            widths: mainHeadingTableWidths,
                            body: [
                                mainHeading
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0;
                            },
                            vLineWidth: function (i, node) {
                                return (printFormat === Common.INVOICE_FORMAT_CUSTOM_1 || printFormat === Common.INVOICE_FORMAT_RETAIL_LOGO || printFormat === Common.INVOICE_FORMAT_MODERN_LOGO) ? 0 : 0.1;
                            },
                            hLineColor: function (i, node) {
                                return 'black';
                            },
                            vLineColor: function (i, node) {
                                return 'black';
                            },
                            paddingLeft: function (i, node) { return 0; },
                            paddingRight: function (i, node) { return 0; },
                            paddingTop: function (i, node) { return 0; },
                            paddingBottom: function (i, node) { return 0; },
                        }
                    }
                ],
                [
                    body,
                ]
            ]
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0;
            },
            vLineWidth: function (i, node) {
                return 0.1;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
        }
    };

    pageBody.push(example);
    return pageBody;
}

export function addBody(printData, entityType, printFormat) {
    var body = [];
    const isCompositionScheme = printData.subscriber.taxRegistrationTypeId == Common.TAX_REG_COMPOSITION_SCHEME;
    const isUnregisteredBusiness = printData.subscriber.taxRegistrationTypeId == Common.TAX_REG_UNREGISTERED;
    var settings = printData.settings;
    const stateList = printData.stateList;
    var party = printData.party;
    var partyAddress = {};
    const voucherData = printData.voucherData;
    const isEInvoice = (!Utility.isNullOrUndefined(voucherData) && !Utility.isNullOrUndefined(voucherData.eInvoiceData));

    if (!Utility.isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
        partyAddress = party.addressList.find(a => a.addressType === 'Billing' && a.isPrimary === true)
    }
    var addressTableWidths = isEInvoice ? ['*', '*', 'auto'] : ['*', '*'];
    var addressTableBody = [];
    addressTableBody.push({
        style: 'headerAddressTable',
        table: {
            widths: ['*'],
            body: getPartyAddress(settings, voucherData, party, partyAddress, stateList, entityType)
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 0.1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
            paddingBottom: function (i, node) { return 0 },
        }
    });
    addressTableBody.push({
        style: 'headerAddressTable',
        table: {
            widths: ['*'],
            body: getShippingAddress(printData.deliveryAddress, stateList)
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 0.1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
            paddingTop: function (i, node) { return i === 0 ? 2 : 1 },
            paddingBottom: function (i, node) { return 0 },
        }
    });
    if (isEInvoice) {
        addressTableBody.push(getEInvoiceQRCode(voucherData));
    }

    const header = {
        style: 'headerAddressTable',
        table: {
            widths: addressTableWidths,
            body: [addressTableBody]
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return 'black';
            },
            vLineColor: function (i, node) {
                return 'black';
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            // paddingBottom: function(i, node) { return 2; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
        }
    };
    body.push(header);

    if (isEInvoice) {
        body.push({
            stack: [
                { text: getLabelText("IRN: ", Utility.safeString(voucherData.eInvoiceData.irnNo)), margin: [1, 1, 1, 1], alignment: 'right', fontSize: 8 },
                { text: getLabelText("Ack. No & Date: ", Utility.safeString(voucherData.eInvoiceData.ackNo + " " + voucherData.eInvoiceData.ackDate)), margin: [1, 1, 1, 1], alignment: 'right', fontSize: 8 },
            ]
        });
    }

    var itemsTable = getItemsTable(printData, entityType, printFormat);
    body.push(itemsTable);

    if (entityType === Common.DELIVERY_CHALLAN_ENTITY) {
        if (printData.settings.includeAmountDeliveryChallan) {
            var dcSummaryWithAmountTable = getVoucherSummaryTable(printData, entityType, printFormat, isCompositionScheme);
            body.push(dcSummaryWithAmountTable);
        } else {
            var dcSummary = getDeliveryChallanSummary(printData);
            body.push(dcSummary);
        }
    } else {
        var voucherSummaryTable = getVoucherSummaryTable(printData, entityType, printFormat, isCompositionScheme);
        body.push(voucherSummaryTable);
        var includeHSNSummary = true;
        if (printFormat === Common.INVOICE_FORMAT_RETAIL
            || printFormat === Common.INVOICE_FORMAT_RETAIL_LOGO
            || printFormat === Common.INVOICE_FORMAT_MODERN
            || printFormat === Common.INVOICE_FORMAT_MODERN_LOGO
        ) {
            includeHSNSummary = false;
        }
        if (includeHSNSummary === true && !isCompositionScheme && !isUnregisteredBusiness) {
            if (!Utility.isNullOrUndefined(printData.hsnSummary) && printData.hsnSummary.length > 0) {
                var hsnSummaryTable = getHSNSummaryTable(printData.hsnSummary, printData.compact);
                body.push(hsnSummaryTable)
            }
        }
        if (isCompositionScheme) {
            body.push(
                { text: getLabelText("Declaration: ", "Composition taxable person, not eligibile to collect tax on supplies.", true, 10, 'center'), margin: [21, 15, 2, 2], alignment: 'center', fontSize: 10 },
            )
        }
    }

    if (entityType === 'Invoice' || entityType === 'Quotation' || entityType === 'PurchaseOrder' || entityType === "PurchaseBill") {
        body.push(addBankTermsAndConditions(printData.subscriber, printData.bankDetails, printData.settings, entityType, printData.compact));
    }

    return body;
}

export function getEInvoiceQRCode(voucherData) {
    if (!Utility.isNullOrUndefined(voucherData) && !Utility.isNullOrUndefined(voucherData.eInvoiceData)) {
        return [
            {
                image: "data:image/png;base64," + Utility.safeString(voucherData.eInvoiceData.qrCodeData),
                width: 100,
                height: 100,
                margin: [0, 0, 0, 0],
                alignment: 'right'
            },
        ];
    }
    return null;

}

function getDrugLicenseValue(subscriber) {
    var drugLicencse = '';
    try {
        if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
            const additionalDetails = JSON.parse(subscriber.additionalDetails);
            const drugLicense20B = !Utility.isNullOrUndefined(additionalDetails) ? Utility.getAdditioanlFieldValue(additionalDetails, 'drugLicense20B') : '';
            const drugLicense21B = !Utility.isNullOrUndefined(additionalDetails) ? Utility.getAdditioanlFieldValue(additionalDetails, 'drugLicense21B') : '';


            if (drugLicense20B !== '' || drugLicense21B !== '') {
                drugLicencse = drugLicense20B + "/" + drugLicense21B;
            }
        }
        return drugLicencse;
    } catch (error) {
        return drugLicencse;
    }
}


function getFssaiLicense(subscriber) {
    var fssaiLicense = '';
    try {
        if (!Utility.isNullOrEmpty(subscriber.additionalDetails)) {
            const additionalDetails = JSON.parse(subscriber.additionalDetails);
            const fssaiLicenseData = !Utility.isNullOrUndefined(additionalDetails) ? Utility.getAdditioanlFieldValue(additionalDetails, 'fssaiLicense') : '';
            if (fssaiLicenseData !== '') {
                fssaiLicense = fssaiLicenseData;
            }
        }
        return fssaiLicense;
    } catch (error) {
        return fssaiLicense;
    }
}
/* export function addFooter(currentPage, pageCount, pageSize, printData, entityType = '', compact = false) {
    var subscriber = printData.subscriber;
    if (entityType === 'Invoice') {
        var stackData = [];        
        stackData.push(addCustomerSignatureFooter(subscriber, entityType, compact, printData.settings));
        if(!Utility.isNullOrEmpty(printData.settings.authorisedSignature)) {
            const signaureImage = {                
                image: printData.settings.authorisedSignature,
                fit: [100, 100],
                alignment: 'right',
                margin: [0, -36, 54, 0]                
            }
            stackData.push(signaureImage);
        }
        stackData.push({ text: 'Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.', alignment: 'center', fontSize: 7 });
        stackData.push({ text: 'This is a computer generated invoice.', alignment: 'center', fontSize: 7 });
        stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 20, 0], fontSize: 7, bold: true });
        return {
            stack: stackData
        }
    } else {
        return {
            stack: [
                (entityType === 'Invoice')
                    ? addBankTermsAndConditions(printData.subscriber, printData.bankDetails, printData.settings, entityType, printData.compact)
                    : null,
                addCustomerSignatureFooter(subscriber, entityType, compact, printData.settings),
                { text: 'This is a computer generated ' + entityType, alignment: 'center', fontSize: 7 },
                { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 20, 0], fontSize: 7, bold: true },
            ]
        }
    }
} */
export function addFooter(currentPage, pageCount, pageSize, printData, entityType = '', compact = false) {
    var subscriber = printData.subscriber;
    var stackData = [];

    switch (entityType) {
        case 'Invoice':
            stackData.push(addCustomerSignatureFooter(subscriber, entityType, printData.settings, compact));
            if (!Utility.isNullOrEmpty(printData.subscriber.signatureAndSeal)) {
                stackData.push({
                    image: printData.subscriber.signatureAndSeal,
                    fit: [48, 48],
                    absolutePosition: { x: 410, y: 10 },
                });
            } else if (!Utility.isNullOrEmpty(printData.settings.authorisedSignature)) {
                stackData.push({
                    image: printData.settings.authorisedSignature,
                    fit: [120, 120],
                    absolutePosition: { x: 382, y: 10 }
                });
            }
            stackData.push({ text: 'Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.', alignment: 'center', fontSize: 8 });
            stackData.push({ text: 'This is a computer generated invoice.', alignment: 'center', fontSize: 8 });
            break;

        case 'Quotation':
            stackData.push(addCustomerSignatureFooter(subscriber, entityType, printData.settings, compact));
            if (!Utility.isNullOrEmpty(printData.subscriber.signatureAndSeal)) {
                stackData.push({
                    image: printData.subscriber.signatureAndSeal,
                    fit: [48, 48],
                    absolutePosition: { x: 410, y: 10 },
                });
            } else if (!Utility.isNullOrEmpty(printData.settings.authorisedSignature)) {
                stackData.push({
                    image: printData.settings.authorisedSignature,
                    fit: [120, 120],
                    absolutePosition: { x: 382, y: 10 }
                });
            }
            stackData.push({ text: `This is a computer generated ${printData.heading}.`, alignment: 'center', fontSize: 9 });
            break;

        default:
            stackData.push(addCustomerSignatureFooter(subscriber, entityType, printData.settings, compact));
            if (!Utility.isNullOrEmpty(printData.subscriber.signatureAndSeal)) {
                stackData.push({
                    image: printData.subscriber.signatureAndSeal,
                    fit: [48, 48],
                    absolutePosition: { x: 410, y: 10 },
                });
            } else if (!Utility.isNullOrEmpty(printData.settings.authorisedSignature)) {
                stackData.push({
                    image: printData.settings.authorisedSignature,
                    fit: [120, 120],
                    absolutePosition: { x: 382, y: 10 }
                });
            }
            stackData.push({ text: 'This is a computer generated ' + entityType, alignment: 'center', fontSize: 9 });
            break;
    }
    if (entityType === Common.DELIVERY_CHALLAN_ENTITY) {
        stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, absolutePosition: { x: 528, y: 85 }, fontSize: 9, bold: true });
    } else {
        stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, absolutePosition: { x: 528, y: 75 }, fontSize: 9, bold: true });
    }

    return {
        stack: stackData
    };
}


export function addPageBorder(reportName = "", entityType = "", compact = false) {

    switch (reportName) {
        case Common.REPORT_ORDERS_PRINT:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 43, x2: 575, y2: 43, lineWidth: 0.5 }, //Up line
                        { type: 'line', x1: 20, y1: 43, x2: 20, y2: 800, lineWidth: 0.5 }, //Left line
                        { type: 'line', x1: 20, y1: 800, x2: 575, y2: 800, lineWidth: 0.5 }, //Bottom line
                        { type: 'line', x1: 575, y1: 43, x2: 575, y2: 800, lineWidth: 0.5 }, //Rigth line
                    ],
                }
            ];
        case Common.REPORT_STATEMENT_PRINT:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 72, x2: 575, y2: 72, lineWidth: 0.5 }, //Up line
                        { type: 'line', x1: 20, y1: 72, x2: 20, y2: 780, lineWidth: 0.5 }, //Left line
                        { type: 'line', x1: 20, y1: 780, x2: 575, y2: 780, lineWidth: 0.5 }, //Bottom line
                        { type: 'line', x1: 575, y1: 72, x2: 575, y2: 780, lineWidth: 0.5 }, //Rigth line
                    ],
                }
            ];
        case Common.REPORT_PENDING_STATEMENT_PRINT:
        case Common.REPORT_CUSTOMER_GROUP_STATEMENT_PRINT:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 81, x2: 575, y2: 81, lineWidth: 1 }, //Up line                        
                    ],
                }
            ];
        case Common.REPORT_PAYMENT_RECEIPT_PRINT:
        case Common.REPORT_EXPENSE_RECEIPT_PRINT:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 20, x2: 575, y2: 20, lineWidth: 1 }, //Up line
                        { type: 'line', x1: 20, y1: 20, x2: 20, y2: 400, lineWidth: 1 }, //Left line
                        { type: 'line', x1: 20, y1: 400, x2: 575, y2: 400, lineWidth: 1 }, //Bottom line
                        { type: 'line', x1: 575, y1: 20, x2: 575, y2: 400, lineWidth: 1 }, //Rigth line
                    ],
                }
            ];
        case Common.INVOICE_FORMAT_MODERN_LOGO:
        case Common.INVOICE_FORMAT_MODERN:
        case Common.INVOICE_FORMAT_RETAIL_LOGO:
        case Common.INVOICE_FORMAT_RETAIL:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 30, x2: 575, y2: 30, lineWidth: 0.5 }, //Up line
                        { type: 'line', x1: 20, y1: 30, x2: 20, y2: 788, lineWidth: 0.5 }, //Left line
                        // { type: 'line', x1: 20, y1: 780, x2: 575, y2: 780, lineWidth: 0.5 }, //Bottom line
                        { type: 'line', x1: 575, y1: 30, x2: 575, y2: 788, lineWidth: 0.5 }, //Rigth line
                    ],
                }
            ];
        case Common.INVOICE_ENTITY:
        case Common.QUOTATION_ENTITY:
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: compact ? 36 : 43, x2: 575, y2: compact ? 36 : 43, lineWidth: 0.5 }, //Up line
                        { type: 'line', x1: 20, y1: compact ? 36 : 43, x2: 20, y2: 760, lineWidth: 0.5 }, //Left line
                        // { type: 'line', x1: 20, y1: 800, x2: 575, y2: 800, lineWidth: 0.5 }, //Bottom line
                        { type: 'line', x1: 20, y1: 700, x2: 575, y2: 700, lineWidth: 0.5 }, //Footer line
                        { type: 'line', x1: 575, y1: compact ? 36 : 43, x2: 575, y2: 760, lineWidth: 0.5 }, //Rigth line
                    ],
                }
            ];
        default:
            if (entityType === "Invoice" || entityType === "Quotation") {
                return [
                    {
                        canvas: [
                            { type: 'line', x1: 20, y1: compact ? 36 : 43, x2: 575, y2: compact ? 36 : 43, lineWidth: 0.5 }, //Up line
                            { type: 'line', x1: 20, y1: compact ? 36 : 43, x2: 20, y2: 760, lineWidth: 0.5 }, //Left line
                            // { type: 'line', x1: 20, y1: 800, x2: 575, y2: 800, lineWidth: 0.5 }, //Bottom line
                            // { type: 'line', x1: 20, y1: 700, x2: 575, y2: 700, lineWidth: 0.5 }, //Footer line
                            { type: 'line', x1: 575, y1: compact ? 36 : 43, x2: 575, y2: 760, lineWidth: 0.5 }, //Rigth line
                        ],
                    }
                ];
            } else {
                return [
                    {
                        canvas: [
                            { type: 'line', x1: 20, y1: 43, x2: 575, y2: 43, lineWidth: 0.5 }, //Up line
                            { type: 'line', x1: 20, y1: 43, x2: 20, y2: 780, lineWidth: 0.5 }, //Left line
                            // { type: 'line', x1: 20, y1: 800, x2: 575, y2: 800, lineWidth: 0.5 }, //Bottom line
                            { type: 'line', x1: 575, y1: 43, x2: 575, y2: 780, lineWidth: 0.5 }, //Rigth line
                        ],
                    }
                ];
            }

    }

}

export function buildTable(columnDefinitions, tableData, hasHeader = true, emptyRowsToAdd = 12, styles = '', addTotalQuantity = false, compact = false) {

    var columnWidths = [];
    var columnAlignments = [];
    var columnHeaders = [];

    if (!!columnDefinitions && columnDefinitions.length > 0) {
        columnDefinitions.map(def => {
            columnWidths.push(def.width);
            columnAlignments.push((!!def.align ? def.align : 'left'));
            if (hasHeader === true) {
                columnHeaders.push({ text: def.title, bold: true, alignment: def.align });
            }
        });
    }

    var tableBody = [];
    if (hasHeader === true) {
        tableBody.push(columnHeaders);
    }

    var totalQuantity = 0;
    var lineTotal = 0;
    let isMiscellaneous = false;
    if (!!tableData && tableData.length > 0) {
        tableData.map((dataItem, index) => {
            var row = [];
            isMiscellaneous = Utility.safeString(dataItem?.productType) === 'Miscellaneous';
            columnDefinitions.map((col, colIndex) => {
                if (col.field === 'index') {
                    row.push({ text: index + 1, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                } else {
                    if (col.type === 'currency') {
                        let amountValue = Utility.safeFloat(dataItem[col.field]);
                        if (Utility.safeBool(col.blankIfZero)) {
                            if (amountValue !== 0) {
                                amountValue = Utility.formatToCurency(amountValue);
                            } else {
                                amountValue = '0.00';
                            }
                        } else {
                            amountValue = amountValue.toFixed(2);
                        }
                        if (isMiscellaneous && Utility.safeFloat(dataItem[col.field]) === 0) {
                            amountValue = '';
                        }
                        row.push({ text: amountValue, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                    } else if (col.type === 'date') {
                        row.push({ text: Utility.getLocaleDate(dataItem[col.field]), alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                    } else {
                        let colValue = dataItem[col.field];
                        if (Utility.safeBool(col.blankIfNegative)) {
                            if (colValue <= 0) {
                                colValue = '--';
                            }
                        }
                        if (!Utility.isNullOrUndefined(col.tertiaryField)) {
                            let tempColValue = colValue;
                            if (!Utility.isNullOrUndefined(col.secondaryField) && !Utility.isNullOrEmpty(dataItem[col.secondaryField])) {
                                tempColValue = getDescInfoText(colValue, dataItem[col.secondaryField], false, !!col.fontSize ? col.fontSize : null, col.align)
                            }
                            if (!Utility.isNullOrUndefined(col.tertiaryField) && !Utility.isNullOrEmpty(dataItem[col.tertiaryField])) {
                                tempColValue = getDescInfoText(tempColValue, 'IMEI/Sl.#:' + dataItem[col.tertiaryField], false, !!col.fontSize ? col.fontSize : null, col.align)
                            }
                            row.push({ text: tempColValue, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                        } else {
                            if (!Utility.isNullOrUndefined(col.secondaryField)) {
                                row.push(getDescInfoText(colValue, dataItem[col.secondaryField], false, !!col.fontSize ? col.fontSize : null, col.align));
                            } else {
                                row.push({ text: colValue, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                            }
                        }

                    }
                }
            });
            tableBody.push(row);
            lineTotal += dataItem['lineTotal'];
            if (addTotalQuantity === true) {
                if (dataItem['productType'] === 'Goods') {
                    totalQuantity += dataItem['quantity'];
                }
            }
        });

        if (addTotalQuantity === true && Utility.safeFloat(totalQuantity) > 0) {
            var totalQtyRow = [];
            for (let index = 0; index < columnDefinitions.length; index++) {
                if (columnDefinitions[index]["field"] === "description") {
                    totalQtyRow.push({ text: 'Total:', alignment: 'right', bold: true, style: compact ? 'quoteSmall' : 'quote' });
                } else if (columnDefinitions[index]["field"] === "quantity") {
                    totalQtyRow.push({ text: Utility.safeFloat(totalQuantity), alignment: 'right', bold: true, style: compact ? 'quoteSmall' : 'quote' });
                } else if (columnDefinitions[index]["field"] === "lineTotal") {
                    totalQtyRow.push({ text: Utility.safeFloat(lineTotal).toFixed(2), alignment: 'right', bold: true, style: compact ? 'quoteSmall' : 'quote' });
                } else {
                    totalQtyRow.push('');
                }

            }
            tableBody.push(totalQtyRow);
        }
        // if (emptyRowsToAdd > 0 && tableBody.length <= emptyRowsToAdd) {
        //     for (let index = tableBody.length; index < emptyRowsToAdd; index++) {
        //         var emptyRow = [];
        //         columnDefinitions.map(() => {
        //             emptyRow.push({ text: '\n' });
        //         });
        //         tableBody.push(emptyRow);
        //     }
        // }
    }
    var table = {
        widths: columnWidths,
        headerRows: hasHeader === true ? 1 : 0,
        body: tableBody
    };
    // console.log(JSON.stringify(table));
    return table;
}

export function getStateName(stateId, stateList) {
    if (!Utility.isNullOrUndefined(stateId) && stateId != "") {
        if (Utility.safeInt(stateId) > 0) {
            var stateName = stateList.find(s => Utility.safeInt(s.code) == Utility.safeInt(stateId)).description;
            if (!!stateName) {
                return stateName;
            }
        } else {
            return "";
        }
    }
    return stateId;
}

export function getPlaceOfSupply(stateId, stateList) {
    var stateName = stateList.find(s => Utility.safeInt(s.code) == Utility.safeInt(stateId)).description;
    if (!!stateName) {
        return stateId + '-' + stateName;
    }
    return stateId + '-';
}

export function getTaxSummary(voucherData, voucherItems) {
    var tempObject = {};
    var taxSummary = [];
    voucherItems.map((item, index) => {
        if (Utility.safeFloat(item.taxRate) > 0) {
            if (tempObject.hasOwnProperty('P' + item.taxRate)) {
                tempObject['P' + item.taxRate] = {
                    taxableAmount: tempObject['P' + item.taxRate]['taxableAmount'] + item['taxableAmount'],
                    centralTaxAmount: tempObject['P' + item.taxRate]['centralTaxAmount'] + item['centralTaxAmount'],
                    stateTaxAmount: tempObject['P' + item.taxRate]['stateTaxAmount'] + item['stateTaxAmount'],
                    interStateTaxAmount: tempObject['P' + item.taxRate]['interStateTaxAmount'] + item['interStateTaxAmount']
                }
            } else {
                tempObject['P' + item.taxRate] = {
                    taxableAmount: item['taxableAmount'],
                    centralTaxAmount: item['centralTaxAmount'],
                    stateTaxAmount: item['stateTaxAmount'],
                    interStateTaxAmount: item['interStateTaxAmount']
                }
            }
        }
    });
    for (var prop in tempObject) {
        taxSummary.push(
            {
                taxRateDisplay: prop.substring(1) + '%',
                taxableAmount: tempObject[prop].taxableAmount,
                centralTaxAmount: tempObject[prop].centralTaxAmount,
                stateTaxAmount: tempObject[prop].stateTaxAmount,
                interStateTaxAmount: tempObject[prop].interStateTaxAmount,
                taxRateDesc: 'GST @' + prop.substring(1) + '%',
                totalTax: voucherData.isIGST ? tempObject[prop].interStateTaxAmount : (tempObject[prop].centralTaxAmount + tempObject[prop].stateTaxAmount)
            });
    }

    return taxSummary;
}

export function getDocumentInfo(title, subject, keywords) {
    return {
        title: title,
        creator: 'ezybill',
        producer: 'ezybill',
        creationDate: Utility.getISODate(),
        author: 'EmerSys LLP',
        subject: subject,
        keywords: keywords,
    }
}

function getHSNSummaryColumnDefinition() {
    return [
        { title: 'HSN/SAC', field: 'hsnCode', width: '*', align: 'center' },
        { title: 'Taxable Value', field: 'taxableAmount', width: 'auto', align: 'right', type: "currency" },
        { title: 'CGST', field: 'cgstRateDisplay', width: 'auto', align: 'center' },
        { title: 'Tax Amount', field: 'centralTaxAmountDisplay', width: 'auto', align: 'right' },
        { title: 'SGST', field: 'cgstRateDisplay', width: 'auto', align: 'center' },
        { title: 'Tax Amount', field: 'centralTaxAmountDisplay', width: 'auto', align: 'right' },
        { title: 'IGST', field: 'igstRateDisplay', width: 'auto', align: 'center' },
        { title: 'Tax Amount', field: 'interStateTaxAmountDisplay', width: 'auto', align: 'right' },
        { title: 'Total Tax', field: 'taxTotal', width: '*', align: 'right', type: "currency" },
    ];
}

function getItemsColumnDefinition(settings, entityType = '', hasDiscount = true, isCompositionScheme = false, printPTSPTR = false, isUnregisteredBusiness = false) {
    const voucherFormat = settings[Utility.lowerCaseFirstLetter(entityType) + 'Format'];
    const enableCess = Utility.safeBool(settings.enableCess);
    const printItemCode = Utility.safeBool(settings.printItemCode);

    switch (entityType) {
        case Common.DELIVERY_CHALLAN_ENTITY:
            let columnDefDC = [];
            columnDefDC.push({ title: '#', field: 'index', width: 'auto', align: 'left' });
            columnDefDC.push({ title: 'Item Description', field: 'description', secondaryField: 'orderDetailInfoText', width: '*', align: 'left', bold: true });

            if (printItemCode) {
                columnDefDC.push({ title: 'Code', field: 'productCode', width: 'auto', align: 'left', bold: false });
            }
            if (!isUnregisteredBusiness) {
                columnDefDC.push({ title: 'HSN/SAC', field: 'hsnCode', width: 'auto', align: 'center' });
            }
            if (!isCompositionScheme && !isUnregisteredBusiness) {
                columnDefDC.push({ title: 'GST', field: 'taxRateDisplay', width: 'auto', align: 'center' });
            }
            columnDefDC.push({ title: 'Quantity', field: 'quantity', width: 50, align: 'right', bold: true });
            columnDefDC.push({ title: 'UOM', field: 'unitOfMeasurement', width: 30, align: 'center' });
            if (Utility.safeBool(settings.includeAmountDeliveryChallan)) {
                if (Utility.safeBool(settings.useMaximumRetailPrice)) {
                    columnDefDC.push({ title: 'MRP', field: 'maximumRetailPrice', width: 'auto', align: 'right', type: "currency" });
                }
                if (Utility.safeBool(settings.taxInclusivePricing)) {
                    columnDefDC.push({ title: 'Net Rate', field: 'unitPriceIncludingTax', width: 'auto', align: 'right', type: "currency" });
                } else {
                    columnDefDC.push({ title: 'Rate', field: 'unitPrice', width: 'auto', align: 'right', type: "currency" });
                }
                if (hasDiscount) {
                    columnDefDC.push({ title: 'Disc.', field: 'discountPercentDisplay', width: 'auto', align: 'center' });
                }
                if (voucherFormat === Common.INVOICE_FORMAT_MODERN || voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
                    columnDefDC.push({ title: 'Net Amount', field: 'lineTotal', width: 54, align: 'right', type: "currency", bold: true });
                } else {
                    if (Utility.safeBool(settings.taxInclusivePricing)) {
                        columnDefDC.push({ title: 'Net Amount', field: 'lineTotal', width: 54, align: 'right', type: "currency", bold: true });
                    } else {
                        columnDefDC.push({ title: 'Amount', field: 'taxableAmount', width: 54, align: 'right', type: "currency", bold: true });
                    }
                }
            }
            return columnDefDC;
        default:
            var columnDef = [];
            if (settings.isPharmacy) {
                columnDef.push({ title: '#', field: 'index', width: 'auto', align: 'left' });
                if (!isUnregisteredBusiness) {
                    columnDef.push({ title: 'HSN', field: 'hsnCode', width: 'auto', align: 'center' });
                }
                columnDef.push({ title: 'PRODUCT NAME', field: 'description', secondaryField: 'orderDetailInfoText', tertiaryField: 'serialNumbers', width: '*', align: 'left', bold: true });
                if (settings.isPharmacy) {
                    columnDef.push({ title: 'PACK', field: 'quantityDescription', width: 'auto', align: 'left' });
                }
                columnDef.push({ title: 'QTY', field: 'quantity', width: 'auto', align: 'right' });
                if (Utility.safeBool(settings.allowFreeQuantity)) {
                    columnDef.push({ title: 'FREE', field: 'freeQuantity', width: 'auto', align: 'right' });
                }

                if (Utility.safeBool(settings.useBatchSerialNoTracking)) {
                    switch (Utility.safeString(settings.includeBatchDetailsInInvoice)) {
                        case "1":
                            columnDef.push({ title: 'BATCH', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'EXP', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        case "2":
                            columnDef.push({ title: 'BATCH', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'MFG', field: 'mfgDate', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'EXP', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        case "3":
                            columnDef.push({ title: 'BATCH', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'MFG', field: 'mfgDate', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'EXP', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        default:
                            break;
                    }
                }
                if (Utility.safeBool(settings.useMaximumRetailPrice)) {
                    columnDef.push({ title: 'MRP', field: 'maximumRetailPrice', width: 'auto', align: 'right', type: "currency" });
                }
                columnDef.push({ title: 'RATE', field: 'unitPrice', width: 'auto', align: 'right', type: "currency" });
                if (printPTSPTR) {
                    columnDef.push({ title: 'PTS', field: 'pts', width: 'auto', align: 'right', type: "currency" });
                    columnDef.push({ title: 'PTR', field: 'ptr', width: 'auto', align: 'right', type: "currency" });
                }
                // if (voucherFormat === Common.INVOICE_FORMAT_MODERN || voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
                //     columnDef.push({ title: 'Per', field: 'unitOfMeasurementDisplay', width: 'auto', align: 'center' });
                // } else {
                //     columnDef.push({ title: 'Per', field: 'unitOfMeasurement', width: 'auto', align: 'center' });
                // }            

                if (hasDiscount) {
                    columnDef.push({ title: 'Disc.', field: 'discountPercentDisplay', width: 'auto', align: 'center' });
                }
                if (!isCompositionScheme && !isUnregisteredBusiness) {
                    columnDef.push({ title: 'GST', field: 'taxRateDisplay', width: 'auto', align: 'center' });
                }

                if (voucherFormat === Common.INVOICE_FORMAT_MODERN || voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
                    columnDef.push({ title: 'Net Amount', field: 'lineTotal', width: 54, align: 'right', type: "currency", bold: true });
                } else {
                    columnDef.push({ title: 'AMOUNT', field: 'taxableAmount', width: 54, align: 'right', type: "currency", bold: true });
                }
            } else {
                columnDef.push({ title: '#', field: 'index', width: 'auto', align: 'left' });
                columnDef.push({ title: 'Item Description', field: 'description', secondaryField: 'orderDetailInfoText', tertiaryField: 'serialNumbers', width: '*', align: 'left', bold: true });
                if (printItemCode) {
                    columnDef.push({ title: 'Code', field: 'productCode', width: 'auto', align: 'left', bold: false });
                }
                if (settings.isPharmacy) {
                    columnDef.push({ title: 'Pack', field: 'quantityDescription', width: 'auto', align: 'left' });
                }
                if (Utility.safeBool(settings.useBatchSerialNoTracking)) {
                    switch (Utility.safeString(settings.includeBatchDetailsInInvoice)) {
                        case "1":
                            columnDef.push({ title: 'Batch', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'Exp.', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        case "2":
                            columnDef.push({ title: 'Batch', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'Mfg.', field: 'mfgDate', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'Exp.', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        case "3":
                            columnDef.push({ title: 'Batch', field: 'batchNumber', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'Mfg.', field: 'mfgDate', width: 'auto', align: 'left' });
                            columnDef.push({ title: 'Exp.', field: 'expiryDate', width: 'auto', align: 'left' });
                            break;
                        default:
                            break;
                    }
                }
                if (!isUnregisteredBusiness) {
                    columnDef.push({ title: 'HSN/SAC', field: 'hsnCode', width: 'auto', align: 'center' });
                }
                if (!isCompositionScheme && !isUnregisteredBusiness) {
                    columnDef.push({ title: 'GST', field: 'taxRateDisplay', width: 'auto', align: 'center' });
                }

                columnDef.push({ title: 'Qty', field: 'quantity', width: 'auto', align: 'right' });
                if (Utility.safeBool(settings.allowFreeQuantity)) {
                    columnDef.push({ title: 'Free Qty', field: 'freeQuantity', width: 'auto', align: 'right' });
                }

                if (Utility.safeBool(settings.useMaximumRetailPrice)) {
                    columnDef.push({ title: 'MRP', field: 'maximumRetailPrice', width: 'auto', align: 'right', type: "currency" });
                }
                if (printPTSPTR) {
                    columnDef.push({ title: 'PTS', field: 'pts', width: 'auto', align: 'right', type: "currency" });
                    columnDef.push({ title: 'PTR', field: 'ptr', width: 'auto', align: 'right', type: "currency" });
                }

                if (Utility.safeBool(settings.taxInclusivePricing)) {
                    columnDef.push({ title: 'Net Rate', field: 'unitPriceIncludingTax', width: 'auto', align: 'right', type: "currency" });
                } else {
                    columnDef.push({ title: 'Rate', field: 'unitPrice', width: 'auto', align: 'right', type: "currency" });
                }
                if (voucherFormat === Common.INVOICE_FORMAT_MODERN || voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
                    columnDef.push({ title: 'Per', field: 'unitOfMeasurementDisplay', width: 'auto', align: 'center' });
                } else {
                    columnDef.push({ title: 'Per', field: 'unitOfMeasurement', width: 'auto', align: 'center' });
                }

                if (hasDiscount) {
                    columnDef.push({ title: 'Disc.', field: 'discountPercentDisplay', width: 'auto', align: 'center' });
                }

                if (enableCess) {
                    columnDef.push({ title: 'Cess', field: 'cessPercentDisplay', width: 'auto', align: 'center' });
                }
                if (voucherFormat === Common.INVOICE_FORMAT_MODERN || voucherFormat === Common.INVOICE_FORMAT_MODERN_LOGO) {
                    columnDef.push({ title: 'Net Amount', field: 'lineTotal', width: 54, align: 'right', type: "currency", bold: true });
                } else {
                    if (Utility.safeBool(settings.taxInclusivePricing)) {
                        columnDef.push({ title: 'Net Amount', field: 'lineTotal', width: 54, align: 'right', type: "currency", bold: true });
                    } else {
                        columnDef.push({ title: 'Amount', field: 'taxableAmount', width: 54, align: 'right', type: "currency", bold: true });
                    }
                }
            }
            return columnDef;
    }
}

function getHSNSummaryTable(hsnSummary, compact = false) {
    return [
        {
            style: compact ? 'tableItemsCompact' : 'tableItemsSmall',
            table: buildTable(getHSNSummaryColumnDefinition(), hsnSummary, true, 0, '', false, compact),
            layout: {
                hLineWidth: function (i, node) {
                    if ((i <= 1 || i === node.table.body.length || i === node.table.body.length - 1)) {
                        return 0.5;
                    }
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ];
}

function getItemsTable(printData, entityType, printFormat) {
    const isCompositionScheme = printData.subscriber.taxRegistrationTypeId == Common.TAX_REG_COMPOSITION_SCHEME;
    const isUnregisteredBusiness = printData.subscriber.taxRegistrationTypeId == Common.TAX_REG_UNREGISTERED;
    const printPTSPR = Utility.safeInt(printData.party.businessTypeId) == Common.PartyBusinessTypeWholesale;
    var emptyRowsToAdd = 14;
    if (isCompositionScheme) {
        emptyRowsToAdd += 6;
    }
    var addTotalQuantity = true;
    if (entityType === Common.DELIVERY_CHALLAN_ENTITY) {
        addTotalQuantity = false;
    }
    var itemsWithDiscount = printData.voucherItems.find(i => i.discountPercent > 0);
    const hasDiscount = !Utility.isNullOrUndefined(itemsWithDiscount) ? true : false;
    var tableStyle = printData.compact ? 'tableItemsCompact' : 'tableItems';
    if (printFormat === Common.INVOICE_FORMAT_RETAIL
        || printFormat === Common.INVOICE_FORMAT_RETAIL_LOGO
        || printFormat === Common.INVOICE_FORMAT_MODERN
        || printFormat === Common.INVOICE_FORMAT_MODERN_LOGO
    ) {
        tableStyle = 'tableItemsNormal';
    }

    if (Utility.safeBool(printData.settings.useBatchSerialNoTracking)) {
        if (Utility.safeInt(printData.settings.includeBatchDetailsInInvoice) > 0) {
            emptyRowsToAdd = emptyRowsToAdd - 5;
        }
        for (let i = 0; i < printData.voucherItems.length; i++) {
            let item = printData.voucherItems[i];
            if (!Utility.isNullOrUndefined(item.batchItems) && !Utility.isEmptyObjectArray(item.batchItems) && item.batchItems.length > 0) {
                item.batchNumber = Utility.safeString(item.batchItems[0].batchNumber);
                item.mfgDate = Utility.getBatchDateDisplay(item.batchItems[0].mfgDate);
                item.expiryDate = Utility.getBatchDateDisplay(item.batchItems[0].expiryDate);
            }
        }
    }

    return [
        {
            style: tableStyle,
            table: buildTable(getItemsColumnDefinition(printData.settings, entityType, hasDiscount, isCompositionScheme, printPTSPR, isUnregisteredBusiness), printData.voucherItems, true, emptyRowsToAdd, '', addTotalQuantity, printData.compact),
            layout: {
                hLineWidth: function (i, node) {
                    if (addTotalQuantity === true && i === (printData.voucherItems.length + 1)) {
                        return 0.1;
                    }
                    if ((i <= 1 || i === node.table.body.length)) {
                        return 0.5;
                    }
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    // if (i === (printData.voucherItems.length + 1)) {
                    //     return 'gray';
                    // }
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
                hLineStyle: function (i, node) {
                    if (addTotalQuantity === true) {
                        if (i === (printData.voucherItems.length + 1)) {
                            return { dash: { length: 2, space: 2 } };
                        }
                    }
                    return null;
                },
                paddingBottom: (rowIndex, node, colIndex) => {
                    const DEFAULT_PADDING = 2;
                    // Calculate padding for the last element of the table.
                    if (rowIndex === node.table.body.length - 1) {
                        const currentPosition = node.positions[node.positions.length - 1];
                        const currentHeight = currentPosition.top;
                        let paddingBottom = DEFAULT_PADDING;
                        if (currentHeight < (currentPosition.pageInnerHeight * 0.56)) {
                            paddingBottom = (currentPosition.pageInnerHeight * 0.56) - currentHeight;
                        }
                        return paddingBottom;
                    } else {
                        return DEFAULT_PADDING;
                    }
                }
            }
        }
    ]

}

function getDeliveryChallanSummary(printData) {
    var totalQuantity = 0;
    printData.voucherItems.map(item => {
        totalQuantity += item.quantity;
    });
    return [
        {
            style: 'tableItems',
            table: buildTable(
                [
                    { title: 'Col1', field: 'field1', width: '*', align: 'right', bold: true, fontSize: 12 },
                    { title: 'Col2', field: 'field2', width: 50.5, align: 'right', bold: true, fontSize: 12 },
                    { title: 'Col3', field: 'field3', width: 30.5, align: 'center' },
                ],
                [
                    { field1: 'Total Quantity', field2: totalQuantity, field3: '' },
                ], false, 0),
            layout: {
                hLineWidth: function (i, node) {
                    return (i <= 1 || i === node.table.body.length) ? 0.1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0.1;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ]
}

function getVoucherSummaryTable(printData, entityType, printFormat, isCompositionScheme) {
    var voucherData = printData.voucherData;
    var paymentInfo = printData.paymentInfo;
    var voucherItems = printData.voucherItems;
    const orderAdjustments = voucherData?.orderAdjustments;


    var taxSummary = getTaxSummary(voucherData, voucherItems);
    var totalAmountDue = printData.totalPaymentDueAmount;
    let settings = printData.settings;
    var itemsSummaryArray = [];
    let hideTaxSummary = (entityType == 'Quotation' && !Utility.safeBool(settings.includeTaxSummaryQuotation));
    if (printData.subscriber.taxRegistrationTypeId == Common.TAX_REG_UNREGISTERED) {
        hideTaxSummary = true;
    }
    if (!isCompositionScheme) {
        if (!hideTaxSummary) {
            itemsSummaryArray.push({ description: 'TOTAL GST', amount: voucherData.taxAmount });
            if (Utility.safeBool(settings.enableCess)) {
                itemsSummaryArray.push({ description: 'TOTAL CESS', amount: voucherData.cessAmount });
            }
        }
    }
    if (entityType == Common.DELIVERY_CHALLAN_ENTITY && !Utility.safeBool(settings.includeTaxSummaryDeliveryChallan)) {
        hideTaxSummary = true;
    }
    if (!isEmptyArray(orderAdjustments) && !Utility.isEmptyObjectArray(orderAdjustments)) {
        for (let i = 0; i < orderAdjustments.length; i++) {
            let orderAdjustmentItem = orderAdjustments?.[i];
            if (orderAdjustmentItem?.adjustmentType === 'TDS' || orderAdjustmentItem?.adjustmentType === 'TCS') {
                itemsSummaryArray.push({ description: `${Utility.safeString(orderAdjustmentItem?.adjustmentText)}%`, amount: Utility.safeAmount(orderAdjustmentItem?.adjustmentAmount) });
            } else {
                itemsSummaryArray.push({ description: `${Utility.safeString(orderAdjustmentItem?.adjustmentText)}`, amount: Utility.safeAmount(orderAdjustmentItem?.adjustmentAmount) });
            }
        }
    }

    if (Utility.safeFloat(voucherData.roundOffAmount) !== 0) {
        itemsSummaryArray.push({ description: 'Round off', amount: voucherData.roundOffAmount });
    }
    if (Utility.safeFloat(voucherData.orderDiscountAmount) > 0) {
        itemsSummaryArray.push({ description: 'Discount', amount: (voucherData.orderDiscountAmount * -1) });
    }
    itemsSummaryArray.push({ description: 'GRAND TOTAL', amount: voucherData.grandTotal });

    if (!Utility.isNullOrUndefined(paymentInfo) && Utility.safeBool(settings.printPaymentInfo)) {
        itemsSummaryArray.push({ description: 'Received', amount: Utility.safeFloat(paymentInfo.amountReceived).toFixed(2) });
        itemsSummaryArray.push({ description: 'Due', amount: Utility.safeFloat(paymentInfo.amountDue).toFixed(2) });
    }

    if (!Utility.isNullOrUndefined(totalAmountDue) && Utility.safeBool(settings.printTotalPendingAmount)) {
        itemsSummaryArray.push({ description: 'Total Outstanding', amount: Utility.safeFloat(totalAmountDue).toFixed(2) });
    }

    var tableStyle = printData.compact ? 'tableItemsCompact' : 'tableItems';
    if (printFormat === Common.INVOICE_FORMAT_RETAIL
        || printFormat === Common.INVOICE_FORMAT_RETAIL_LOGO
        || printFormat === Common.INVOICE_FORMAT_MODERN
        || printFormat === Common.INVOICE_FORMAT_MODERN_LOGO
    ) {
        tableStyle = 'tableItemsComfort';
    }

    var voucherSummaryBody = [];

    if (!isCompositionScheme) {
        if (!hideTaxSummary) {
            voucherSummaryBody.push({
                style: 'tableNested',
                table: buildTable([
                    { title: 'GST Rate', field: 'taxRateDisplay', width: 'auto', align: 'center' },
                    { title: 'Taxable Value', field: 'taxableAmount', width: 'auto', align: 'right', type: 'currency' },
                    { title: 'SGST', field: 'stateTaxAmount', width: '*', align: 'right', type: 'currency' },
                    { title: 'CGST', field: 'centralTaxAmount', width: '*', align: 'right', type: 'currency' },
                    { title: 'IGST', field: 'interStateTaxAmount', width: '*', align: 'right', type: 'currency' },
                ], taxSummary, true, 0),
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.1;
                        // return (i <= 1 || i === node.table.body.length) ? 0.1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0.1;
                    },
                    hLineColor: function (i, node) {
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    // paddingTop: function (i, node) {  return i === 0 ?  2 : 1 },
                    // paddingBottom: function (i, node) { return 0 }
                }
            });
            voucherSummaryBody.push([
                {
                    style: 'tableNested',
                    table: buildTable([
                        { title: 'Total Taxable Value:', field: 'taxRateDesc', width: '*', align: 'right' },
                        { title: Utility.safeFloat(voucherData.orderTotal).toFixed(2), field: 'totalTax', width: 54, align: 'right', type: 'currency' },
                    ], taxSummary, true, 0),
                    layout: {
                        hLineWidth: function (i, node) {
                            // return (i <= 1 || i === node.table.body.length) ? 0.1 : 0;
                            return 0;
                        },
                        vLineWidth: function (i, node) {
                            return 0.1;
                        },
                        hLineColor: function (i, node) {
                            return 'black';
                        },
                        vLineColor: function (i, node) {
                            return 'black';
                        },
                        // paddingTop: function (i, node) {  return i === 0 ?  2 : 1 },
                        // paddingBottom: function (i, node) { return 0 }
                    }
                }
            ]);
        }
    }

    var voucherSummary = [
        {
            style: printData.compact ? 'tableItemsCompact' : 'tableItems',
            table: {
                widths: [351, '*'],
                body: [voucherSummaryBody]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i <= 1 || i === node.table.body.length) ? 0.1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0.1;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; },
            }
        },
        {
            style: printData.compact ? 'tableItemsCompact' : 'tableItems',
            table: buildTable(
                [
                    { title: 'description', field: 'description', width: '*', align: 'right', bold: true },
                    { title: 'amount', field: 'amount', width: 54, align: 'right', type: "currency", bold: true },
                ],
                itemsSummaryArray, false, 0),
            layout: {
                hLineWidth: function (i, node) {
                    return (i <= 3 || i === node.table.body.length || i === node.table.body.length - 1) ? 0.1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0.1;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        },
        {
            style: Utility.safeBool(printData.compact) ? 'tableItemsCompact' : 'tableItemsLarge',
            table: buildTable(
                [
                    { title: 'Amount chargeable  (in words)', field: 'col1', width: '*', align: 'left', fontSize: printData.compact ? 8 : 10 },
                    { title: 'E. & 0.E', field: 'col2', width: '*', align: 'right', fontSize: printData.compact ? 8 : 10 },
                ],
                [
                    { col1: 'Amount chargeable  (in words)', col2: 'E. & 0.E', fontSize: printData.compact ? 8 : 10 },
                ], false, 0),
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 ? 0.25 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                paddingTop: function (i, node) { return 0 },
                paddingBottom: function (i, node) { return 0 },
            }
        },
        {
            style: Utility.safeBool(printData.compact) ? 'tableItemsCompact' : 'tableItemsLarge',
            table: buildTable(
                [
                    { title: 'desc1', field: 'col1', width: '*', align: 'left', bold: true, fontSize: printData.compact ? 8 : 10 },

                ],
                [
                    { col1: Utility.getAmountInWords(voucherData.grandTotal, true) }
                ], false, 0),
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
            }
        },
    ];

    return voucherSummary;
}



export const getEntityFields = (customFields, entityName) => {
    let fieldDefinition = null;
    let entityFields = {
        hasCustomFields: false,
        customFields: []
    };
    let entityCustomFields = null;
    if (!Utility.isEmptyObjectArray(customFields)) {
        switch (entityName) {
            case Common.INVOICE_ENTITY:
                entityCustomFields = customFields.find(x => x.templateTypeId === 2002);
                break;
            case Common.PURCHASE_BILL_ENTITY:
                entityCustomFields = customFields.find(x => x.templateTypeId === 2006);
                break;
            case Common.PURCHASE_ORDER_ENTITY:
                entityCustomFields = customFields.find(x => x.templateTypeId === 2007);
                break;
            case Common.QUOTATION_ENTITY:
                entityCustomFields = customFields.find(x => x.templateTypeId === 2003);
                break;
            case Common.DELIVERY_CHALLAN_ENTITY:
                entityCustomFields = customFields.find(x => x.templateTypeId === 2004);
                break;
            default:
                break;
        }
        if (!Utility.isNullOrEmpty(entityCustomFields?.templateText)) {
            if (!Utility.isNullOrEmpty(entityCustomFields?.templateText)) {
                try {
                    fieldDefinition = JSON.parse(entityCustomFields?.templateText);
                    if (!Utility.isNullOrUndefined(fieldDefinition)) {
                        if (!Utility.isEmptyObjectArray(fieldDefinition?.customFields)) {
                            entityFields.customFields = fieldDefinition?.customFields;
                            if (!Utility.isNullOrEmpty(entityFields.customFields)) {
                                entityFields.customFields.sort((a, b) => a.sequence - b.sequence);
                            }
                            let customFieldsDefinitionArr = fieldDefinition?.customFields.filter(f => Utility.safeBool(f.isStandard) === false);
                            if (!Utility.isEmptyObjectArray(customFieldsDefinitionArr) && customFieldsDefinitionArr.length > 0) {
                                entityFields.hasCustomFields = true;
                            }
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }
    return entityFields;
}


function mapCustomFields(fieldDefinition, voucherData, additionalDetails, entityDetails) {
    let rowArray = [];
    let customFieldVal = '';
    for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
        const customField = fieldDefinition?.customFields[i];
        if (Utility.safeBool(customField?.print)) {
            if (Utility.safeBool(customField?.isStandard)) {
                customFieldVal = Utility.safeString(voucherData[customField?.name]);
            } else {
                if (!Utility.isNullOrUndefined(additionalDetails)) {
                    customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                } else {
                    customFieldVal = '';
                }
            }
            if (customField?.type === 'DateTime') {
                customFieldVal = Utility.getLocaleDateTime(customFieldVal);
            } else if (customField?.type === 'Date') {
                customFieldVal = Utility.getLocaleDate2(customFieldVal);
            } else if (customField?.type === 'Currency') {
                customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
            }
            if (Utility.safeBool(customField.fullWidth)) {
                entityDetails.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
            } else {
                rowArray.push({ text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 });
                if (rowArray.length === 2) {
                    entityDetails.push(rowArray);
                    rowArray = [];
                } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                    // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                    rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                    entityDetails.push(rowArray);
                    rowArray = [];
                }
            }
        }
        // At the end also check if any non-fullwidth fields were left out
        if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
            rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
            entityDetails.push(rowArray);
            rowArray = [];
        }
    }
}

function getEntityDetails(voucherData, entityType, voucherFormat, customFields = null, printData) {
    let entityDetails = null;
    let fieldDefinition = getEntityFields(customFields, entityType);
    let additionalDetails = null;
    additionalDetails = getAdditionalDetails(voucherData);

    switch (entityType) {
        case Common.PURCHASE_ORDER_ENTITY:
            /* if (fieldDefinition?.hasCustomFields) { */
            entityDetails = [];
            entityDetails.push([
                { text: ['P.O. #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                { text: ['P.O. date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
            ]);
            mapCustomFields(fieldDefinition, voucherData, additionalDetails, entityDetails);
            /*  } */
            break;
        case Common.PURCHASE_BILL_ENTITY:
            entityDetails = [];
            entityDetails.push([
                { text: ['Bill No: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                { text: ['Bill date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
            ]);
            mapCustomFields(fieldDefinition, voucherData, additionalDetails, entityDetails);
            break;
        case Common.DEBIT_NOTE_ENTITY:
            entityDetails = [
                [
                    { text: ['Debit Note #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                    { text: ['Debit Note date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }] },
                ],
                [{ colSpan: 2, text: ['Vendor`s Ref.: ', { text: Utility.safeString(voucherData.originalOrderNumber) + ', dated: ' + Utility.getLocaleDate(voucherData.originalOrderDate), bold: true, fontSize: 10 }], fontSize: 10 }],
                [{ colSpan: 2, text: ['Reason: ', { text: voucherData.reasonForIssue, bold: true, fontSize: 10 }], fontSize: 10 }],
                [{ colSpan: 2, text: ['Notes: ', { text: voucherData.notes, bold: true, fontSize: 10 }], fontSize: 10 }],
            ];
            break;
        case Common.CREDIT_NOTE_ENTITY:
            entityDetails = [
                [
                    { text: ['Credit Note #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                    { text: ['Credit Note date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }] },
                ],
                [{ colSpan: 2, text: ['Buyer`s Ref.: ', { text: Utility.safeString(voucherData.originalOrderNumber) + ', dated: ' + Utility.getLocaleDate(voucherData.originalOrderDate), bold: true, fontSize: 10 }], fontSize: 10 }],
                [{ colSpan: 2, text: ['Reason: ', { text: voucherData.reasonForIssue, bold: true, fontSize: 10 }], fontSize: 10 }],
                [{ colSpan: 2, text: ['Notes: ', { text: voucherData.notes, bold: true, fontSize: 10 }], fontSize: 10 }],
            ];
            break;
        case Common.QUOTATION_ENTITY:
            entityDetails = [];
            entityDetails.push([
                { text: [printData.heading === "Proforma Invoice" ? "P.I:" : 'Quotation #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                { text: [printData.heading === "Proforma Invoice" ? "P.I date:" : 'Quotation date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
            ]);
            mapCustomFields(fieldDefinition, voucherData, additionalDetails, entityDetails);
            break;
        case Common.DELIVERY_CHALLAN_ENTITY:
            entityDetails = [];
            entityDetails.push([
                { text: ['D.C. #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                { text: ['D.C. date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
            ]);
            mapCustomFields(fieldDefinition, voucherData, additionalDetails, entityDetails);
            break;
        case Common.INVOICE_ENTITY:
            switch (voucherFormat) {
                case Common.INVOICE_FORMAT_DEFAULT_LOGO:
                case Common.INVOICE_FORMAT_DEFAULT:
                    if (fieldDefinition?.hasCustomFields) {
                        let customFieldVal = '';
                        entityDetails = [];
                        entityDetails.push([
                            { text: ['Invoice #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                            { text: ['Invoice date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
                        ]);
                        let rowArray = [];
                        for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                            const customField = fieldDefinition?.customFields[i];
                            if (Utility.safeBool(customField?.print)) {
                                if (Utility.safeBool(customField?.isStandard)) {
                                    customFieldVal = Utility.safeString(voucherData[customField?.name]);
                                } else {
                                    if (!Utility.isNullOrUndefined(additionalDetails)) {
                                        customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                                    } else {
                                        customFieldVal = '';
                                    }
                                }
                                if (customField?.type === 'DateTime') {
                                    customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                                } else if (customField?.type === 'Date') {
                                    customFieldVal = Utility.getLocaleDate2(customFieldVal);
                                } else if (customField?.type === 'Currency') {
                                    customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                                }
                                if (Utility.safeBool(customField.fullWidth)) {
                                    entityDetails.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
                                } else {
                                    rowArray.push({ text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 });
                                    if (rowArray.length === 2) {
                                        entityDetails.push(rowArray);
                                        rowArray = [];
                                    } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                        // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                                        rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                        entityDetails.push(rowArray);
                                        rowArray = [];
                                    }
                                }
                            }
                            // At the end also check if any non-fullwidth fields were left out
                            if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                entityDetails.push(rowArray);
                                rowArray = [];
                            }
                        }
                    } else {
                        entityDetails = [
                            [
                                { text: ['Invoice #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['Invoice date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [
                                { text: ['P.O #: ', { text: voucherData.poReference, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['P.O date: ', { text: Utility.getLocaleDate(voucherData.poDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [{ colSpan: 2, text: ['Reference: ', { text: voucherData.orderReference, bold: true, fontSize: 10 }], fontSize: 10 }],
                            [{ colSpan: 2, text: ['Transport: ', { text: voucherData.transportDetails, bold: true, fontSize: 10 }], fontSize: 10 }],
                            [
                                { text: ['E-Way bill: ', { text: voucherData.eWayBillNo, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['Delivery date: ', { text: Utility.getLocaleDate(voucherData.deliveryDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [{ colSpan: 2, text: ['Notes/Terms: ', { text: voucherData.termsAndConditions, bold: true, fontSize: 10 }], fontSize: 10 }],
                        ];
                    }
                    break;
                default:
                    if (fieldDefinition?.hasCustomFields) {
                        let customFieldVal = '';
                        entityDetails = [];
                        entityDetails.push([
                            { text: ['Invoice #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                            { text: ['Invoice date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
                        ]);
                        let rowArray = [];
                        for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                            const customField = fieldDefinition?.customFields[i];
                            if (Utility.safeBool(customField?.print)) {
                                if (Utility.safeBool(customField?.isStandard)) {
                                    customFieldVal = Utility.safeString(voucherData[customField?.name]);
                                } else {
                                    if (!Utility.isNullOrUndefined(additionalDetails)) {
                                        customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                                    } else {
                                        customFieldVal = '';
                                    }
                                }
                                if (customField?.type === 'DateTime') {
                                    customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                                } else if (customField?.type === 'Date') {
                                    customFieldVal = Utility.getLocaleDate2(customFieldVal);
                                } else if (customField?.type === 'Currency') {
                                    customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                                }
                                if (Utility.safeBool(customField.fullWidth)) {
                                    entityDetails.push([{ colSpan: 2, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }]);
                                } else {
                                    rowArray.push({ text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 });
                                    if (rowArray.length === 2) {
                                        entityDetails.push(rowArray);
                                        rowArray = [];
                                    } else if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                        // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                                        rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                        entityDetails.push(rowArray);
                                        rowArray = [];
                                    }
                                }
                            }
                            // At the end also check if any non-fullwidth fields were left out
                            if (rowArray.length === 1 && i === (fieldDefinition?.customFields.length - 1)) {
                                rowArray.push({ text: ['', { text: '', bold: true, fontSize: 10 }], fontSize: 10 });
                                entityDetails.push(rowArray);
                                rowArray = [];
                            }
                        }
                    } else {
                        entityDetails = [
                            [
                                { text: ['Invoice #: ', { text: voucherData.orderNumber, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['Invoice date: ', { text: Utility.getLocaleDate(voucherData.orderDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [
                                { text: ['P.O #: ', { text: voucherData.poReference, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['P.O date: ', { text: Utility.getLocaleDate(voucherData.poDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [{ colSpan: 2, text: ['Reference: ', { text: voucherData.orderReference, bold: true, fontSize: 10 }], fontSize: 10 }],
                            [
                                { text: ['E-Way bill: ', { text: voucherData.eWayBillNo, bold: true, fontSize: 10 }], fontSize: 10 },
                                { text: ['Delivery date: ', { text: Utility.getLocaleDate(voucherData.deliveryDate), bold: true, fontSize: 10 }], fontSize: 10 },
                            ],
                            [{ colSpan: 2, text: ['Transport: ', { text: voucherData.transportDetails, bold: true, fontSize: 10 }], fontSize: 10 }],
                            [{ colSpan: 2, text: ['Notes/Terms: ', { text: voucherData.termsAndConditions, bold: true, fontSize: 10 }], fontSize: 10 }],
                        ];
                    }
                    break;
            }
        default:
            break;
    }
    return entityDetails;

}

function getGreetingMessage(settings, entityType) {
    // settings.quotationGreetingMessage
    switch (entityType) {
        case Common.QUOTATION_ENTITY:
            if (!!settings.quotationGreetingMessage && settings.quotationGreetingMessage.trim().length > 0) {
                return settings.quotationGreetingMessage;
            } else {
                return " ";
            }
        case Common.INVOICE_ENTITY:
            if (!!settings.invoiceGreetingMessage && settings.invoiceGreetingMessage.trim().length > 0) {
                return settings.invoiceGreetingMessage;
            } else {
                return " ";
            }

        case Common.PURCHASE_ORDER_ENTITY:
            if (!!settings.purchaseOrderGreetingMessage && settings.purchaseOrderGreetingMessage.trim().length > 0) {
                return settings.purchaseOrderGreetingMessage;
            } else {
                return " ";
            }
        case Common.CREDIT_NOTE_ENTITY:
            if (!!settings.creditNoteGreetingMessage && settings.creditNoteGreetingMessage.trim().length > 0) {
                return settings.creditNoteGreetingMessage;
            } else {
                return " ";
            }
        case Common.DEBIT_NOTE_ENTITY:
            if (!!settings.debitNoteGreetingMessage && settings.debitNoteGreetingMessage.trim().length > 0) {
                return settings.debitNoteGreetingMessage;
            } else {
                return " ";
            }

        case Common.DELIVERY_CHALLAN_ENTITY:
            if (!!settings.deliveryChallanGreetingMessage && settings.deliveryChallanGreetingMessage.trim().length > 0) {
                return settings.deliveryChallanGreetingMessage;
            } else {
                return " ";
            }
        default:
            return " ";
    }
}


function getTermsAndConditions(settings, entityType) {
    switch (entityType) {
        case Common.QUOTATION_ENTITY:
            return "Terms & Conditions: \n" + settings.quotationTermsAndConditions;
        case Common.INVOICE_ENTITY:
            return "Terms & Conditions: \n" + settings.invoiceTermsAndConditions;
        case Common.PURCHASE_ORDER_ENTITY:
            return "Terms & Conditions: \n" + settings.purchaseOrderTermsAndConditions;
        case Common.PURCHASE_BILL_ENTITY:
            return "Terms & Conditions: \n" + settings.purchaseBillTermsAndConditions;
        case Common.CREDIT_NOTE_ENTITY:
            return "Terms & Conditions: \n" + settings.creditNoteTermsAndConditions;
        case Common.DEBIT_NOTE_ENTITY:
            return "Terms & Conditions: \n" + settings.debitNoteTermsAndConditions;
        case Common.DELIVERY_CHALLAN_ENTITY:
            return "Terms & Conditions: \n" + settings.deliveryChallanTermsAndConditions;
        default:
            return "";
    }
}

export function getPAN(gstin) {
    if (!Utility.isNullOrUndefined(gstin) && gstin.length > 12) {
        return gstin.substring(2, 12).toUpperCase();
    }

    return "";
}

function addBankTermsAndConditions(subscriber, bankDetails, settings, entityType, compact = false) {
    const drugLicenseNo = getDrugLicenseValue(subscriber);
    var fieldData = ''
    if (!Utility.isNullOrEmpty(drugLicenseNo)) {
        fieldData += "D.L. No.: " + drugLicenseNo;
        fieldData += "\n";
    }
    if (entityType !== 'PurchaseOrder') {
        fieldData += "Company's PAN: " + getPAN(subscriber.taxIdNumber);
        if (!Utility.isNullOrUndefined(bankDetails)) {
            fieldData += "\n A/c Name: " + Utility.safeString(bankDetails.accountName);
            fieldData += "\n Bank Name: " + Utility.safeString(bankDetails.bankName);
            fieldData += "\n A/c No.: " + Utility.safeString(bankDetails.accountNumber);
            fieldData += "\n Branch: " + Utility.safeString(bankDetails.branchName);
            fieldData += "\n IFSC: " + Utility.safeString(bankDetails.ifscCode);
        }
    }

    if (entityType === Common.DELIVERY_CHALLAN_ENTITY
        || entityType === Common.CREDIT_NOTE_ENTITY
        || entityType === Common.DEBIT_NOTE_ENTITY) {
        return null;
    } else {
        return {
            style: 'footerTableTerms',
            table: {
                widths: ['*', 'auto', 'auto'],
                body: [
                    [
                        {
                            style: compact ? 'tableItemsBankAndTerms' : 'tableItemsBankAndTerms',
                            table: buildTable([
                                { title: 'Terms & Conditions:', field: 'field1', width: 'auto', align: 'left', fontSize: compact ? 8 : 9 }
                            ], [
                                {
                                    field1: getTermsAndConditions(settings, entityType)
                                }
                            ], false, 0),
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0;
                                },
                                vLineWidth: function (i, node) {
                                    return 0;
                                },
                                hLineColor: function (i, node) {
                                    return 'black';
                                },
                                vLineColor: function (i, node) {
                                    return 'black';
                                },
                            }
                        },
                        {
                            style: 'tableItemsBankAndTerms',
                            table: buildTable([
                                { title: Utility.isNullOrUndefined(bankDetails) ? '' : 'Bank details:', field: 'field1', width: 'auto', align: 'right', fontSize: compact ? 8 : 9 },
                            ], [
                                {
                                    field1: fieldData,
                                },
                            ], false, 0),
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0;
                                },
                                vLineWidth: function (i, node) {
                                    return 0;
                                },
                                hLineColor: function (i, node) {
                                    return 'black';
                                },
                                vLineColor: function (i, node) {
                                    return 'black';
                                },
                            }
                        },
                        {
                            stack: getQRCode(bankDetails, settings)
                        }
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    }

}

function addCustomerSignatureFooter(subscriber, entityType, settings, compact = false) {
    switch (entityType) {
        case Common.DELIVERY_CHALLAN_ENTITY:
            return {
                style: compact ? 'footerTableCompact' : 'footerTable',
                table: buildTable([
                    { title: 'Col1', field: 'field1', width: '*', align: 'left' },
                    { title: 'Col2', field: 'field2', width: '*', align: 'center', bold: true }
                ], [
                    {
                        field1: "Received by:",
                        field2: "for " + subscriber.legalName
                    },
                    {
                        field1: "\n",
                        field2: ''
                    },
                    {
                        field1: Utility.safeBool(settings?.hideSealAndSignature) ? "" : "Customer's seal & signature:",
                        field2: ""
                    },
                    {
                        field1: "\n",
                        field2: ''
                    },
                    {
                        field1: "",
                        field2: 'Authorized signature'
                    },
                ], false, 3),
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 0.1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0.1;
                    },
                    hLineColor: function (i, node) {
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                }
            }

        default:
            return {
                style: compact ? 'footerTableCompact' : 'footerTable',
                table: buildTable([
                    { title: 'Col1', field: 'field1', width: '*', align: 'left' },
                    { title: 'Col2', field: 'field2', width: '*', align: 'center', bold: true, fontSize: 8 }
                ], [
                    {
                        field1: Utility.safeBool(settings?.hideSealAndSignature) ? "" : "Customer's seal & signature:",
                        field2: "for " + subscriber.legalName,
                    },
                    {
                        field1: "",
                        field2: "\n",
                    },
                    {
                        field1: "",
                        field2: "\n",
                    },
                    {
                        field1: "",
                        field2: "\n",
                    },
                    {
                        field1: "",
                        field2: "Authorized signature"
                    },
                ], false, 3),
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 0.1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0.1;
                    },
                    hLineColor: function (i, node) {
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                }
            }
    }

}

function getPartyType(entityType) {
    if (entityType === Common.DEBIT_NOTE_ENTITY || entityType === Common.PURCHASE_ORDER_ENTITY) {
        return "Vendor";
    }
    if (entityType === Common.PURCHASE_BILL_ENTITY) {
        return "Bill From"
    }
    return "Bill To";
}

export function getPartyName(party, entityType) {
    if (entityType === Common.DEBIT_NOTE_ENTITY || entityType === Common.PURCHASE_ORDER_ENTITY || entityType === Common.SUPPLIER_ENTITY || entityType === Common.PURCHASE_BILL_ENTITY) {
        return party.name;
    }
    return party.legalName;
}

//#region Customer or Supplier statement related
export function addStatementHeader(printData, reportName) {
    var subscriber = printData.subscriber;
    var subscriberAddress = printData.subscriber.addressList[0];
    switch (reportName) {
        case Common.REPORT_PENDING_STATEMENT_PRINT:
        case Common.REPORT_CUSTOMER_GROUP_STATEMENT_PRINT:
            return {
                stack: [
                    { text: subscriber.legalName, alignment: 'center', style: 'header', margin: [0, 20, 0, 0] },
                    { text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
                    { text: 'Tel.: ' + subscriberAddress.contactPhone + ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
                ],
            }
        case Common.REPORT_STATEMENT_PRINT:
            return {
                stack: [
                    { text: subscriber.legalName, alignment: 'center', style: 'header', margin: [0, 20, 0, 0] },
                    { text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
                    { text: 'Tel.: ' + subscriberAddress.contactPhone + ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
                    { text: 'Statement period: From ' + Utility.getLocaleDate(printData.fromDate) + ' To ' + Utility.getLocaleDate(printData.toDate), bold: true, alignment: 'left', style: 'normal', margin: [25, 14, 0, 0] },
                    /* { text: getPartyName(printData.party, printData.referenceType), bold: true, alignment: 'right', style: 'normal', margin: [0, -14, 25, 0] }, */
                ],
            }
        case Common.REPORT_ORDERS_PRINT:
            return {
                stack: [
                    { text: subscriber.legalName, alignment: 'center', style: 'header', margin: [0, 20, 0, 0] },
                    { text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
                    { text: 'Tel.: ' + subscriberAddress.contactPhone + ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
                    { text: 'List Filter: From ' + Utility.getLocaleDate(printData.fromDate) + ' To ' + Utility.getLocaleDate(printData.toDate), bold: true, alignment: 'left', style: 'normal', margin: [25, 14, 0, 0] },
                    /* { text: getPartyName(printData.party, printData.referenceType), bold: true, alignment: 'right', style: 'normal', margin: [0, -14, 25, 0] }, */
                ],
            }
        case Common.REPORT_PAYMENT_RECEIPT_PRINT:
        case Common.REPORT_EXPENSE_RECEIPT_PRINT:
            return {
                stack: [
                    { text: 'CASH/PAYMENT RECEIPT', alignment: 'center', style: 'header', bold: true, fontSize: 14, margin: [0, 40, 0, 0], decoration: 'underline' },
                    { text: subscriber.legalName, alignment: 'center', style: 'header', margin: [0, 20, 0, 0] },
                    { text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
                    { text: 'Tel.: ' + subscriberAddress.contactPhone + ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
                ],
            }
        default:
            return {
                stack: [
                    { text: subscriber.legalName, alignment: 'center', style: 'header', margin: [0, 20, 0, 0] },
                    { text: subscriberAddress.addressLine1 + ", " + subscriberAddress.addressLine2 + ' ' + subscriberAddress.city + ' ' + getStateName(subscriberAddress.stateId, printData.stateList) + ' ' + subscriberAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
                    { text: 'Tel.: ' + subscriberAddress.contactPhone + ' Email: ' + subscriberAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
                    { text: 'Statement period: From ' + Utility.getLocaleDate(printData.fromDate) + ' To ' + Utility.getLocaleDate(printData.toDate), bold: true, alignment: 'left', style: 'normal', margin: [25, 14, 0, 0] },
                    { text: getPartyName(printData.party, printData.referenceType), bold: true, alignment: 'right', style: 'normal', margin: [0, -14, 25, 0] },
                ],
            }
    }

}

export function addStatementBody(printData) {
    var body = [];
    const partyAddress = printData?.party?.addressList;
    const billingAddresses = partyAddress.filter(address => address.addressType.toLowerCase() === 'billing');
    const displayName = (printData?.referenceType === "Supplier") ? printData?.party?.name : printData?.party?.legalName || "";

    const customerAddress = [
        { text: displayName || "", bold: true, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
        { text: billingAddresses?.[0]?.addressLine1 || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
        { text: billingAddresses?.[0]?.addressLine2 || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
        { text: billingAddresses?.[0]?.contactEmail || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
        { text: billingAddresses?.[0]?.contactPhone || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] }
    ];

    const summary = [
        { text: 'Opening Balance:  ' + Utility.formatToCurency(printData.reportData.balance.openingBalance), bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0] },
        { text: 'Total Credits:  ' + Utility.formatToCurency(printData.reportData.summary.creditTotals), bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0] },
        { text: 'Total Debits:  ' + Utility.formatToCurency(printData.reportData.summary.debitTotals), bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0] },
        { text: 'Closing Balance:  ' + Utility.formatToCurency(printData.reportData.balance.closingBalance), bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 10] },
    ];

    const customerTable = {
        table: {
            widths: ['70%', '30%'],
            body: [
                [
                    { stack: customerAddress },
                    { stack: summary, alignment: 'right' },
                ],
            ],
        },
        layout: 'noBorders',
    };

    body.push(customerTable);


    const transactionsColumnDef = [
        { title: 'Date', field: 'orderDate', width: '11%', align: 'left', type: 'date' },
        { title: 'Voucher Type', field: 'voucherType', width: '10%', align: 'left' },
        { title: 'Voucher No.', field: 'orderNumber', width: '10%', align: 'left' },
        { title: 'Particulars', field: 'narrative', width: '31%', align: 'left' },
        { title: 'Dr. Amount', field: 'debitAmount', width: '12%', align: 'right', type: "currency", blankIfZero: true },
        { title: 'Cr. Amount', field: 'creditAmount', width: '12%', align: 'right', type: "currency", blankIfZero: true },
        { title: 'Balance', field: 'runningBalance', width: '14%', align: 'right', type: "currency", blankIfZero: true },
    ];

    var transactions = [
        {
            style: 'nestedTableCompact',
            table: buildTable(transactionsColumnDef, printData.statementData, true, 0, '', false),
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? 'white' : '#E9E9E9';
                },
                hLineWidth: function (i, node) {
                    if ((i <= 1 || i === node.table.body.length || i === node.table.body.length - 1 || i === node.table.body.length - 2)) {
                        return 1;
                    }
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ];

    body.push(transactions);
    return body;
}


export function addOrderStatementBody(printData, entityName) {
    var body = [];
    const statementData = printData.statementData;

    var customerAddress = [];
    if (!isEmptyArray(printData?.party) && !Utility.isNullOrUndefined(printData?.party)) {
        const partyAddress = printData?.party?.addressList;
        const billingAddresses = partyAddress.filter(address => address.addressType.toLowerCase() === 'billing');
        const displayName = (printData?.referenceType === "Supplier") ? `Party : ${printData?.party?.name}` : ` Party : ${printData?.party?.legalName}` || "";

        customerAddress = [
            { text: displayName || "", bold: true, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
            { text: billingAddresses?.[0]?.addressLine1 || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
            { text: billingAddresses?.[0]?.addressLine2 || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
            { text: billingAddresses?.[0]?.contactEmail || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] },
            { text: billingAddresses?.[0]?.contactPhone || "", bold: false, alignment: 'left', style: 'normal', margin: [4, 0, 0, 0] }
        ];
    } else {
        customerAddress = [];
    }


    if (!isEmptyArray(statementData) && !Utility.isNullOrUndefined(statementData)) {
        var summary = statementData?.reduce((acc, curr) => {
            acc.invoiceTotal += curr.grandTotal;
            acc.receivedTotal += curr.amountReceived;
            acc.returnsTotal += curr.returnsAmount;
            acc.totalDue += curr.amountDue;
            return acc;
        }, {
            invoiceTotal: 0,
            receivedTotal: 0,
            returnsTotal: 0,
            totalDue: 0
        });
    }

    const summaryArray = [
        { text: `${Utility.capitalizeFirstLetter(entityName)} Total: ${Utility.formatToCurency(summary.invoiceTotal)}`, bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0], color: 'blue' },
        { text: `Received Total: ${Utility.formatToCurency(summary.receivedTotal)}`, bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0], color: 'green' },
        { text: `Returns Total: ${Utility.formatToCurency(summary.returnsTotal)}`, bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 0], color: 'orange' },
        { text: `Total Due: ${Utility.formatToCurency(summary.totalDue)}`, bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 5, 10], color: 'red' }
    ];


    const customerTable = {
        table: {
            widths: ['70%', '30%'],
            body: [
                [
                    { stack: customerAddress },
                    { stack: summaryArray, alignment: 'right' },
                ],
            ],
        },
        layout: 'noBorders',
    };

    body.push(customerTable);

    const getTransactionsColumnDef = () => {
        switch (entityName) {
            case Common.INVOICE_ENTITY:
                return [
                    { title: 'Customer/Party', field: 'referenceName', width: '*', align: 'left' },
                    { title: 'GSTIN', field: 'gstin', width: 'auto', align: 'left' },
                    { title: 'Invoice #', field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'Invoice Total', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Received', field: 'amountReceived', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Returns', field: 'returnsAmount', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Due Amount', field: 'amountDue', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Credit?', field: 'isCredit', width: 'auto', align: 'center' },
                    { title: 'IRN Status', field: 'irnStatus', width: 'auto', align: 'center' },
                ];
            case Common.QUOTATION_ENTITY:
                return [
                    { title: 'Customer/Party', field: 'referenceName', width: '*', align: 'left' },
                    { title: 'GSTIN', field: 'gstin', width: 'auto', align: 'left' },
                    { title: 'Quote #', field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'Amount', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Converted to invoice', field: 'referenceOrderNumber', width: 'auto', align: 'left' },
                ];
            case Common.DELIVERY_CHALLAN_ENTITY:
                return [
                    { title: 'Customer/Party', field: 'referenceName', width: '*', align: 'left' },
                    { title: 'GSTIN', field: 'gstin', width: 'auto', align: 'left' },
                    { title: 'D.C. #', field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'Amount', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Converted to invoice', field: 'referenceOrderNumber', width: 'auto', align: 'left' },
                ];
            case Common.CREDIT_NOTE_ENTITY:
            case Common.DEBIT_NOTE_ENTITY:
                return [
                    { title: `${entityName === Common.CREDIT_NOTE_ENTITY ? "Customer" : "Supplier"}/Party`, field: 'referenceName', width: '*', align: 'left' },
                    { title: 'GSTIN', field: 'gstin', width: 'auto', align: 'left' },
                    { title: `${entityName === Common.CREDIT_NOTE_ENTITY ? "Cr" : "Dr"}. note #`, field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'Amount', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: `Converted to ${entityName === Common.CREDIT_NOTE_ENTITY ? "Invoice" : "Bill"} #`, field: 'referenceOrderNumber', width: 'auto', align: 'left' },
                ];
            case Common.PURCHASE_BILL_ENTITY:
                return [
                    { title: 'Supplier/Party', field: 'referenceName', width: '*', align: 'left' },
                    { title: 'GSTIN', field: 'gstin', width: 'auto', align: 'left' },
                    { title: 'Bill #', field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Bill Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'Amount', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Paid', field: 'amountReceived', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Returns', field: 'returnsAmount', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Due Amount', field: 'amountDue', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                ];
            case Common.PURCHASE_ORDER_ENTITY:
                return [
                    { title: 'Supplier/Party', field: 'referenceName', width: '*', align: 'left' },
                    { title: 'Amount', field: 'grandTotal', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
                    { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: 'date' },
                    { title: 'P.O. #', field: 'orderNumber', width: 'auto', align: 'left' },
                    { title: 'Status', field: 'statusId', width: 'auto', align: 'left' },
                    { title: `Converted to Bill #`, field: 'referenceOrderNumber', width: 'auto', align: 'left' },
                ];
            default:
                return [];
        }
    };
    const transactionsColumnDef = getTransactionsColumnDef();

    var transactions = [
        {
            style: 'nestedTableCompact',
            table: buildTable(transactionsColumnDef, printData.statementData, true, 0, '', false),

            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? 'white' : '#e8f3fd';
                },
                hLineWidth: function (i, node) {
                    if ((i <= 1 || i === node.table.body.length)) {
                        return 1;
                    }
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ];

    body.push(transactions);
    return body;
}

export function addStatementFooter(currentPage, pageCount, printData) {
    return {
        stack: [
            { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 40, 20] },
            { text: 'Report date: ' + Utility.getCurrentDateTime(), alignment: 'center', margin: [0, 0, 20, 20], style: 'quote' }
        ]
    }
}





//#endregion


//#region Customer/Supplier pending statement
export function addPendingStatementBody(printData) {
    var body = [];
    const party = printData.party;
    var partyAddress = party.addressList[0];
    const summary = [
        { text: 'Bill wise details:', bold: false, alignment: 'center', style: 'normal', margin: [0, 0, 5, 0] },
        { text: getPartyName(party, printData.referenceType), bold: true, alignment: 'center', style: 'normal', margin: [0, 0, 5, 0] },
        { text: partyAddress.addressLine1 + ", " + partyAddress.addressLine2 + ' ' + partyAddress.city + ' ' + getStateName(partyAddress.stateId, printData.stateList) + ' ' + partyAddress.pinCode, alignment: 'center', bold: false, style: 'normal' },
        { text: 'Tel.: ' + partyAddress.contactPhone + ' Email: ' + partyAddress.contactEmail, bold: false, alignment: 'center', style: 'normal' },
    ];

    body.push(summary);

    const transactionsColumnDef = [
        { title: 'Date', field: 'orderDate', width: '12%', align: 'left', type: "date" },
        { title: ((printData.referenceType === 'Customer') ? 'Invoice/Ref No.' : 'Bill No.'), field: 'orderNumber', width: '18%', align: 'left' },
        { title: 'P.O./Ref No.', field: 'poReference', width: '18%', align: 'left' },
        { title: 'Opening Amount', field: 'grandTotal', width: '15%', align: 'right', type: "currency", blankIfZero: true },
        { title: 'Pending Amount', field: 'amountDue', width: '15%', align: 'right', type: "currency", blankIfZero: true },
        { title: 'Due on', field: 'paymentDueDate', width: '12%', align: 'left', type: "date" },
        { title: 'Overdue by days', field: 'daysInAdvance', width: '*', align: 'center', blankIfNegative: true },
    ];
    var transactions = [
        {
            style: 'tableItems',
            table: buildTable(transactionsColumnDef, printData.statementData, true, 0, '', false),
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? 'white' : '#E9E9E9';
                },
                hLineWidth: function (i, node) {
                    if (i <= 1) {
                        return 1;
                    }
                    return 0.5;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ];
    body.push(transactions);


    return body;
}
//#endregion

export function addCustomerGroupStatementBody(printData) {
    var body = [];
    const summary = [
        { text: 'Bill wise details:', bold: false, alignment: 'center', style: 'normal', margin: [0, 0, 5, 0] },
        { text: Utility.safeString(printData.customerGroup.name), fontSize: 12, bold: true, alignment: 'center', style: 'normal', margin: [0, 0, 5, 0] },
    ];

    body.push(summary);

    const transactionsColumnDef = [
        { title: 'Customer', field: 'referenceName', width: '*', align: 'left' },
        { title: 'Date', field: 'orderDate', width: 'auto', align: 'left', type: "date" },
        { title: 'Invoice/Ref No.', field: 'orderNumber', width: 'auto', align: 'left' },
        { title: 'Opening Amount', field: 'openingAmount', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
        { title: 'Pending Amount', field: 'pendingAmount', width: 'auto', align: 'right', type: "currency", blankIfZero: false },
        { title: 'Due on', field: 'dueDate', width: '*', align: 'right', type: "date" },
        { title: 'Overdue by days', field: 'overDueDays', width: 'auto', align: 'right', blankIfZero: true },
    ];
    var transactions = [
        {
            style: 'tableItems',
            table: buildCustomerGroupStatementTable(transactionsColumnDef, printData.statementData, true),
            layout: {
                hLineWidth: function (i, node) {
                    if (i <= 1) {
                        return 1;
                    }
                    return 0.5;
                },
                vLineWidth: function (i, node) {
                    return 0.5;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    ];
    body.push(transactions);

    return body;
}

function buildCustomerGroupStatementTable(columnDefinitions, tableData, hasHeader = true,) {
    var columnWidths = [];
    var columnAlignments = [];
    var columnHeaders = [];

    if (!!columnDefinitions && columnDefinitions.length > 0) {
        columnDefinitions.map(def => {
            columnWidths.push(def.width);
            columnAlignments.push((!!def.align ? def.align : 'left'));
            if (hasHeader === true) {
                columnHeaders.push({ text: def.title, bold: true, alignment: def.align });
            }
        });
    }

    var tableBody = [];
    if (hasHeader === true) {
        tableBody.push(columnHeaders);
    }
    if (!!tableData && tableData.length > 0) {
        tableData.map((dataItem, index) => {
            var row = [];
            columnDefinitions.map((col, colIndex) => {
                if (Utility.safeInt(dataItem.recordType) === 2) { //Summary records                    
                    if (colIndex === 0) {
                        row.push({ text: dataItem['referenceName'], colSpan: 3, alignment: 'right', fontSize: 10, bold: true, italics: true, color: 'blue' });
                    } else if (colIndex === 5) {
                        row.push({ text: 'Received: ' + (Utility.safeFloat(dataItem['amountReceived'])).toFixed(2), alignment: 'right', fontSize: 10, bold: true, italics: true, color: 'green' });
                    } else if (colIndex === 6) {
                        var returns = '';
                        if (Utility.safeFloat(dataItem['returnsAmount']) > 0) {
                            returns = 'Returns: ' + (Utility.safeFloat(dataItem['returnsAmount'])).toFixed(2);
                        }
                        row.push({ text: returns, alignment: 'left', fontSize: 10, bold: true, italics: true, color: 'red' });
                    } else {
                        if (col.type === 'currency') {
                            let amountValue = Utility.safeFloat(dataItem[col.field]);
                            if (Utility.safeBool(col.blankIfZero)) {
                                if (amountValue !== 0) {
                                    amountValue = Utility.formatToCurency(amountValue);
                                } else {
                                    amountValue = '0.00';
                                }
                            } else {
                                amountValue = amountValue.toFixed(2);
                            }
                            var colColor = 'black';
                            if (colIndex === 4) {
                                colColor = 'red';
                            }
                            row.push({ text: amountValue, alignment: col.align, fontSize: 10, bold: true, italics: true, color: colColor });
                        } else if (col.type === 'date') {
                            row.push({ text: Utility.getLocaleDate(dataItem[col.field]), alignment: col.align, fontSize: 10, bold: true, italics: true, color: 'blue' });
                        } else {
                            row.push({ text: dataItem[col.field], alignment: col.align, fontSize: 10, bold: true, italics: true, color: 'blue' });
                        }
                    }
                } else {
                    if (col.field === 'index') {
                        row.push({ text: index + 1, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                    } else {
                        if (col.type === 'currency') {
                            let amountValue = Utility.safeFloat(dataItem[col.field]);
                            if (Utility.safeBool(col.blankIfZero)) {
                                if (amountValue !== 0) {
                                    amountValue = Utility.formatToCurency(amountValue);
                                } else {
                                    amountValue = '0.00';
                                }
                            } else {
                                amountValue = amountValue.toFixed(2);
                            }
                            row.push({ text: amountValue, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                        } else if (col.type === 'date') {
                            row.push({ text: Utility.getLocaleDate(dataItem[col.field]), alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                        } else {
                            var columnVal = dataItem[col.field];
                            if (Utility.safeBool(col.blankIfZero)) {
                                if (Utility.safeFloat(dataItem[col.field]) == 0) {
                                    columnVal = '--';
                                }
                            }
                            row.push({ text: columnVal, alignment: col.align, fontSize: !!col.fontSize ? col.fontSize : null, bold: !!col.bold ? col.bold : false });
                        }
                    }
                }
            });
            tableBody.push(row);
        });
    }

    var table = {
        widths: columnWidths,
        headerRows: hasHeader === true ? 1 : 0,
        body: tableBody
    };
    // console.log(JSON.stringify(table));
    return table;

}

export function addExpenseReceiptBody(printData) {
    var body = [];
    var expenseData = [];

    expenseData.push(
        {
            label: 'Paid to:',
            description: printData.expense.payeeName,
        },
        {
            label: 'Date:',
            description: Utility.getLocaleDate(printData.expense.expenseDate),
        },
        {
            label: 'Amount:',
            description: Utility.safeFloat(printData.expense.totalAmount).toFixed(2) + '  (' + Utility.getAmountInWords(Utility.safeFloat(printData.expense.totalAmount).toFixed(2), true) + ')',
        },
        {
            label: 'Mode of Payment:',
            description: printData.expense.paymentMode,
        },
        {
            label: 'Towards:',
            description: printData.expense.description,
        },
        {
            label: 'Notes:',
            description: printData.expense.notes,
        },
    );

    const columnDefinition = [
        { title: 'Col 1', field: 'label', width: 'auto', align: 'left' },
        { title: 'Col 2', field: 'description', width: '*', align: 'left' }
    ];

    var expenseDetail = [
        {
            style: 'tableList',
            table: buildTable(columnDefinition, expenseData, false, 0, '', false),
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
            }
        }
    ];

    body.push(expenseDetail);

    body.push([
        { text: '---------------------------------', bold: true, alignment: 'right', style: 'normal', margin: [0, 60, 45, 0] },
        { text: '\nSignature / Seal', bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 45, 0] },
    ]);

    return body;

}

export function addPaymentReceiptBody(printData) {
    var body = [];
    var paymentData = [];

    paymentData.push(
        {
            label: printData?.referenceType === "Customer" ? 'Received with thanks from:' : 'Paid To :',
            description: printData?.referenceType === "Customer" ? printData.party.legalName : printData.party.name,
        },
        {
            label: 'Date:',
            description: Utility.getLocaleDate(printData.paymentData.paymentDate),
        },
        {
            label: 'Amount:',
            description: Utility.safeFloat(printData.paymentData.amount).toFixed(2) + '  (' + Utility.getAmountInWords(Utility.safeFloat(printData.paymentData.amount).toFixed(2), true) + ')',
        },
        {
            label: 'Mode of Payment:',
            description: printData.paymentData.modeOfPayment,
        },
        {
            label: 'Towards:',
            description: printData.paymentData.orderNumber,
        },
        {
            label: 'Notes:',
            description: printData.paymentData.narrative,
        },
    );

    const columnDefinition = [
        { title: 'Col 1', field: 'label', width: 'auto', align: 'left' },
        { title: 'Col 2', field: 'description', width: '*', align: 'left' }
    ];

    var paymnetDetail = [
        {
            style: 'tableList',
            table: buildTable(columnDefinition, paymentData, false, 0, '', false),
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
            }
        }
    ];

    body.push(paymnetDetail);

    body.push([
        { text: '---------------------------------', bold: true, alignment: 'right', style: 'normal', margin: [0, 60, 45, 0] },
        { text: '\nSignature / Seal', bold: true, alignment: 'right', style: 'normal', margin: [0, 0, 45, 0] },
    ]);

    return body;

}


export function getLabelText(label, text, boldData = true, fontSize = 9, align = 'left') {
    return { text: [label, { text: text, bold: boldData }], fontSize: fontSize, alignment: align };
}

export function getDescInfoText(description, infoText, boldInfo = false, fontSize = 9, align = 'left') {
    return { text: [description, { text: "\n" + infoText, bold: boldInfo }], fontSize: fontSize, alignment: align, bold: true };
}

//#region Elegant format
export function addPageBorderElegant(reportName = "", entityType = "") {
    return [
        {
            canvas: [
                { type: 'line', x1: 20, y1: 25, x2: 575, y2: 25, lineWidth: 0.5 }, //Up line
                { type: 'line', x1: 20, y1: 45, x2: 575, y2: 45, lineWidth: 0.5 }, //Header line 1
                // { type: 'line', x1: 20, y1: 115, x2: 575, y2: 115, lineWidth: 0.5 }, //Header line 2
                { type: 'line', x1: 20, y1: 25, x2: 20, y2: 780, lineWidth: 0.5 }, //Left line
                // { type: 'line', x1: 20, y1: 800, x2: 575, y2: 800, lineWidth: 0.5 }, //Bottom line
                // { type: 'line', x1: 20, y1: 700, x2: 575, y2: 700, lineWidth: 0.5 }, //Footer line
                { type: 'line', x1: 575, y1: 25, x2: 575, y2: 780, lineWidth: 0.5 }, //Rigth line
            ],
        }
    ];
}

export function addHeaderElegant(printData, entityType = 'Invoice') {
    var settings = printData.settings;
    var stateList = printData.stateList;
    var subscriber = printData.subscriber;
    var subscriberAddress = printData.subscriber.addressList[0];
    var voucherData = printData.voucherData;
    const isUnregisteredBusiness = subscriber.taxRegistrationTypeId == Common.TAX_REG_UNREGISTERED;
    const customFields = printData.customFields;

    var entityDetails = [];
    let fieldDefinition = getEntityFields(customFields, entityType);
    let additionalDetails = null;
    if (entityType === 'Invoice') {
        if (fieldDefinition?.hasCustomFields) {
            let customFieldVal = '';
            let rowArray = [];
            let columnCount = 2;
            additionalDetails = getAdditionalDetails(voucherData);
            rowArray.push(getLabelText('Invoice #: ', voucherData.orderNumber, 10));
            rowArray.push(getLabelText('Invoice date: ', Utility.getLocaleDate(voucherData.orderDate), 10));
            for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
                const customField = fieldDefinition?.customFields[i];
                if (Utility.safeBool(customField?.print)) {
                    if (Utility.safeBool(customField?.isStandard)) {
                        customFieldVal = Utility.safeString(voucherData[customField?.name]);
                    } else {
                        if (!Utility.isNullOrUndefined(additionalDetails)) {
                            customFieldVal = Utility.safeString(additionalDetails[Utility.safeString(customField?.name)]);
                        } else {
                            customFieldVal = '';
                        }
                    }
                    if (customField?.type === 'DateTime') {
                        customFieldVal = Utility.getLocaleDateTime(customFieldVal);
                    } else if (customField?.type === 'Date') {
                        customFieldVal = Utility.getLocaleDate2(customFieldVal);
                    } else if (customField?.type === 'Currency') {
                        customFieldVal = Utility.safeFloat(customFieldVal).tofixed(2);
                    }
                    if (Utility.safeBool(customField.fullWidth)) {
                        entityDetails.push([{ colSpan: 4, text: [`${Utility.safeString(customField?.label)}: `, { text: customFieldVal, bold: true, fontSize: 10 }], fontSize: 10 }])
                    } else {
                        rowArray.push(getLabelText(Utility.safeString(customField?.label) + ": ", customFieldVal, 10));
                        columnCount += 1;
                    }
                    if (columnCount === 4) {
                        entityDetails.push(rowArray);
                        rowArray = [];
                        columnCount = 0;
                    } else if (columnCount < 4 && i === (fieldDefinition?.customFields.length - 1)) {
                        for (let k = columnCount; k < 4; k++) {
                            rowArray.push(getLabelText("", "", 10));
                        }
                        entityDetails.push(rowArray);
                        rowArray = [];
                        columnCount = 0;
                    }
                }
            }
        } else {
            if (settings.invoiceFormat === Common.INVOICE_FORMAT_COMFORT || settings.invoiceFormat === Common.INVOICE_FORMAT_COMFORT_LOGO) {
                entityDetails.push([
                    getLabelText('Invoice #: ', voucherData.orderNumber, 10),
                    getLabelText('Invoice date: ', Utility.getLocaleDate(voucherData.orderDate), 10),
                    getLabelText('P.O. date: ', Utility.getLocaleDate(voucherData.poDate), 10),
                    getLabelText('Reference: ', voucherData.orderReference, 10),
                ]);
                entityDetails.push([
                    getLabelText('E-Way bill #: ', voucherData.eWayBillNo, 10),
                    getLabelText('Delivery date: ', Utility.getLocaleDate(voucherData.deliveryDate), 10),
                    { colSpan: 2, text: ['P.O. #: ', { text: voucherData.poReference, fontSize: 9, bold: true }], fontSize: 9, bold: false },
                ]);
            } else {
                entityDetails.push([
                    getLabelText('Invoice #: ', voucherData.orderNumber, 10),
                    getLabelText('Invoice date: ', Utility.getLocaleDate(voucherData.orderDate), 10),
                    getLabelText('P.O. #: ', voucherData.poReference, 10),
                    getLabelText('P.O. date: ', Utility.getLocaleDate(voucherData.poDate), 10),
                ]);
                entityDetails.push([
                    getLabelText('E-Way bill #: ', voucherData.eWayBillNo, 10),
                    getLabelText('Delivery date: ', Utility.getLocaleDate(voucherData.deliveryDate), 10),
                    { colSpan: 2, text: ['Reference: ', { text: voucherData.orderReference, fontSize: 9, bold: true }], fontSize: 9, bold: false },
                ]);
            }
            entityDetails.push([
                { colSpan: 4, text: ['Transport details: ', { text: voucherData.transportDetails, fontSize: 9, bold: true }], fontSize: 9, bold: false },
            ]);
            entityDetails.push([
                { colSpan: 4, text: ['Notes/Terms: ', { text: voucherData.termsAndConditions, fontSize: 9, bold: true }], fontSize: 9, bold: false },
            ]);
        }
    } else if (entityType === 'Quotation') {
        entityDetails.push([
            getLabelText(printData.heading === "Proforma Invoice" ? "P.I:" : 'Quotation #: ', voucherData.orderNumber, 10),
            getLabelText(printData.heading === "Proforma Invoice" ? "P.I date:" : 'Quotation date: ', Utility.getLocaleDate(voucherData.orderDate), 10),
            { colSpan: 2, text: ['Reference: ', { text: voucherData.orderReference, fontSize: 9, bold: true }], fontSize: 9, bold: false },
        ]);
        entityDetails.push([
            { colSpan: 4, text: ['Notes/Terms: ', { text: voucherData.termsAndConditions, fontSize: 9, bold: true }], fontSize: 9, bold: false },
        ]);
    }

    var elegantBody = [];
    if (subscriber.taxRegistrationTypeId != Common.TAX_REG_UNREGISTERED) {
        elegantBody.push({ text: ['GSTIN: ', { text: subscriber.taxIdNumber, bold: true }], fontSize: 9 });
    }
    elegantBody.push({ text: printData.heading, bold: true, alignment: 'center', fontSize: 14 });

    if (Utility.safeInt(subscriber.businessTypeId) === 404 || Utility.safeInt(subscriber.businessTypeId) === 405) {
        elegantBody.push({ text: ['D.L. No.: ', { text: getDrugLicenseValue(subscriber), bold: true }], fontSize: 9, alignment: 'right' });
    } else {
        if (!isUnregisteredBusiness) {
            elegantBody.push({ text: ['PAN: ', { text: getPAN(subscriber.taxIdNumber), bold: true }], fontSize: 9, alignment: 'right' });
        }
    }
    return {
        stack: [
            {
                style: 'noMargins',
                table: {
                    widths: ['*', '*', '*'],
                    body: [elegantBody]
                },
                layout: {
                    defaultBorder: false,
                    paddingLeft: function (i, node) { return 0; },
                    paddingRight: function (i, node) { return 0; },
                    paddingTop: function (i, node) { return 0; },
                    paddingBottom: function (i, node) { return 0; },
                }
            },
            [
                getMainHeading(entityType, subscriber, subscriberAddress, printData, stateList, settings)
            ],
            {
                style: 'noMargins',
                table: {
                    widths: ['*', '*', '*', '*'],
                    body: entityDetails
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return (i % 2 === 0) ? 0.5 : 0;
                    },
                }
            },
        ]
    }
}

export function addFooterElegant(currentPage, pageCount, pageSize, printData, entityType = '') {
    var subscriber = printData.subscriber;
    var stackData = [];
    stackData.push(addCustomerSignatureFooter(subscriber, entityType, printData.settings));
    if (!Utility.isNullOrEmpty(printData.subscriber.signatureAndSeal)) {
        stackData.push({
            image: printData.subscriber.signatureAndSeal,
            fit: [48, 48],
            absolutePosition: { x: 410, y: 10 },
        });
    } else if (!Utility.isNullOrEmpty(printData.settings.authorisedSignature)) {
        stackData.push({
            image: printData.settings.authorisedSignature,
            fit: [120, 120],
            absolutePosition: { x: 382, y: 10 }
        });
    }
    stackData.push({ text: 'Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.', alignment: 'center', fontSize: 7 });
    stackData.push({ text: 'This is a computer generated document.', alignment: 'center', fontSize: 7 });
    stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, absolutePosition: { x: 528, y: 75 }, fontSize: 9, bold: true });
    return {
        stack: stackData
    }
}

function addBankTermsAndConditionsElegant(subscriber, bankDetails, settings, entityType) {
    if (entityType === Common.DELIVERY_CHALLAN_ENTITY
        || entityType === Common.CREDIT_NOTE_ENTITY
        || entityType === Common.DEBIT_NOTE_ENTITY) {
        return null;
    } else {
        return {
            style: 'footerTableTerms',
            table: {
                widths: ['*', 'auto', 'auto'],
                body: [
                    [
                        {
                            style: 'tableItems',
                            table: buildTable([
                                { title: 'Terms & Conditions:', field: 'field1', width: 'auto', align: 'left', fontSize: 9 }
                            ], [
                                {
                                    field1: getTermsAndConditions(settings, entityType)
                                }
                            ], false, 0),
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0;
                                },
                                vLineWidth: function (i, node) {
                                    return 0;
                                },
                                hLineColor: function (i, node) {
                                    return 'black';
                                },
                                vLineColor: function (i, node) {
                                    return 'black';
                                },
                            }
                        },
                        {
                            style: 'tableItems',
                            table: buildTable([
                                { title: Utility.isNullOrUndefined(bankDetails) ? '' : 'Bank details:', field: 'field1', width: 'auto', align: 'right', fontSize: 9 },
                            ], [
                                {
                                    field1: Utility.isNullOrUndefined(bankDetails) ? '' : ("Company's PAN: " + getPAN(subscriber.taxIdNumber)
                                        + "\n A/c Name: " + Utility.safeString(bankDetails.accountName)
                                        + "\n Bank Name: " + Utility.safeString(bankDetails.bankName)
                                        + "\n A/c No.: " + Utility.safeString(bankDetails.accountNumber)
                                        + "\n Branch: " + Utility.safeString(bankDetails.branchName)
                                        + "\n IFSC: " + Utility.safeString(bankDetails.ifscCode)),
                                },
                            ], false, 0),
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0;
                                },
                                vLineWidth: function (i, node) {
                                    return 0;
                                },
                                hLineColor: function (i, node) {
                                    return 'black';
                                },
                                vLineColor: function (i, node) {
                                    return 'black';
                                },
                            }
                        },
                        {
                            stack: getQRCode(bankDetails, settings)
                        }
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return 'black';
                },
                vLineColor: function (i, node) {
                    return 'black';
                },
            }
        }
    }
}

//#endregion


//#region

export function addHeaderService(printData, entityType = 'Invoice') {
    var settings = printData.settings;
    var stateList = printData.stateList;
    var subscriber = printData.subscriber;
    var subscriberAddress = printData.subscriber.addressList[0];
    var party = printData.party;
    var partyAddress = {};

    return {
        stack: [
            {
                style: 'headerTableServiceInvoice',
                table: {
                    widths: ['*', 'auto'],
                    body: [
                        getMainHeading(entityType, subscriber, subscriberAddress, printData, stateList, settings)
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    hLineColor: function (i, node) {
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    paddingLeft: function (i, node) { return 0; },
                    paddingRight: function (i, node) { return 0; },
                    paddingTop: function (i, node) { return 0; },
                    paddingBottom: function (i, node) { return 0; },
                }
            },
        ]
    }
}


//#endregion