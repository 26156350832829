import shallow from "zustand/shallow";
import useStore from "../../Base/Store";
import NavMenu from "./NavMenu";
import useNavStyles from "./Styles/NavStyles";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Layout(props) {
    const classes = useNavStyles();
    const [closeDialog, openDialog] = useStore(state => [state.closeDialog, state.openDialog], shallow);
    const history = useHistory();
    const [isSearchDrawerOpen, toggleSearchDrawer] = useStore(state => [state.isSearchDrawerOpen, state.toggleSearchDrawer], shallow);
    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        if (event.altKey && (event.key === 'a' || event.key === 'A')) {
            closeDialog();
            openDialog('quickAddDialog');
        } else if (event.altKey && (event.key === 'n' || event.key === 'N')) {
            history.push('/newinvoice');
            closeDialog();
        } else if (event.altKey && (event.key === 'q' || event.key === 'Q')) {
            history.push('/newquotation');
            closeDialog();
        } else if (event.altKey && (event.key === 'i' || event.key === 'I')) {
            history.push('/selectparty/Customer');
            closeDialog();
        } else if (event.altKey && (event.key === 'o' || event.key === 'O')) {
            history.push('/selectparty/Supplier');
            closeDialog();
        } else if (event.altKey && (event.key === 'b' || event.key === 'B')) {
            history.push('/newpurchasebill');
            closeDialog();
        } else if (event.altKey && (event.key === 'x' || event.key === 'X')) {
            history.push('/expense');
            closeDialog();
        } else if (event.altKey && (event.key === 'h' || event.key === 'H')) {
            history.push('/dashboard');
            closeDialog();
        } else if (event.altKey && (event.key === 'l' || event.key === 'L')) {
            toggleSearchDrawer(!isSearchDrawerOpen);
        }        
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);           
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div className={classes.root}>
                <NavMenu />
                <div className={classes.content}>
                    <div className={classes.marginForAppBar} />
                    {props.children}
                </div>
            </div>
        </>
    )
};
