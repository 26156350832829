import { Box, Button, ButtonBase, ButtonGroup, Divider, Grid, IconButton, Menu, MenuItem, Switch, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "../../styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppIcon from "../UI/Controls/AppIcons";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import OrderPreview from "./OrderPreview";
import shallow from "zustand/shallow";
import useStore from "../Base/Store";
import { getConfirmOptions, getTodaysDate, isNullOrEmpty, isNullOrUndefined, lowerCaseFirstLetter, safeInt } from "../Base/Utils";
import { getFilteredEntityList, getMasterData } from "../Base/DataProvider";
import { APP_MODULE_Invoices, CREDIT_NOTE_ENTITY, CUSTOMER_ENTITY, DEBIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, ERROR_NO_RETURNS_FOUND, INVOICE_ENTITY, PDF_OUTPUT_DOWNLOAD, PDF_OUTPUT_URL, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY } from "../Base/Common";
import AuthContext from "../Auth/AuthContext";
import { copyOrder, getOrder, getParty, processPrintPdf } from "../Shared/OrderHelper";
import printJS from "print-js";
import ContextMenu from "../UI/Controls/ContextMenu";
import ContextMenuItemShare from "../UI/Controls/ContextMenuItem";
import DialogWindow from "../UI/Controls/DialogWindow";
import WhatsAppSend from "../Shared/Communication/WhatsAppSend";
import SMSSend from "../Shared/Communication/SMSSend";
import { APP_MODULE_Receivables } from './../Base/Common';
import { OrderListItem } from "./OrderListItem";
import { Form, Formik, isEmptyArray } from "formik";
import OrderListViewSort from './OrderListViewSort';
import OrderListViewFilter from "./OrderListViewFilter";
import * as yup from 'yup';
import { PrintOrderStatementPdf } from "../Shared/Printing/PrintPDF";
import { useConfirm } from "material-ui-confirm";
import { Recurring } from "../Sales/Invoice/Recurring";
import NewPaymentLink from "../Payments/PaymentLinks/NewPaymentLink";
import EmailSend from "../Shared/Communication/EmailSend";
import EntityButtonGroup from "./OrderListHeaderButton";
import OrderListViewDesktopMenu from "./OrderListViewDesktopMenu";
import OrderListViewMobileMenu from "./OrderListViewMobileMenu";
import PurchaseOrderFullfillmentStatus from "../Reports/FullfillmentStatus/PurchaseOrderFullfillmentStatus";

export default function OrdersListView({ entityName, referenceType, pageHeading, pageSubHeading = '', pageIcon, referenceId = 0, headingColor = "", salesToday = false }) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const showAlert = useStore(state => state.showAlert);
    const [tableData, setTableData] = useState([]);
    const authContext = useContext(AuthContext);
    const [pdfUrl, setPdfUrl] = useState('');
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedReferenceId, setSelectedReferenceId] = useState(0);
    const [sharedAnchorEl, setSharedAnchorEl] = useState(null);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [customerMenu, setCustomerMenu] = useState(null);
    const paymentAccess = authContext.moduleAccess(APP_MODULE_Receivables);
    const [orderListFilter, setOrderListFilter] = useStore(state => [state.orderListFilter, state.setOrderListFilter], shallow);
    const moduleAccess = authContext.moduleAccess(APP_MODULE_Invoices);
    const [dialogResponse, setDialogResponse] = useStore(state => [state.dialogResponse, state.setDialogResponse], shallow);
    const [displayDataGrid, toggleDisplayDataGrid] = useStore(state => [state.displayDataGrid, state.toggleDisplayDataGrid], shallow);
    const [orderData, setOrderData] = useState(null);
    const [selectedPartyOrderValue, SetSelectedPartyOrderValue] = useState(null);
    const [anchorElMobileExport, setAnchorElMobileExport] = useState(null);
    const [paymentLinkResponse, setPaymentLinkResponse] = useState();
    const [selectedReturn, setSelectedReturn] = useStore(state => [state.selectedReturn, state.setSelectedReturn], shallow);
    const confirm = useConfirm();
    const entityNameLowerCase = lowerCaseFirstLetter(entityName);



    const [fieldValues, setFieldValues] = useState({
        fromDate: salesToday ? getTodaysDate() : orderListFilter[`${entityNameLowerCase}FromDate`],
        toDate: salesToday ? getTodaysDate() : orderListFilter[`${entityNameLowerCase}ToDate`],
        referenceId: salesToday ? 0 : orderListFilter[`${entityNameLowerCase}ReferenceId`],
        referenceType: referenceType,
        selectedParty: null,
        employeeId: salesToday ? 0 : safeInt(orderListFilter[`${entityNameLowerCase}EmployeeId`]),
        employeeName: "",
        projectName: "",
        projectId: salesToday ? 0 : safeInt(orderListFilter[`${entityNameLowerCase}ProjectId`])
    });

    const handleClickMobileExport = (event) => {
        setAnchorElMobileExport(event.currentTarget);
    };

    const handleCloseMobileExport = () => {
        setAnchorElMobileExport(null);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortChange = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
    };


    const filteredData = tableData.filter(invoice =>
        invoice.orderNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.referenceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        invoice.grandTotal.toString().includes(searchQuery)
    );

    const sortedData = [...filteredData].sort((a, b) => {
        if (!isNullOrEmpty(sortField)) {
            if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const openShared = (event) => {
        setSharedAnchorEl(event.currentTarget);
    }

    const handleSharedClose = () => {
        setSharedAnchorEl(null);
    };

    const getList = async (values, token = null) => {
        try {
            setWaiting(true);
            let filter;
            switch (entityName) {
                case INVOICE_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType,
                        projectId: safeInt(values.projectId),
                        employeeId: safeInt(values.employeeId),
                    };
                    break;
                case PURCHASE_BILL_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType,
                        projectId: safeInt(values.projectId),
                        employeeId: safeInt(values.employeeId),
                    };
                    break;
                case QUOTATION_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType
                    };
                    break;
                case DELIVERY_CHALLAN_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType
                    };
                    break;
                case CREDIT_NOTE_ENTITY:
                case DEBIT_NOTE_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType
                    };
                    break;
                case PURCHASE_ORDER_ENTITY:
                    filter = {
                        fromDate: values?.fromDate,
                        toDate: values?.toDate,
                        pending: false,
                        active: 1,
                        includeOpening: false,
                        ascending: false,
                        referenceId: safeInt(values?.referenceId),
                        referenceType: referenceType
                    };
                    break;
                default:
                    break;
            }
            storeFilterStateValues(values);
            const response = await getFilteredEntityList(entityName, filter, authContext, token, true);

            if (!!response) {
                if (!!response.code && response.code > 0) {
                    showAlert(response.message, 'error');
                } else {
                    setTableData(response);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const storeFilterStateValues = (values) => {
        try {
            if (!salesToday) { //dont save filter when on Sales today screen
                let tempOrderListFilterValue = { ...orderListFilter }
                tempOrderListFilterValue[`${entityNameLowerCase}FromDate`] = values?.fromDate;
                tempOrderListFilterValue[`${entityNameLowerCase}ToDate`] = values?.toDate;
                tempOrderListFilterValue[`${entityNameLowerCase}ReferenceId`] = safeInt(values?.referenceId);
                tempOrderListFilterValue[`${entityNameLowerCase}EmployeeId`] = safeInt(values?.employeeId);
                tempOrderListFilterValue[`${entityNameLowerCase}ProjectId`] = safeInt(values?.projectId);
                setOrderListFilter(tempOrderListFilterValue);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const printOrder = async (outputType, selectedId, selectedReferenceId) => {
        try {
            setWaiting(true);
            await processPrintPdf(selectedId, entityName, selectedReferenceId, CUSTOMER_ENTITY, authContext, outputType,
                (dataUrl) => {
                    setPdfUrl(dataUrl);
                    if (!isNullOrUndefined(dataUrl.error)) {
                        showAlert(dataUrl.error, 'error');
                    }
                }
            );
        } catch (error) {
            console.log(error);
            showAlert('An error occured while performing the selected action. Please try again or contact support.', 'error')
        }
        finally {
            setWaiting(false);
        }
    }


    const handleOrderItemClick = async (row, index) => {
        if (row.id !== selectedId) {
            setSelectedRowIndex(index);
            setSelectedId(row.id);
            setOrderData(row);
            setSelectedReferenceId(row.referenceId);
            await printOrder(PDF_OUTPUT_URL, row.id, row.referenceId);
        }
    }

    const handleOrderClick = (selectedId = 0) => {
        if (safeInt(selectedId) > 0) {
            let path = ""
            switch (entityName) {
                case INVOICE_ENTITY:
                    path = '/invoice/'
                    break;
                case PURCHASE_BILL_ENTITY:
                    path = '/purchasebill/'
                    break;
                case QUOTATION_ENTITY:
                    path = '/quotation/'
                    break;
                case DELIVERY_CHALLAN_ENTITY:
                    path = '/deliverychallan/'
                    break;
                case CREDIT_NOTE_ENTITY:
                    path = "/creditnote/"
                    break;
                case DEBIT_NOTE_ENTITY:
                    path = "/debitnote/"
                    break;
                case PURCHASE_ORDER_ENTITY:
                    path = "/purchaseorder/"
                    break;
                default:
                    break;
            }
            history.push(`${path}` + selectedId);
        } else {
            showAlert('No Order Selected', 'error');
        }
    }


    const printOrderHandler = async () => {
        if ((!isNullOrUndefined(selectedId) && !isNullOrUndefined(selectedReferenceId)) && (!isNullOrEmpty(selectedId) && !isNullOrEmpty(selectedReferenceId))) {
            printJS(pdfUrl);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await getList(fieldValues);
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);


    const handleActionButtonClick = async (e) => {
        const action = e.currentTarget.attributes['name'].value;
        switch (action) {
            case 'sendWhatsApp':
                openDialog('sendWhatsAppDialog');
                break;
            case 'sendSMS':
                openDialog('sendSMSDialog');
                break;
            case 'sendEmail':
                openDialog('sendEmailDialog');
                break;
            case "downloadPDF":
                printOrder(PDF_OUTPUT_DOWNLOAD, selectedId, selectedReferenceId)
                break;
            default:
                alert(`Unhandled menu action: ${action}!`);
                break;
        }

    }

    const handleCustomerMenuClose = () => {
        setCustomerMenu(null);
    }

    const handleDialogClosed = async (dialogName) => {
        try {
            if (dialogResponse !== null) {
                switch (dialogName) {
                    case "advancedOrderFilter":
                        await getList(fieldValues.fromDate, fieldValues.toDate, null);
                        break;
                    case "createRecurring":
                        history.push('/recurringList');
                        break;
                    case "requestPaymentDialog":
                        openDialog('sendWhatsAppPaymentDialog');
                        setPaymentLinkResponse(dialogResponse)
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
        }
    }

    const handleCustomerMoreAction = (e, order = null, isMobileDevice = false) => {
        if (isMobileDevice) {
            setSelectedId(order.id);
            setSelectedReferenceId(order.referenceId);
            setOrderData(order);
        }
        setCustomerMenu(e.currentTarget);
    }

    const printStatement = async (outputType) => {
        try {
            setWaiting(true);

            const pdfOutputType = (mobileDevice === true) ? PDF_OUTPUT_DOWNLOAD : outputType;
            var party = null;
            if (safeInt(selectedPartyOrderValue?.id) > 0) {
                party = await getParty(safeInt(selectedPartyOrderValue?.id), referenceType, authContext)
            }

            const printData = {
                heading: `${entityName} list`,
                referenceType: referenceType,
                party: party,
                fromDate: fieldValues.fromDate,
                toDate: fieldValues.toDate,
                reportData: null,
                statementData: tableData,
                subscriber: authContext.currentSubscriber,
                stateList: getMasterData('State', authContext),
                downloadFileName: `${entityName} list`,
                outputType: pdfOutputType
            }
            await PrintOrderStatementPdf(entityName, printData,
                (dataUrl) => {
                    if (!mobileDevice) {
                        if (!isNullOrUndefined(dataUrl.error)) {
                            showAlert(dataUrl.error, 'error');
                        } else {
                            printJS(dataUrl);
                        }
                    }
                }
            );
        } catch (error) {
            console.log(error);
            showAlert('An error occured while performing the selected action. Please try again or contact support.', 'error')
        }
        finally {
            setWaiting(false);
        }
    }


    var selectedItemParty = {
        id: selectedReferenceId,
        legalName: orderData?.referenceName,
        name: orderData?.referenceName,
    };

    var selectedOrder = {
        id: selectedId,
        orderNumber: orderData?.orderNumber,
        returnsId: orderData?.returnsId
    }

    const getSortOptions = (entityName, sortField, sortDirection) => {
        switch (entityName) {
            case INVOICE_ENTITY:
                return [
                    { field: 'orderNumber', label: 'Invoice Number', isSorted: sortField === 'orderNumber', direction: sortDirection },
                    { field: 'orderDate', label: 'Invoice Date', isSorted: sortField === 'orderDate', direction: sortDirection },
                    { field: 'referenceName', label: 'Customer', isSorted: sortField === 'referenceName', direction: sortDirection },
                ];

            case PURCHASE_BILL_ENTITY:
            case PURCHASE_ORDER_ENTITY:
                return [
                    { field: 'orderNumber', label: 'Order Number', isSorted: sortField === 'orderNumber', direction: sortDirection },
                    { field: 'orderDate', label: 'Order Date', isSorted: sortField === 'orderDate', direction: sortDirection },
                    { field: 'referenceName', label: 'Supplier', isSorted: sortField === 'referenceName', direction: sortDirection },
                ];

            case QUOTATION_ENTITY:
                return [
                    { field: 'orderNumber', label: 'Order Number', isSorted: sortField === 'orderNumber', direction: sortDirection },
                    { field: 'orderDate', label: 'Order Date', isSorted: sortField === 'orderDate', direction: sortDirection },
                    { field: 'referenceName', label: 'Customer', isSorted: sortField === 'referenceName', direction: sortDirection },
                ];
            case DELIVERY_CHALLAN_ENTITY:
                return [
                    { field: 'orderNumber', label: 'Order Number', isSorted: sortField === 'orderNumber', direction: sortDirection },
                    { field: 'orderDate', label: 'Order Date', isSorted: sortField === 'orderDate', direction: sortDirection },
                    { field: 'referenceName', label: 'Customer', isSorted: sortField === 'referenceName', direction: sortDirection },
                ];
            case CREDIT_NOTE_ENTITY:
            case DEBIT_NOTE_ENTITY:
                return [
                    { field: 'orderNumber', label: 'Order Number', isSorted: sortField === 'orderNumber', direction: sortDirection },
                    { field: 'orderDate', label: 'Order Date', isSorted: sortField === 'orderDate', direction: sortDirection },
                    { field: 'referenceName', label: `${entityName === CREDIT_NOTE_ENTITY ? "Customer" : "Supplier"}`, isSorted: sortField === 'referenceName', direction: sortDirection },
                ];
            default:
                return [];
        }
    };

    const submitFormData = async (values) => {
        SetSelectedPartyOrderValue(values?.selectedParty);
        setPdfUrl('');
        setSelectedRowIndex(null);
        setSelectedId(0);
        setSelectedReferenceId(0);
        setOrderData(null);
        setFieldValues({
            fromDate: values.fromDate,
            toDate: values.toDate,
            referenceId: safeInt(values.referenceId),
            employeeId: safeInt(values.employeeId)
        });
        await getList(values)
    }

    function exportToExcel(data) {
        function convertToCSV(arr) {
            if (arr.length === 0) return '';

            const getHeaderMapping = () => {
                switch (entityName) {
                    case INVOICE_ENTITY:
                        return {
                            referenceName: 'Customer/Party',
                            gstin: 'GSTIN',
                            orderNumber: 'Invoice #',
                            orderDate: 'Date',
                            grandTotal: 'Invoice total',
                            receivedAmount: 'Received',
                            returnsAmount: 'Returns',
                            amountDue: 'Due Amount',
                            isCredit: 'Credit',
                            irnStatus: 'IRN Status'
                        };
                    case PURCHASE_BILL_ENTITY:
                        return {
                            referenceName: 'Supplier/Party',
                            gstin: 'GSTIN',
                            orderNumber: 'Bill #',
                            orderDate: 'Bill Date',
                            grandTotal: 'Amount',
                            receivedAmount: 'Paid',
                            returnsAmount: 'Returns',
                            amountDue: 'Due Amount',
                        };
                    case QUOTATION_ENTITY:
                        return {
                            referenceName: 'Supplier/Party',
                            gstin: 'GSTIN',
                            orderNumber: 'Quote #',
                            orderDate: 'Date',
                            grandTotal: 'Amount',
                            referenceOrderNumber: 'Converted to invoice',
                        }
                    case DELIVERY_CHALLAN_ENTITY:
                        return {
                            referenceName: 'Supplier/Party',
                            gstin: 'GSTIN',
                            orderNumber: 'D.C. #',
                            orderDate: 'Date',
                            grandTotal: 'Amount',
                            referenceOrderNumber: 'Converted to invoice',
                        }
                    case CREDIT_NOTE_ENTITY:
                    case DEBIT_NOTE_ENTITY:
                        return {
                            referenceName: `${entityName === CREDIT_NOTE_ENTITY ? "Customer" : "Supplier"}/Party`,
                            gstin: 'GSTIN',
                            orderNumber: `${entityName === CREDIT_NOTE_ENTITY ? "Cr" : "Dr"}. #`,
                            orderDate: 'Date',
                            grandTotal: 'Amount',
                            referenceOrderNumber: `Converted to ${entityName === CREDIT_NOTE_ENTITY ? "Invoice" : "Bill"}`,
                        }

                    case PURCHASE_ORDER_ENTITY:
                        return {
                            referenceName: 'Supplier/Party',
                            grandTotal: 'Amount',
                            orderDate: 'Date',
                            orderNumber: 'P.O. #',
                            referenceOrderNumber: 'Converted to Bill #',
                            statusId: "Status"
                        }
                    default:
                        return {};
                }
            };

            const headerMapping = getHeaderMapping();
            const csvRows = [];

            // Extract the desired headers
            const header = Object.keys(headerMapping);
            const csvHeader = Object.values(headerMapping);
            csvRows.push(csvHeader.join(',')); // Add the header row

            for (const row of arr) {
                const csvRow = header.map(fieldName => {
                    let value = row[fieldName];

                    if (fieldName === 'isCredit') {
                        value = value ? 'Yes' : 'No'; // Convert boolean to "Yes" or "No"
                    }

                    return value !== null && value !== undefined ? `"${value}"` : '';
                });

                csvRows.push(csvRow.join(','));
            }

            return csvRows.join('\n');
        }

        const csvData = convertToCSV(data);

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleUnitDataGridChanged = () => {
        toggleDisplayDataGrid(!displayDataGrid);
    }

    const convertToInvoice = async (selectedId) => {
        if (!moduleAccess.allowAdd) {
            history.push('/notauthorised');
        } else {
            confirm(getConfirmOptions(`Convert ${entityName.toLowerCase()} to invoice`, `Are you sure, you want to convert this ${entityName.toLowerCase()} into a sales invoice?`))
                .then(async () => {
                    try {
                        history.push('/invoicesummary/' + entityName + "/" + selectedId);
                    } catch (error) {
                        showAlert(entityName + ' convert to invoice failed. Internal server error.', 'error');
                    }

                }).catch(() => { });
        }
    }


    const convertToDeliveryChallan = async (selectedId) => {
        if (!moduleAccess.allowAdd) {
            history.push('/notauthorised');
        } else {
            confirm(getConfirmOptions(`Convert ${entityName.toLowerCase()} to Delivery Challan`, `Are you sure, you want to convert this ${entityName.toLowerCase()} into a Delivery Challan?`))
                .then(async () => {
                    try {
                        history.push('/dcsummary/' + entityName + "/" + selectedId);
                    } catch (error) {
                        showAlert(entityName + ' convert to Delivery Challan failed. Internal server error.', 'error');
                    }

                }).catch(() => { });
        }
    }

    const convertToPurchaseBill = async (selectedId) => {
        confirm(getConfirmOptions('Convert P.O. to purchase bill', 'Are you sure, you want to convert this P.O. to a purchase bill?'))
            .then(async () => {
                history.push('/purchasesummary/' + entityName + "/" + selectedId);
            }).catch(() => { });
    }

    const handleMenuItemClick = async (action) => {
        switch (action) {
            case "Payments":
                history.push("/addpayment/" + selectedId);
                break;
            case "BillPayable":
                history.push("/addpayable/" + selectedId);
                break;
            case "fullfillmentReport":
                openDialog('openNewDialog');
                break;
            case "convertToDC":
                convertToDeliveryChallan(selectedId);
                break;
            case "Edit":
                history.push("/" + entityName.toLowerCase() + "/" + selectedId);
                break;
            case 'Recurring':
                openDialog('createRecurring');
                setCustomerMenu(null);
                break;
            case "Convert":
                convertToInvoice(selectedId);
                break;
            case "ConvertToPO":
                convertToPurchaseBill(selectedId);
                break;
            case 'recurringList':
                history.push('recurringList');
                break;
            case "requestPayment":
                openDialog("requestPaymentDialog");
                setCustomerMenu(null);
                break;
            case 'sendWhatsApp':
                openDialog('sendWhatsAppDialog');
                break;
            case 'sendSMS':
                openDialog('sendSMSDialog');
                break;
            case 'sendEmail':
                openDialog('sendEmailDialog');
                break;
            case "downloadPDF":
                printOrder(PDF_OUTPUT_DOWNLOAD, selectedId, selectedReferenceId)
                break;
            case "Copy":
                confirm(getConfirmOptions('Copy/Clone ' + entityName + '?', 'Are you sure, you want to copy this ' + entityName + ' and create a new ' + entityName + ' with same item list & party in it?'))
                    .then(async () => {
                        await copyOrder(entityName, safeInt(selectedOrder.id), setWaiting, authContext, showAlert, history);
                    }).catch(() => { });
                break;
            case "Refund":
                if (safeInt(selectedOrder.returnsId) > 0) {
                    var response = await getOrder(safeInt(selectedId), entityName, authContext);
                    setSelectedReturn(response);
                    history.push("/selectRefundType/" + referenceType + "/" + selectedItemParty.id)
                } else {
                    showAlert(ERROR_NO_RETURNS_FOUND, 'error');
                }
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className={classes.formHeader}>
                        <AppIcon name={pageIcon} color="primary" />
                        <Typography className={classes.ml1} style={{ fontWeight: 'bold' }} variant="subtitle1" color="secondary">{pageHeading}</Typography>
                    </div>
                </Grid>

                <Grid item xs={6} className={classes.pullRightOrderList}>
                    <EntityButtonGroup entityName={entityName} />
                </Grid>

                <Grid item spacing={1} container xs={12} style={{ background: headingColor, marginTop: '16px', padding: '0px', borderRadius: '5px' }}>
                    <Grid item xs={6} sm={8} md={2}>
                        <TextField
                            type="text"
                            variant="standard"
                            size="small"
                            color="secondary"
                            fullWidth
                            placeholder=" Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{ background: '#ffffff', borderRadius: '5px', border: '0px solid white', marginTop: mobileDevice ? "0px" : '3px' }}
                            InputProps={{
                                startAdornment: <AppIcon name="Search" />
                            }}
                        />
                    </Grid>
                    <Grid item container spacing={0} xs={5} sm={4} md={2} className={classes.pullRight}>
                        <Grid item style={{ marginTop: '3px', marginRight: '8px' }}>
                            <OrderListViewSort
                                buttonLabel="Sort"
                                sortOptions={getSortOptions(entityName, sortField, sortDirection)}
                                onSortChange={handleSortChange}
                                iconName="Sort"
                            />
                        </Grid>
                        <Grid item>
                            <Formik
                                initialValues={fieldValues}
                                validationSchema={yup.object({
                                    fromDate: yup.date().default(new Date()),
                                    toDate: yup
                                        .date()
                                        .when("fromDate", (eventStartDate, schema) => eventStartDate && schema.min(eventStartDate, 'To date cannot be less than from date'))
                                })}
                                enableReinitialize={true}
                                onSubmit={(values) => submitFormData(values)}
                            >
                                {({ isSubmitting, values, setFieldValue }) => (
                                    <>
                                        <Form style={{ marginTop: '3px' }}
                                        >
                                            <OrderListViewFilter
                                                referenceType={referenceType}
                                                buttonLabel="Filter"
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                submitFormData={submitFormData}
                                                entityName={entityName}
                                            />
                                        </Form>
                                    </>
                                )}

                            </Formik>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={12} md={8} className={classes.pullRight}>
                        {!mobileDevice && (
                            <Grid item xs={12} md={6} className={classes.pullLeft} style={{ marginTop: '4px' }}>
                                <div style={{
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '6px 10px',
                                    backgroundColor: 'transparent',
                                    textAlign: 'center',
                                    height: '35px'
                                }}>
                                    <Typography variant="caption" color="secondary">
                                        <span style={{ fontWeight: '600' }}>{safeInt(sortedData?.length)}</span> record(s) found.
                                    </Typography>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={12} md={4} className={classes.pullRight} style={{ marginTop: '2px' }}>
                            <div style={{ display: mobileDevice ? 'flex' : 'block' }}>
                                {mobileDevice ? (
                                    <Tooltip title="Export Options">
                                        <IconButton
                                            onClick={handleClickMobileExport}
                                            style={{ padding: 6 }}
                                            size="small"
                                        >
                                            <AppIcon name="Download" color="#205BD4" size="small" />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <>
                                        <Button
                                            style={{ background: "#fff", marginRight: '8px' }}
                                            variant="text"
                                            color="primary"
                                            size="small"
                                            endIcon={<AppIcon name="Download" color="#205BD4" size="small" />}
                                            onClick={handleClickMobileExport}
                                        >
                                            Export
                                        </Button>
                                    </>
                                )}

                                <Menu
                                    anchorEl={anchorElMobileExport}
                                    open={Boolean(anchorElMobileExport)}
                                    onClose={handleCloseMobileExport}
                                    elevation={1}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem onClick={() => { exportToExcel(tableData); handleClickMobileExport(); }}>
                                        <Box display="flex" alignItems="center">
                                            <AppIcon name="CSV" color="#4CAF50" size="small" />
                                            <Box ml={1}>Export as CSV</Box>
                                        </Box>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => { printStatement(PDF_OUTPUT_DOWNLOAD); handleCloseMobileExport(); }}>
                                        <Box display="flex" alignItems="center">
                                            <AppIcon name="PDF" color="#B30B00" size="small" />
                                            <Box ml={1}>Export as PDF</Box>
                                        </Box>
                                    </MenuItem>

                                </Menu>
                            </div>
                        </Grid>
                        {
                            mobileDevice ? null : <div style={{ marginTop: '4px' }}>
                                <ButtonBase disableRipple tabIndex={100}>
                                    <Switch tabIndex={101}
                                        checked={displayDataGrid}
                                        onChange={handleUnitDataGridChanged}
                                        size="small"
                                        color="secondary"
                                    /><span style={{ fontWeight: 'bold' }}>{mobileDevice ? "" : "Grid View"}</span></ButtonBase>
                            </div>
                        }
                    </Grid>
                    {
                        mobileDevice &&
                        <Typography variant="caption" color="secondary">
                            <span style={{ fontWeight: '600', marginLeft: '8px' }}>{safeInt(sortedData?.length)}</span> record(s) found.
                        </Typography>
                    }
                </Grid>

                <Grid item container xs={12} sm={12} md={4}>
                    <Grid item xs={12}>
                        <div style={{ height: mobileDevice ? '100%' : '100vh', display: 'flex' }}>
                            <div style={{ background: '#fafafa', marginTop: '4px', borderRadius: '2px', overflow: 'auto', paddingRight: '4px', minWidth: mobileDevice ? '96vw' : '100%', maxWidth: mobileDevice ? '96vw' : '100%' }}>
                                {!isNullOrEmpty(sortedData) && !isNullOrUndefined(sortedData) && !isEmptyArray(sortedData) ? (
                                    sortedData?.map((invoice, index) => (
                                        <OrderListItem
                                            key={invoice.id}
                                            invoice={invoice}
                                            index={index}
                                            selectedRowIndex={selectedRowIndex}
                                            handleOrderItemClick={handleOrderItemClick}
                                            handleCustomerMoreAction={handleCustomerMoreAction}
                                            entityName={entityName}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body1" color="textSecondary" align="center">
                                        No orders found
                                    </Typography>
                                )}

                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={8} spacing={0} className={classes.laptopOnly}>
                    <Grid item xs={12}>
                        <div style={{ background: "#fff" }}>
                            <Grid container className={classes.pullRight}>
                                <Grid item>
                                    <ButtonGroup variant="text" color="primary">
                                        <Button
                                            endIcon={<AppIcon name="Edit" size="small" />}
                                            style={{ background: "#fff" }}
                                            onClick={() => handleOrderClick(selectedId)}
                                            disabled={selectedId === 0 && selectedReferenceId === 0}
                                        >
                                            EDIT
                                        </Button>
                                        <Button
                                            endIcon={<AppIcon name="Print" size="small" />}
                                            style={{ background: "#fff" }}
                                            onClick={printOrderHandler}
                                            disabled={selectedId === 0 && selectedReferenceId === 0}
                                        >
                                            PRINT
                                        </Button>
                                        <Button
                                            endIcon={<AppIcon name="Share" size="small" />}
                                            style={{ background: "#fff" }}
                                            onClick={(e) => openShared(e)}
                                            disabled={selectedId === 0 && selectedReferenceId === 0}
                                        >
                                            SHARE
                                        </Button>
                                        <Button
                                            endIcon={<AppIcon name="MoreHorizontal" size="small" />}
                                            style={{ background: "#fff" }}
                                            onClick={(e) => handleCustomerMoreAction(e)}
                                            disabled={selectedId === 0 && selectedReferenceId === 0}
                                        >
                                            MORE
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                            </Grid>
                            <OrderPreview entityName={entityName} referenceType={referenceType} pdfUrl={pdfUrl} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <ContextMenu element={sharedAnchorEl} handleClose={handleSharedClose}
                menuItems={<>
                    {
                        entityName === INVOICE_ENTITY &&
                        <>
                            <ContextMenuItemShare
                                handleClick={(e) => handleActionButtonClick(e)}
                                name="sendWhatsApp" caption="Send on WhatsApp"
                                iconColor="green"
                                iconName="WhatsappIcon" iconSize="20px" />
                            <Divider />
                            <ContextMenuItemShare
                                handleClick={(e) => handleActionButtonClick(e)}
                                name="sendSMS" caption="Send on SMS"
                                iconColor="#2D90DD"
                                iconName="SMS" iconSize="20px" />
                            <Divider />
                        </>
                    }
                    {
                        (entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY || entityName === QUOTATION_ENTITY || entityName === DELIVERY_CHALLAN_ENTITY || entityName === CREDIT_NOTE_ENTITY || entityName === DEBIT_NOTE_ENTITY || entityName === PURCHASE_ORDER_ENTITY) &&
                        <>
                            <ContextMenuItemShare
                                handleClick={(e) => handleActionButtonClick(e)}
                                name="sendEmail" caption="Send on Email"
                                iconColor="blue"
                                iconName="Email" iconSize="20px" />
                            <Divider />
                        </>
                    }
                    <ContextMenuItemShare
                        handleClick={(e) => handleActionButtonClick(e)}
                        name="downloadPDF" caption="Download PDF"
                        iconColor="steelblue"
                        iconName="PDF" iconSize="20px" />
                </>}
            />


            {
                mobileDevice ? <OrderListViewMobileMenu
                    entityName={entityName}
                    customerMenu={customerMenu}
                    handleCustomerMenuClose={handleCustomerMenuClose}
                    handleMenuItemClick={handleMenuItemClick}
                    paymentAccess={paymentAccess}
                    moduleAccess={moduleAccess}
                    mobileDevice={mobileDevice}
                /> : <OrderListViewDesktopMenu
                    entityName={entityName}
                    customerMenu={customerMenu}
                    handleCustomerMenuClose={handleCustomerMenuClose}
                    handleMenuItemClick={handleMenuItemClick}
                    paymentAccess={paymentAccess}
                    moduleAccess={moduleAccess}
                    mobileDevice={mobileDevice}
                />
            }



            <DialogWindow name="createRecurring" open={isDialogOpen}
                onExited={e => handleDialogClosed('createRecurring')}
                width="xs" component={<Recurring orderData={orderData} isEditMode={false} />} />

            <DialogWindow name="requestPaymentDialog" open={isDialogOpen}
                onExited={e => handleDialogClosed('requestPaymentDialog')}
                width="sm" component={<NewPaymentLink orderData={orderData} isEditMode={false} />} />

            <DialogWindow name="sendEmailDialog" open={isDialogOpen}
                width="xs" component={<EmailSend orderId={orderData?.id} orderType={entityName} referenceType={referenceType} selectedParty={selectedItemParty} selectedOrder={selectedOrder} />}
            />

            <DialogWindow name="sendWhatsAppDialog" open={isDialogOpen}
                width="xs" component={<WhatsAppSend orderType={entityName} referenceType={referenceType} selectedOrder={selectedOrder} selectedParty={selectedItemParty} />}
            />

            <DialogWindow name="sendSMSDialog" open={isDialogOpen}
                width="xs" component={<SMSSend orderType={entityName} referenceType={referenceType} selectedOrder={selectedOrder} selectedParty={selectedItemParty} />}
            />


            <DialogWindow name="openNewDialog" open={isDialogOpen} compact={true}
                width="xl" component={<PurchaseOrderFullfillmentStatus isList={false} selectedOrder={selectedItemParty} />} />
        </>

    );
};
