import React, { useEffect, useState } from 'react';
import Page from '../../UI/Pages/Page';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@material-ui/core';
import useStyles from '../../../styles';
import { NumberInput, SelectInput } from '../../UI/Controls/InputFields';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import { PropTypes } from 'prop-types';
import AppIcon from '../../UI/Controls/AppIcons';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ifNullOrEmpty, isNullOrUndefined, safeInt, safeFloat } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';
import { CREDIT_NOTE_ENTITY, DEFAULT_CREDITNOTE_VOUCHER_SETTINGS, DEFAULT_QUOTATION_VOUCHER_SETTINGS, DEFAULT_VOUCHER_AVAILABLE_COLUMN_DEFINITION, DEFAULT_VOUCHER_COLUMN_DEFINITION, DEFAULT_VOUCHER_SETTINGS, INVENTORY_ENTITY, INVOICE_ENTITY, QUOTATION_ENTITY } from '../../Base/Common';
import { useContext } from 'react';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tabpanel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `settings-tab-${index}`,
        'aria-controls': `settings-tabpanel-${index}`,
    };
}

const VoucherSettings = ({ entityType }) => {
    const classes = useStyles();
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const authContext = useContext(AuthContext);
    const toLowerFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);

    const showAlert = useStore(state => state.showAlert);

    let columnDefinition = DEFAULT_VOUCHER_AVAILABLE_COLUMN_DEFINITION;
    let defaultVoucherFormatSettings;
    let defaultAvailableColumn = DEFAULT_VOUCHER_COLUMN_DEFINITION; // Assuming this remains unchanged
    let voucherFormatSettings;

    switch (entityType) {
        case INVOICE_ENTITY:
            defaultVoucherFormatSettings = DEFAULT_VOUCHER_SETTINGS;
            voucherFormatSettings = DEFAULT_VOUCHER_SETTINGS;
            break;
        case QUOTATION_ENTITY:
            defaultVoucherFormatSettings = DEFAULT_QUOTATION_VOUCHER_SETTINGS;
            voucherFormatSettings = DEFAULT_QUOTATION_VOUCHER_SETTINGS;
            break;
        case CREDIT_NOTE_ENTITY:
            defaultVoucherFormatSettings = DEFAULT_CREDITNOTE_VOUCHER_SETTINGS;
            voucherFormatSettings = DEFAULT_CREDITNOTE_VOUCHER_SETTINGS;
            break;
        default:
            break;
    }

    const invoiceFormatSettings = `${toLowerFirstLetter(entityType)}FormatSettings`;

    const invoiceFormatColumnDefination = `${toLowerFirstLetter(entityType)}FormatColumnDefination`;


    if (!isNullOrUndefined(authContext.settings[invoiceFormatSettings])) {
        voucherFormatSettings = authContext.settings[invoiceFormatSettings];
    }

    const [fieldValues, setFieldValues] = useState(voucherFormatSettings);


    const getDefaultSelectedColumns = () => {
        const defaultColumns = columnDefinition.filter(column => column.field === 'index' || column.field === 'description');
        return defaultColumns;
    };
    let vouchersetColumnDefination = null;
    if (!isNullOrUndefined(authContext.settings[invoiceFormatColumnDefination])) {
        vouchersetColumnDefination = authContext.settings[invoiceFormatColumnDefination];
    } else {
        vouchersetColumnDefination = defaultAvailableColumn;
    }
    const [selectedColumns, setSelectedColumns] = useState(ifNullOrEmpty(vouchersetColumnDefination, getDefaultSelectedColumns()));

    const [availableColumns, setAvailableColumns] = useState(columnDefinition);
    const [editedIndex, setEditedIndex] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedWidth, setEditedWidth] = useState('');
    const [totalWidth, setTotalWidth] = useState(selectedColumns.reduce((sum, col) => sum + (col.width || 0), 0));
    const [hideFooter, setHideFooter] = useState(false);
    const [hideHeader, setHideHeader] = useState(false);

    const [movingColumnIndex, setMovingColumnIndex] = useState(null);


    const handleCheckboxChange = (event) => {
        setHideFooter(event.target.checked);
    };

    const handlehideHeaderChange = (event) => {
        setHideHeader(event.target.checked);
    }

    useEffect(() => {
        if (columnDefinition && columnDefinition.length > 0) {
            if (!isNullOrUndefined(vouchersetColumnDefination)) {
                const existingTitles = vouchersetColumnDefination?.map(column => column.field);
                const filteredColumnDefinition = columnDefinition.filter(column => !existingTitles.includes(column.field));
                setAvailableColumns(filteredColumnDefinition);
            } else {
                setAvailableColumns(columnDefinition)
            }
        } else {
            setAvailableColumns(columnDefinition);
        }


        if (!isNullOrUndefined(fieldValues)) {
            let fetchFieldValues = { ...fieldValues }
            fetchFieldValues.backgroundFillColor = fieldValues.backgroundFillColor;
            fetchFieldValues.borderColor = fieldValues.borderColor;
            fetchFieldValues.fontMainHeading1Color = fieldValues.fontMainHeading1Color;
            setSelectedColors(fetchFieldValues);
            setHideFooter(fieldValues.hideFooter);
            setHideHeader(fieldValues.hideHeader);
        }
    }, []);
    const handleEdit = (index) => {
        setEditedIndex(index);
        setEditedTitle(selectedColumns[index].title);
        setEditedWidth(selectedColumns[index].width || 0);
    };

    const handleSave = (index, title, width, field) => {
        const newColumns = [...selectedColumns];
        if (safeInt(width) === 0) {
            if (field === "description") {
                width = "*";
            } else {
                width = "auto";
            }
        } else {
            width = safeFloat(width);
        }
        newColumns[index] = { ...newColumns[index], title, width: width };
        setSelectedColumns(newColumns);
        setTotalWidth(newColumns.reduce((sum, col) => sum + (col.width || 0), 0));
        setEditedIndex(null);
    };

    const handleCancelEdit = () => {
        setEditedIndex(null);
        setEditedTitle('');
    };

    const moveRight = (itemIndex) => {
        const selectedItem = availableColumns[itemIndex];
        setAvailableColumns(availableColumns.filter((_, index) => index !== itemIndex));
        setSelectedColumns([...selectedColumns, selectedItem]);
    };

    const moveLeft = (itemIndex) => {
        const removedItem = selectedColumns[itemIndex];
        setSelectedColumns(selectedColumns.filter((_, index) => index !== itemIndex));
        const originalColumn = columnDefinition.find(column => column.field === removedItem.field);
        if (originalColumn) {
            setAvailableColumns([...availableColumns, originalColumn]);
        }
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const moveUp = (itemIndex) => {
        if (itemIndex > 0) {
            const updatedColumns = [...selectedColumns];
            [updatedColumns[itemIndex], updatedColumns[itemIndex - 1]] = [updatedColumns[itemIndex - 1], updatedColumns[itemIndex]];
            updatedColumns.forEach((column, index) => {
                column.sequence = index + 1;
            });
            setSelectedColumns(updatedColumns);
            setMovingColumnIndex(itemIndex - 1);

        }
    };

    const moveDown = (itemIndex) => {
        if (itemIndex < selectedColumns.length - 1) {
            const updatedColumns = [...selectedColumns];
            [updatedColumns[itemIndex], updatedColumns[itemIndex + 1]] = [updatedColumns[itemIndex + 1], updatedColumns[itemIndex]];
            updatedColumns.forEach((column, index) => {
                column.sequence = index + 1;
            });
            setSelectedColumns(updatedColumns);
            setMovingColumnIndex(itemIndex + 1);

        }
    };

    const submitFormData = async (values) => {
        const numericWidths = selectedColumns.map(col => col.width || 0).filter(width => !isNaN(width));
        const currentTotalWidth = numericWidths.reduce((sum, width) => sum + width, 0);
        if (currentTotalWidth > 100) {
            showAlert('Total width of columns exceeds 100%', 'error');
            return;
        }
        values = {
            ...values,
            backgroundFillColor: selectedColors.backgroundFillColor,
            borderColor: selectedColors.borderColor,
            fontMainHeading1Color: selectedColors.fontMainHeading1Color,
            hideFooter: hideFooter,
            hideHeader: hideHeader
        };

        const response = {
            [`${toLowerFirstLetter(entityType)}FormatSettings`]: values,
            [`${toLowerFirstLetter(entityType)}FormatColumnDefination`]: selectedColumns,
            entityType: entityType
        };
        setDialogResponse(response);
        closeDialog();
    }

    const resetFormValues = (values, setValues) => {
        if (selectedTab === 0) {
            setValues(defaultVoucherFormatSettings);
        } else {
            setAvailableColumns(DEFAULT_VOUCHER_AVAILABLE_COLUMN_DEFINITION);
            setSelectedColumns(DEFAULT_VOUCHER_COLUMN_DEFINITION);
        }

        /* setFieldValues(defaultVoucherFormatSettings);
        setAvailableColumns(defaultAvailableColumn); */
    };

    const colorMappings = {
        "#c1bebf": { borderColor: "#000000", fontMainHeading1Color: "#000000" }, // Lightgray
        "#e8f3fd": { borderColor: "#121481", fontMainHeading1Color: "#121481" }, // Steelblue
        "#c3ecc8": { borderColor: "#007400", fontMainHeading1Color: "#007400" }, // Green
        "#d4c1f2": { borderColor: "#3500a5", fontMainHeading1Color: "#3500a5" }, // Purple
        "#ffc8cb": { borderColor: "#b01f00", fontMainHeading1Color: "#b01f00" }  // Red
    };

    const [selectedColors, setSelectedColors] = useState({
        backgroundFillColor: "#c1bebf",
        borderColor: "#000000",
        fontMainHeading1Color: "#000000"
    });

    const handleBackgroundFillChange = (event) => {
        const newBackgroundFillColor = event.target.value;
        const newColors = colorMappings[newBackgroundFillColor];

        setSelectedColors({
            backgroundFillColor: newBackgroundFillColor,
            borderColor: newColors.borderColor,
            fontMainHeading1Color: newColors.fontMainHeading1Color
        });
    };


    const formContentData = () => {
        return (
            <>
                <div style={{ margin: '0px' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" style={{ backgroundColor: '#e8f3fd' }}>
                        <Tab label="PDF settings"  {...a11yProps(0)} />
                        <Tab label="Voucher item settings" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={selectedTab} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1" align='left' gutterBottom style={{ fontWeight: "bold" }}>
                                    Color Theme
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="center">
                                <SelectInput label="Background fill" name="backgroundFillColor" required value={selectedColors.backgroundFillColor}
                                    onChange={handleBackgroundFillChange}>
                                    <option value="#c1bebf" style={{ background: "#c1bebf" }}>Lightgray</option>
                                    <option value="#e8f3fd" style={{ background: "#e8f3fd" }}>Steelblue</option>
                                    <option value="#c3ecc8" style={{ background: "#c3ecc8" }}>Green</option>
                                    <option value="#d4c1f2" style={{ background: "#d4c1f2" }}>Purple</option>
                                    <option value="#ffc8cb" style={{ background: "#ffc8cb" }}>Red</option>
                                </SelectInput>
                            </Grid>
                            <Grid item xs={6} justifyContent="center" style={{ marginTop: '10px' }}>
                                <SelectInput label="Heading Color" name="fontMainHeading1Color" required value={selectedColors.fontMainHeading1Color}
                                    onChange={(e) => setSelectedColors({ ...selectedColors, fontMainHeading1Color: e.target.value })}>
                                    <option value="#000000" style={{ background: "#000000" }}>Black</option>
                                    <option value="#121481" style={{ background: "#121481" }}>Steelblue</option>
                                    <option value="#007400" style={{ background: "#007400" }}>Green</option>
                                    <option value="#3500a5" style={{ background: "#3500a5" }}>Purple</option>
                                    <option value="#b01f00" style={{ background: "#b01f00" }}>Red</option>
                                </SelectInput>
                            </Grid>
                            <Grid item xs={6} justifyContent="center" style={{ marginTop: '10px' }}>
                                <SelectInput label="Select Border Color" name="borderColor" required value={selectedColors.borderColor}
                                    onChange={(e) => setSelectedColors({ ...selectedColors, borderColor: e.target.value })}>
                                    <option value="#000000" style={{ background: "#000000" }}>Black</option>
                                    <option value="#121481" style={{ background: "#121481" }}>Steelblue</option>
                                    <option value="#007400" style={{ background: "#007400" }}>Green</option>
                                    <option value="#3500a5" style={{ background: "#3500a5" }}>Purple</option>
                                    <option value="#b01f00" style={{ background: "#b01f00" }}>Red</option>
                                </SelectInput>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align='left' gutterBottom style={{ fontWeight: "bold" }}>
                                        Fonts
                                    </Typography>
                                </Grid>
                                <Grid container item spacing={2} xs={12} style={{ marginLeft: '2px' }}>
                                    <Grid item xs={6}>
                                        <NumberInput
                                            label="Main Heading 1"
                                            name="fontMainHeading1"

                                            inputProps={{ min: 6, max: 24 }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NumberInput
                                            label="Main Heading 2"
                                            name="fontMainHeading2"

                                            inputProps={{ min: 6, max: 24 }}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Sub Heading Row */}
                                <Grid container item spacing={2} xs={12} style={{ marginLeft: '2px' }}>
                                    <Grid item xs={6}>
                                        <NumberInput
                                            label="Sub Heading 1"
                                            name="fontSubHeading1"

                                            inputProps={{ min: 6, max: 24 }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NumberInput
                                            label="Sub Heading 2"
                                            name="fontSubHeading2"

                                            inputProps={{ min: 6, max: 24 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>
                                <SelectInput label="Draw page border?" name="drawPageOutlineBorder" required>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </SelectInput>
                            </Grid>

                            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>

                                <SelectInput label="Page orientation" name="pageOrientation" required disabled>
                                    <option value="portrait">Portrait</option>
                                    <option value="landscape">Landscape</option>
                                </SelectInput>
                            </Grid>
                            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>
                                <NumberInput
                                    label="Company Logo Dimension"
                                    name="logoWidth"
                                    inputProps={{ min: 80, max: 250 }}
                                />
                            </Grid>
                            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>
                                <SelectInput label="Select Item Table Size" name="itemSize" required>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </SelectInput>
                            </Grid>

                            <Grid container xs={12} spacing={2} justifyContent="center" style={{ margin: '0px' }}>
                                <Grid item xs={6}>
                                    <NumberInput
                                        label="Footer height"
                                        name="footerMargin"
                                        inputProps={{ min: 10, max: 450 }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: '-10px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={hideFooter}
                                                onChange={handleCheckboxChange}
                                                name="hideFooter"
                                                color="primary"
                                            />
                                        }
                                        label="Hide Footer?"
                                    />
                                    <span style={{ display: 'block', fontSize: '14px', color: 'gray', fontStyle: "italic", marginTop: '-10px' }}>Check this option to hide header from invoice format?</span>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} spacing={2} justifyContent="center" style={{ margin: '0px' }}>
                                <Grid item xs={6}>
                                    <NumberInput
                                        label="Header Margin"
                                        name="headerMargin"
                                        inputProps={{ min: 10, max: 450 }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: '-10px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={hideHeader}
                                                onChange={handlehideHeaderChange}
                                                name="hideHeader"
                                                color="primary"
                                            />
                                        }
                                        label="Hide Header?"
                                    />
                                    <span style={{ display: 'block', fontSize: '14px', color: 'gray', fontStyle: "italic", marginTop: '-10px' }}>Check this option to hide footer from invoice format?</span>
                                </Grid>

                            </Grid>
                        </Grid>

                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <Grid container spacing={2} style={{ overflowY: 'auto' }} className={classes.pullCenter}>
                            <Grid item xs={6}>
                                <Typography variant="caption">Available Columns</Typography>
                                <List>
                                    {availableColumns?.map((column, index) => (
                                        <ListItem key={index} style={{ border: "1px solid #000", marginBottom: '5px', cursor: 'pointer', padding: '0px', height: '40px' }}>
                                            <ListItemText button primary={
                                                <Typography variant="caption" style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '3px' }}>
                                                    {column.title}
                                                </Typography>
                                            } />
                                            {
                                                column.field === "index" || column.field === "description" ? <>
                                                    <IconButton>

                                                    </IconButton>
                                                </> : <>
                                                    <IconButton>
                                                        <AppIcon size="xl" style={{ color: "green", cursor: "pointer" }} name="RightChevron" onClick={() => moveRight(index)} />
                                                    </IconButton>
                                                </>
                                            }
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">Selected Columns</Typography>
                                <List>
                                    {selectedColumns?.map((column, index) => (
                                        <ListItem
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '1px solid #000',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                padding: '0px',
                                                backgroundColor: index === movingColumnIndex ? '#FFFF6E' : 'white',
                                            }}
                                        >
                                            {
                                                column.field === "index" || column.field === "description" ? null : <>
                                                    <IconButton onClick={() => moveUp(index)} disabled={index === 2}>
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => moveDown(index)} disabled={index === selectedColumns.length - 1}>
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                    <IconButton>
                                                        <AppIcon
                                                            size="xl"
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                            name="LeftChevron"
                                                            onClick={() => moveLeft(index)}
                                                        />
                                                    </IconButton>
                                                </>
                                            }
                                            {
                                                <Typography variant='caption'>Width : {<span style={{ color: "#8D65C5", fontWeight: 'bold' }}>{column.width}</span>}</Typography>
                                            }

                                            <IconButton>
                                                <AppIcon
                                                    size="sm"
                                                    style={{
                                                        color: 'green',
                                                    }}
                                                    name="Edit"
                                                    onClick={() => handleEdit(index)}
                                                />
                                            </IconButton>
                                            {editedIndex === index ? (
                                                <div style={{ flexGrow: 1 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                value={editedTitle}
                                                                onChange={(e) => setEditedTitle(e.target.value)}
                                                                autoFocus
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                value={editedWidth}
                                                                onChange={(e) => setEditedWidth(e.target.value)}
                                                                fullWidth
                                                                type="number"
                                                                inputProps={{ min: "0", max: "100", step: "1" }}
                                                                placeholder="Width (%)"
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                    <Button onClick={() => handleSave(index, editedTitle, editedWidth, column.field)} variant="contained" color="primary" style={{ margin: '10px' }}>
                                                        <AppIcon name="Save" />
                                                    </Button>
                                                    <Button onClick={handleCancelEdit} style={{ margin: '10px' }} variant="outlined" color="secondary">
                                                        <AppIcon name="Cancel" />
                                                    </Button>
                                                </div>
                                            ) : (
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="caption" title={column.displayName} style={{ fontSize: '12px', fontWeight: 'bold', flexGrow: 1 }}>
                                                            {column.title}
                                                        </Typography>
                                                    }
                                                />
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: '10px' }}>
                            <h3>Preview</h3>
                            <TableContainer style={{ backgroundColor: "#e8f3fd" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {selectedColumns.map((column, index) => (
                                                <TableCell
                                                    key={index}
                                                    style={{ border: '1px solid gray', fontWeight: 'bold', borderRadius: '5px', width: `${column.width}%` }}
                                                >
                                                    {column.title}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </TabPanel>

                </div >
            </>
        );
    };
    return (
        <div>
            <Page
                formContent={formContentData()}
                pageHeading="Voucher Settings"
                pageIcon="Settings"
                isModal={true}
                showCancelButton={false}
                fieldValues={fieldValues}
                onFormSubmit={submitFormData}
                showResetButton={true}
                onReset={resetFormValues}
                resetButtonCaption="Restore to default"
            />
        </div>
    );
};

export default VoucherSettings;
