import React, { useContext } from 'react';
import useStyles from '../../../styles';
import { Button, ButtonBase, Grid } from '@material-ui/core';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import AppIcon from './AppIcons';
import { isNullOrUndefined } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';


const ImageUploadController = ({ heading, buttonCaption, values, setFieldValue, fieldNamePreview, fieldName, handleUploadImage, buttonTxt, altTxt }) => {
    const classes = useStyles();
    const showAlert = useStore(state => state.showAlert);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const authContext = useContext(AuthContext);

    return (
        <Grid container alignContent="center" alignItems="center">          
            <Grid xs={12} item container alignContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <div class="logocontainer">
                        <div class="card">
                            <div class="drop_box">
                                <header>
                                    <h4>{heading}</h4>
                                </header>

                                <p>Supported format: image/jpeg, image/png</p>
                                <input accept="image/jpeg, image/png" name={fieldName}
                                    value={undefined}
                                    onChange={(e, value) => {
                                        try {
                                            setWaiting(true);
                                            if (!e.target.files || e.target.files.length === 0) {
                                                setFieldValue(fieldName, undefined);
                                                return
                                            }
                                            var reader = new FileReader();
                                            var file = e.currentTarget.files[0];
                                            if (file.size > 1000000) {
                                                showAlert('Failed to upload image. Please verify the image file size. (Max image size allowed: 1MB)', 'error');
                                            } else {
                                                reader.readAsDataURL(file);

                                                reader.onload = function () {
                                                    var image = new Image();
                                                    image.src = reader.result;

                                                    image.onload = function () {
                                                        // if (image.width !== 1080 && image.height !== 1080 && isFlage) {
                                                        //     setWaiting(false);
                                                        //     showAlert('Image dimensions must be 1080x1080 pixels.', 'error');
                                                        // } else {
                                                        setWaiting(false);
                                                        setFieldValue(fieldName, reader.result);
                                                        setFieldValue(fieldNamePreview, reader.result);
                                                        // }
                                                    };
                                                };
                                                reader.onerror = function (error) {
                                                    setWaiting(false);
                                                    showAlert('Failed to load image. Please verify the image format. (Accepted formats: JPEG, PNG)', 'error');
                                                    console.log('Error: ', error);
                                                };
                                            }
                                        } catch (error) {
                                            setWaiting(false);
                                        } finally {
                                            setWaiting(false);
                                        }
                                    }}
                                    className={classes.uploadFileInput}
                                    id="fileUploadButton" type="file" />
                                <label htmlFor="fileUploadButton">
                                    <Button variant="contained" aria-label="upload picture" component="span"
                                        startIcon={<AppIcon name="BrowseFolder" />}>
                                        {buttonCaption}
                                    </Button>
                                </label>
                                <p style={{ color: 'blue', fontStyle: 'italic' }}>Max image size allowed: 1MB</p>
                                <p style={{ fontSize: '12px', marginTop: '-20px', color: 'red' }}>Preferably only square images ( Dimensions :1080 X 1080  )</p>

                                <Button variant='contained' color='secondary' className={classes.mt1}
                                    onClick={async () => await handleUploadImage(values)}
                                    startIcon={<AppIcon name="Upload" size="small" />}
                                >{buttonTxt}</Button>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={`${classes.mt2}`}>
                        <p>Preview</p>
                        <img className={`${classes.m1}`} name={fieldNamePreview} style={{ border: '1px solid lightsteelblue', borderRadius: '5px', padding: '20px' }}
                            src={isNullOrUndefined(values[fieldNamePreview]) ? authContext.currentSubscriber.signatureAndSeal : values[fieldNamePreview]} alt={altTxt} width={300} />

                        <ButtonBase className={classes.pullCenter}>
                            <Button variant="text" style={{ color: 'red' }} startIcon={<AppIcon name="Cancel" />}
                                onClick={() => {
                                    setFieldValue(fieldName, "");
                                    setFieldValue(fieldNamePreview, "");
                                }}
                            >Remove image</Button>
                        </ButtonBase>
                    </div>

                </Grid>
            </Grid>          
        </Grid>

    );
}

export default ImageUploadController;
