import { Chip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { formatCurrencyAmount, getLocaleDate, isNullOrEmpty, safeBool, safeFloat, safeString } from '../Base/Utils';
import AuthContext from '../Auth/AuthContext';
import AppIcon from '../UI/Controls/AppIcons';
import "./Orders.css";
import { DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY, SALES_ORDER_ENTITY } from '../Base/Common';

export const OrderListItem = ({ invoice, index, selectedRowIndex, handleOrderItemClick, handleCustomerMoreAction, entityName }) => {
    const order = invoice;
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const backgroundColor = index === selectedRowIndex ? '#cce7ff' : (index % 2 === 0 ? '#f7f7f7' : 'white');

    return (
        <div
            style={{
                padding: '10px',
                borderBottom: '1px solid lightsteelblue',
                backgroundColor: backgroundColor,
                width: '100%',
                transition: 'background-color 0.3s',
                cursor: 'pointer',
            }}
            className="order-item"
            onClick={(e) => mobileDevice ? handleCustomerMoreAction(e, order, true) : handleOrderItemClick(order, index)}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='subtitle1' color='primary' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '63%' }}>
                    {order?.referenceName}
                </Typography>
                <Typography variant='subtitle1'>{formatCurrencyAmount(currencyCode, order?.grandTotal, "darkgreen", false)}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='caption' color='secondary'>
                    {`${order?.orderNumber} ◆ ${getLocaleDate(order?.orderDate)}`}
                </Typography>
                {(entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY) && (
                    <>
                        {(order.irnStatus !== 'CAN' && safeFloat(order?.amountDue) > 0) && (
                            <Typography variant='caption' style={{ color: 'red' }}>
                                {formatCurrencyAmount(currencyCode, order?.amountDue, "red", false, "DUE: ", 2, 'caption')}
                            </Typography>
                        )}
                        {safeFloat(order?.amountDue) === 0 && (
                            <Chip
                                label={"PAID"}
                                style={{ backgroundColor: theme.palette.success.light, color: theme.palette.success.dark, cursor: "pointer", marginLeft: '2px' }}
                                size='small'
                                title="paid"
                                variant='outlined'
                            />
                        )}
                    </>
                )}
                {
                    (entityName === QUOTATION_ENTITY || entityName === DELIVERY_CHALLAN_ENTITY) &&
                    <>
                        {!isNullOrEmpty(order?.referenceOrderNumber) && (
                            <Chip
                                label={order?.referenceOrderNumber}
                                style={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.dark, cursor: "pointer", marginLeft: '2px', fontSize: '11px' }}
                                size='small'
                                title="Converted to sales invoice"
                                variant='outlined'
                            />
                        )}
                    </>
                }
                {
                    (entityName === PURCHASE_ORDER_ENTITY) &&
                    <>
                        {!isNullOrEmpty(order?.referenceOrderNumber) && (
                            <Chip
                                label={order?.referenceOrderNumber}
                                style={{ backgroundColor: theme.palette.success.light, color: theme.palette.success.dark, cursor: "pointer", marginLeft: '2px', fontSize: '11px' }}
                                size='small'
                                title="Converted to purchase bill"
                                variant='outlined'
                            />
                        )}
                    </>
                }
            </div>
            <div style={{ marginTop: '4px' }}>
                {(entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY) && (
                    <>
                        {order?.irnStatus === 'GEN' && (
                            <Chip
                                icon={<AppIcon name="Check" style={{ backgroundColor: theme.palette.success.light, color: theme.palette.success.dark }} />}
                                label={"IRN"}
                                style={{ backgroundColor: theme.palette.success.light, color: theme.palette.success.dark, cursor: "pointer", marginLeft: '2px' }}
                                size='small'
                                title="IRN Generated"
                                variant='outlined'
                            />
                        )}
                        {order?.irnStatus === 'CAN' && (
                            <Chip
                                icon={<AppIcon name="Cancel" style={{ backgroundColor: 'rgb(243 133 130)', color: 'rgb(137 4 4)' }} />}
                                label={"IRN"}
                                style={{ backgroundColor: 'rgb(243 133 130)', color: 'rgb(137 4 4)', cursor: "pointer", marginLeft: '2px' }}
                                size='small'
                                title="IRN Cancelled"
                                variant='outlined'
                            />
                        )}
                        {!safeBool(order?.isCredit) && (
                            <Chip
                                icon={<AppIcon name="RupeeIcon" size="xs" style={{ marginTop: '2px', backgroundColor: theme.palette.success.light, color: theme.palette.success.dark }} />}
                                label={"Cash"}
                                style={{ paddingTop: '4px', backgroundColor: theme.palette.success.light, color: theme.palette.success.dark, cursor: "pointer", marginLeft: '2px' }}
                                size='small'
                                title="Cash Bill"
                                variant='outlined'
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
